import axios, { InternalAxiosRequestConfig } from 'axios'
import Cookies from 'js-cookie'
import * as qs from 'qs'
import {
  Api, Check,
  Department, Equipment,
  Inventory, PatchedUserPositionUpdate, ReportCreate, TaskCreate, TaskSubType, TaskTimeRecommendation, TaskType,
  TokenCreateSerializerV2,
  UserCreateSerializerV2,
  Vehicle, WorkerRecommendation,
} from 'api/humanagro.generated'
import CookiesKeys from 'data/cookiesKeys'
import { Writable } from 'types'

export interface V1AddressItem {
  place_id: number
  license?: string
  osm_type?: string
  osm_id?: string
  boundingbox?: string[]
  lat: string
  lon: string
  display_name: string
  place_rank: number
  category: 'boundary' | undefined
  type: 'administrative' | undefined
  importance: number
  icon?: string
}

const { api: generatedApi, instance } = new Api({
  baseURL: 'https://api.humanagro.ru',
  paramsSerializer: {
    serialize: (params) => {
      return qs.stringify(params, { arrayFormat: 'comma' })
    },
  },
})

instance.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
  const token = Cookies.get(CookiesKeys.token)

  if (token && config.headers && !config.url?.includes('auth/token/login/')) {
    config.headers['Authorization'] = `Token ${token}`
  }

  return config
})

const api = {
  ...generatedApi,
  authTokenLoginCreate(body: Pick<TokenCreateSerializerV2, 'email' | 'password'>) {
    return generatedApi.authTokenLoginCreate(body as TokenCreateSerializerV2)
  },
  authUsersCreate(body: Writable<UserCreateSerializerV2>) {
    return generatedApi.authUsersCreate(body as UserCreateSerializerV2)
  },
  organizationOrgDepCreate(orgId: Parameters<typeof generatedApi.organizationOrgDepCreate>[0], body: Writable<Department>) {
    return generatedApi.organizationOrgDepCreate(orgId, body as Department)
  },
  organizationOrgInventoryCreate(orgId: Parameters<typeof generatedApi.organizationOrgDepCreate>[0], body: Writable<Inventory>) {
    return generatedApi.organizationOrgInventoryCreate(orgId, body as Inventory)
  },
  organizationOrgEquipmentCreate(orgId: Parameters<typeof generatedApi.organizationOrgDepCreate>[0], body: Writable<Equipment>) {
    return generatedApi.organizationOrgEquipmentCreate(orgId, body as Equipment)
  },
  organizationOrgVehicleCreate(orgId: Parameters<typeof generatedApi.organizationOrgDepCreate>[0], body: Writable<Vehicle>) {
    return generatedApi.organizationOrgVehicleCreate(orgId, body as Vehicle)
  },
  taskOrgTaskTypesCreate(orgId: Parameters<typeof generatedApi.organizationOrgDepCreate>[0], body: Writable<TaskType>) {
    return generatedApi.taskOrgTaskTypesCreate(orgId, body as TaskType)
  },
  taskOrgTaskSubtypesCreate(orgId: Parameters<typeof generatedApi.taskOrgTaskSubtypesCreate>[0], body: Writable<TaskSubType>) {
    return generatedApi.taskOrgTaskSubtypesCreate(orgId, body as TaskSubType)
  },
  taskOrgDepTaskCreate(orgId: number, departmentId: number, body: Writable<TaskCreate>) {
    return generatedApi.taskOrgDepTaskCreate(departmentId.toString(), orgId.toString(), body as TaskCreate)
  },
  taskOrgDepTaskChecksCreate(depId: string, orgId: string, taskId: string, body: Writable<Check>) {
    return generatedApi.taskOrgDepTaskChecksCreate(depId, orgId, taskId, body as Check)
  },
  exportOrgReportsCreate(orgId: number, body: Writable<ReportCreate>) {
    return generatedApi.exportOrgReportsCreate(orgId.toString(), body as ReportCreate)
  },
  analyticsOrgDepBestTimeCreate(depId: string, orgId: string, body: Writable<TaskTimeRecommendation>) {
    return generatedApi.analyticsOrgDepBestTimeCreate(depId.toString(), orgId.toString(), body as TaskTimeRecommendation)
  },
  analyticsOrgDepBestWorkerCreate(depId: string, orgId: string, body: Writable<WorkerRecommendation>) {
    return generatedApi.analyticsOrgDepBestWorkerCreate(depId.toString(), orgId.toString(), body as WorkerRecommendation)
  },
  organizationOrgDepUserPositionsPartialUpdate(
    depId: string,
    id: number,
    orgId: string,
    body: Writable<PatchedUserPositionUpdate>
  ) {
    return generatedApi.organizationOrgDepUserPositionsPartialUpdate(depId, id, orgId, body)
  },
  async mapSearchAddressRetrieve(query: string): Promise<V1AddressItem[]> {
    const { data } = await axios.get(`https://nominatim.openstreetmap.org/?q=${query}&format=jsonv2`)
    return data
  },
}

export { api }
