import { useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Add, DeleteOutline } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import { camelCase } from 'case-anything'
import { FieldInputControlled } from 'components/Field'
import { Form } from 'components/Form'
import { FormActions } from 'components/FormActions'
import { DictionaryType } from 'features/dictionaries/types'
import { TaskSubTypeFormValues } from 'features/tasks/types'
import { useGoBack } from 'hooks/useGoBack'
import { useHandleError } from 'hooks/useHandleError'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { useToast } from 'hooks/useToast'
import { api } from 'api'
import { titleValidationOptions } from 'data'

const type = DictionaryType.TaskTypes

export const DictionariesTaskTypeCreatePage = () => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useToast()
  const navigate = useNavigate()
  const handleError = useHandleError()
  const organizationID = useOrganizationID()
  const { formState: { isSubmitting, isSubmitted, isSubmitSuccessful }, setFocus, control, getValues, reset, handleSubmit } = useForm<TaskSubTypeFormValues>({
    defaultValues: {
      name: '',
      subtypes: [{ name: '' }],
    },
  })
  const fieldArray = useFieldArray({
    control,
    name: 'subtypes',
  })
  const goBack = useGoBack({ getValues })

  const create = async ({ name, subtypes }: TaskSubTypeFormValues) => {
    if (isSubmitting || (isSubmitted && isSubmitSuccessful)) {
      return
    }

    const taskType = await api.taskOrgTaskTypesCreate(organizationID.toString(), { name })
    enqueueSnackbar(t(`entity.${camelCase(type)}.messages.createSuccess`), { variant: 'success' })

    const taskSubTypes = subtypes.filter(({ name }) => name)

    if (taskSubTypes.length) {
      await Promise.all(subtypes.map(({ name }) => api.taskOrgTaskSubtypesCreate(organizationID.toString(), {
        name,
        task_type: taskType.data.id,
      })))

      enqueueSnackbar(t('entity.taskSubtype.messages.createSuccessAll'), { variant: 'success' })
    }
  }

  const handleCreate = async (values: TaskSubTypeFormValues) => {
    try {
      await create(values)
      navigate(`/${organizationID}/dictionaries/${type}`)
    } catch (error) {
      handleError(error)
      throw error
    }
  }

  const handleSaveAndContinue = async (values: TaskSubTypeFormValues) => {
    try {
      await create(values)
    } catch (error) {
      handleError(error)
      throw error
    }
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
  }, [isSubmitSuccessful, reset, setFocus])

  return (
    <Form
      title={t(`pages.${camelCase(type)}Create`)}
      content={(
        <>
          <FieldInputControlled
            name={'name'}
            control={control}
            rules={titleValidationOptions}
            label={t(`entity.${camelCase(type)}.fields.title`)}
            placeholder={t(`entity.${camelCase(type)}.fields.title`)}
          />
          <Typography
            variant={'subtitle1'}
            fontWeight={500}
            sx={{
              marginTop: '4px',
              marginBottom: '-8px',
            }}
          >
            Подтипы задач
          </Typography>
          {fieldArray.fields.map(({ name, id }, index) => (
            <FieldInputControlled
              key={id}
              name={`subtypes.${index}.name`}
              control={control}
              rules={{ ...titleValidationOptions, required: false }}
              label={index === 0 ? t('entity.task.fields.subtype') : undefined}
              placeholder={t('entity.task.fields.subtype')}
              renderRight={(
                <>
                  {index + 1 === fieldArray.fields.length && (
                    <Button
                      variant={'outlined'}
                      type={'button'}
                      sx={{ padding: '5px', minWidth: '42px' }}
                      onClick={() => fieldArray.append({ name: '' })}
                    >
                      <Add />
                    </Button>
                  )}
                  {fieldArray.fields.length > 1 && (
                    <Button
                      variant={'outlined'}
                      color={'error'}
                      type={'button'}
                      sx={{ padding: '5px', minWidth: '42px' }}
                      onClick={() => fieldArray.remove(index)}
                    >
                      <DeleteOutline />
                    </Button>
                  )}
                </>
              )}
            />
          ))}
        </>
      )}
      actions={(
        <FormActions
          isSubmitting={isSubmitting}
          isSubmitSuccessful={isSubmitSuccessful}
          onGoBack={goBack}
          onSubmitAndSave={handleSubmit(handleSaveAndContinue)}
        />
      )}
      onSubmit={handleSubmit(handleCreate)}
    />
  )
}
