import { forwardRef, Ref, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Autocomplete, Box, TextField } from '@mui/material'
import { FieldAutocompleteOption } from 'components/Field/components/FieldAutocomplete/components/FieldAutocompleteOption'
import { FieldAutocompleteSx } from 'components/Field/components/FieldAutocomplete/data'
import { FieldAutocompleteInputProps } from 'components/Field/components/FieldAutocomplete/data/inputProps'
import {
  FieldAutocompleteCommonProps,
  FieldAutocompleteCommonValue,
} from 'components/Field/components/FieldAutocomplete/types'
import { FieldLabel } from 'components/Field/components/FieldLabel'
import { FieldMessage } from 'components/Field/components/FieldMessage'
import { getSxTextFieldDefault } from 'components/Field/data'

export interface FieldAutocompleteMultipleProps<T extends FieldAutocompleteCommonValue> extends FieldAutocompleteCommonProps<T> {
  value: T[]
  count?: boolean
  onChange: (value: T[]) => void
}

export const FieldAutocompleteMultiple = forwardRef(<T extends FieldAutocompleteCommonValue, >({
  name,
  value,
  options,
  label,
  error = false,
  errorMessage,
  max,
  placeholder,
  sx,
  warningMessage,
  disabled = false,
  count = false,
  fluid = false,
  required = false,
  labelInside = false,
  disableClearable = false,
  filterOptions = false,
  isLoading = false,
  onChange,
  onInputChange,
}: FieldAutocompleteMultipleProps<T>, ref: Ref<HTMLInputElement>) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const limitReached = useMemo(() => max ? value.length >= max : false, [max, value])

  const handleOpen = () => {
    if (!disabled && !limitReached) {
      setOpen(true)
    }
  }

  return (
    <Autocomplete
      id={name}
      open={open}
      value={value}
      isOptionEqualToValue={(option, value) => option.id ? option.id === value.id : option.name === value.name}
      getOptionLabel={(option) => option.name}
      noOptionsText={t('common.noResults')}
      closeText={t('common.close')}
      loadingText={t('common.loading')}
      options={options}
      loading={isLoading}
      disabled={disabled || limitReached}
      disableClearable={disableClearable}
      filterOptions={filterOptions ? undefined : (options) => options}
      sx={FieldAutocompleteSx(sx, fluid)}
      renderTags={!count || !value.length ? undefined : (value) => (
        <Box sx={{ paddingLeft: '8px' }}>
          {value.length} {t('common.chosen')}
        </Box>
      )}
      renderOption={(HTMLLIElementProps, option) => (
        <FieldAutocompleteOption
          key={option.id + option.name}
          HTMLLIElementProps={HTMLLIElementProps}
          option={option}
        />
      )}
      renderInput={(params) => (
        <>
          {label && !labelInside && (
            <FieldLabel
              htmlFor={name}
              label={label}
              required={required}
              onClick={handleOpen}
            />
          )}
          <TextField
            {...params}
            size={'small'}
            placeholder={placeholder ?? t('actions.choose')}
            error={error}
            label={labelInside ? label : undefined}
            inputRef={ref}
            InputProps={FieldAutocompleteInputProps(params, isLoading)}
            disabled={limitReached || disabled}
            sx={getSxTextFieldDefault()}
            onClick={handleOpen}
          />
          <FieldMessage
            show={!!warningMessage ?? limitReached}
            variant={'warning'}
            message={warningMessage ?? `${t('entity.task.errors.executorsLimited')} (${max})`}
          />
          <FieldMessage
            show={error}
            message={errorMessage ?? t('validation.commonSelect')}
          />
        </>
      )}
      multiple
      onOpen={() => handleOpen()}
      onClose={() => setOpen(false)}
      onChange={(_, value) => onChange(value)}
      onInputChange={(_, value) => onInputChange?.(value)}
    />
  )
})
