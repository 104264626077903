import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { LatLng } from 'leaflet'
import { FullScreenLoading } from 'components/FullScreenLoading'
import { useMutationTask } from 'features/tasks/api/useMutationTask'
import { useQueryTask } from 'features/tasks/api/useQueryTask'
import { TasksForm } from 'features/tasks/components/TasksForm'
import { TaskFormData } from 'features/tasks/components/TasksForm/types'
import { SubtaskData } from 'features/tasks/components/TasksSectionSubtasks/TaskDrawerTabSubtasks'
import { taskFormDataToRequestBody } from 'features/tasks/helpers'
import { useToast } from 'hooks/useToast'

export const TasksEditPage = () => {
  const { enqueueSnackbar } = useToast()
  const { t } = useTranslation()
  const { taskID: taskIDRaw } = useParams()
  const mutation = useMutationTask()
  const taskID = +(taskIDRaw ?? '-1')
  const { data, isLoading, isSuccess } = useQueryTask(taskID)

  const handleEdit = useCallback(async (data: TaskFormData, _: SubtaskData[], coords: LatLng | null) => {
    await mutation.mutateAsync({
      taskID: taskID,
      departmentID: data.department.id,
      body: taskFormDataToRequestBody(data, coords),
    })

    enqueueSnackbar(t('entity.task.messages.editSuccess'), { variant: 'success' })

    return taskID
  }, [t, enqueueSnackbar, taskID, mutation])

  return isLoading || !isSuccess ? (
    <FullScreenLoading />
  ) : (
    <TasksForm
      data={data}
      onSave={handleEdit}
    />
  )
}
