import { PropsWithChildren } from 'react'
import { Paper } from '@mui/material'

interface TaskFormSectionProps {}

export const TaskFormSection = ({ children }: PropsWithChildren<TaskFormSectionProps>) => {
  return (
    <Paper
      variant={'outlined'}
      sx={{
        display: 'grid',
        gap: '16px',
        padding: '20px 24px 24px',
        maxWidth: '600px',
      }}
    >
      {children}
    </Paper>
  )
}
