import * as React from 'react'
import { MouseEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Edit, PersonAdd, PersonOutline, PersonRemove } from '@mui/icons-material'
import { ListItemIcon, ListItemText, MenuItem, TableCell, TableRow } from '@mui/material'
import { UserPosition } from 'api/humanagro.generated'
import { useMutationUserUpdate } from 'api/query/useMutationUserUpdate'
import { ButtonContextActions } from 'components/ButtonContextActions'
import ContextMenu from 'components/ContextMenu'
import { useConfirm } from 'components/DialogConfirm/ConfirmProvider'
import { EmployeeContactsList } from 'features/employees/components/EmployeeContactsList'
import { useOpenEmployeeDrawer } from 'features/employees/components/EmployeeDrawer'
import { EmployeeGroupTags } from 'features/employees/components/EmployeeGroupTags'
import { EmployeeRatingIcon } from 'features/employees/components/EmployeeRatingIcon'
import { EmployeeStatusChip } from 'features/employees/components/EmployeeStatusChip'
import { getUserFullName } from 'features/employees/helpers'
import { assertEmployeeStatus, EmployeeGroup, EmployeeStatusCustomized } from 'features/employees/types'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { useToast } from 'hooks/useToast'
import { TABLE_CELL_DENSE_PADDING } from 'consts'
import { tableRowDefaultSx } from 'data'

interface EmployeeTableRowProps {
  employee: UserPosition
  department?: boolean
}

export const EmployeeTableRow = ({ employee, department = true }: EmployeeTableRowProps) => {
  const { id, user, status, status_name } = employee
  const { enqueueSnackbar } = useToast()
  const { t } = useTranslation()
  const confirm = useConfirm()
  const organizationID = useOrganizationID()
  const navigate = useNavigate()
  const update = useMutationUserUpdate()
  const openEmployeeDrawer = useOpenEmployeeDrawer()
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const isContextMenuOpen = useMemo(() => !!anchorEl, [anchorEl])

  const handleContextActionsClick = (e: MouseEvent) => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
  }

  const handleEdit = () => {
    navigate(`/${organizationID}/employees/${id}/edit`)
  }

  const handleMarkAbsent = useCallback(async () => {
    try {
      await update.mutateAsync({
        employeeID: employee.id,
        departmentID: employee.department.id,
        body: {
          status: assertEmployeeStatus(EmployeeStatusCustomized.Absent),
        },
      })
    } catch (error) {
      enqueueSnackbar(t('entity.employee.errors.absent'), { variant: 'error' })
    }
  }, [update, employee, enqueueSnackbar, t])

  const handleMarkAvailable = useCallback(async () => {
    try {
      await update.mutateAsync({
        employeeID: employee.id,
        departmentID: employee.department.id,
        body: {
          status: assertEmployeeStatus(EmployeeStatusCustomized.Available),
        },
      })
    } catch (error) {
      enqueueSnackbar(t('entity.employee.errors.available'), { variant: 'error' })
    }
  }, [update, employee, enqueueSnackbar, t])

  const handleFire = useCallback(async () => {
    const proceed = await confirm({
      title: 'Отметить сотрудника уволенным?',
      description: 'Это необратимое действие',
    })

    if (!proceed) return

    try {
      await update.mutateAsync({
        employeeID: employee.id,
        departmentID: employee.department.id,
        body: {
          status: assertEmployeeStatus(EmployeeStatusCustomized.Fired),
        },
      })
    } catch (error) {
      enqueueSnackbar(t('entity.employee.errors.fire'), { variant: 'error' })
    }
  }, [confirm, update, employee, enqueueSnackbar, t])

  return (
    <>
      <TableRow
        sx={{
          ...tableRowDefaultSx,
          cursor: 'pointer',
        }}
        hover
        onClick={() => openEmployeeDrawer(id)}
      >
        <TableCell sx={{ paddingLeft: TABLE_CELL_DENSE_PADDING, verticalAlign: 'top' }}>
          {id}
        </TableCell>
        <TableCell sx={{ verticalAlign: 'top' }}>
          {getUserFullName(user)}
        </TableCell>
        {department && (
          <TableCell sx={{ verticalAlign: 'top' }}>
            {employee.department.name}
          </TableCell>
        )}
        <TableCell sx={{ verticalAlign: 'top' }}>
          {status ? (
            <EmployeeStatusChip
              status={status}
              statusName={status_name}
            />
          ) : '-'}
        </TableCell>
        <TableCell sx={{ verticalAlign: 'top' }}>
          <EmployeeGroupTags groups={user.groups} />
        </TableCell>
        <TableCell sx={{ verticalAlign: 'top', textAlign: 'center' }}>
          {user.groups.some(({ name }) => name === EmployeeGroup.Worker) && (
            <EmployeeRatingIcon rating={employee.rating?.value ?? null} />
          )}
        </TableCell>
        <TableCell
          sx={{ verticalAlign: 'top' }}
          onClick={(e) => e.stopPropagation()}
        >
          <EmployeeContactsList employee={user} />
        </TableCell>
        <TableCell sx={{ paddingRight: TABLE_CELL_DENSE_PADDING }}>
          {status !== assertEmployeeStatus(EmployeeStatusCustomized.Fired) && (
            <ButtonContextActions
              onClick={handleContextActionsClick}
            />
          )}
        </TableCell>
      </TableRow>
      <ContextMenu
        open={isContextMenuOpen}
        anchorEl={anchorEl}
        onClick={() => setAnchorEl(null)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={handleEdit}>
          <ListItemIcon sx={{ marginRight: '-8px' }}>
            <Edit fontSize={'small'} />
          </ListItemIcon>
          <ListItemText>
            {t('actions.change')}
          </ListItemText>
        </MenuItem>
        {![EmployeeStatusCustomized.Absent, EmployeeStatusCustomized.Sick].some((v) => assertEmployeeStatus(v) === employee.status) && (
          <MenuItem onClick={handleMarkAbsent}>
            <ListItemIcon sx={{ marginRight: '-8px' }}>
              <PersonOutline fontSize={'small'} />
            </ListItemIcon>
            <ListItemText>
              {t('entity.employee.actions.absent')}
            </ListItemText>
          </MenuItem>
        )}
        {[EmployeeStatusCustomized.Absent, EmployeeStatusCustomized.Sick].some((v) => assertEmployeeStatus(v) === employee.status) && (
          <MenuItem onClick={handleMarkAvailable}>
            <ListItemIcon sx={{ marginRight: '-8px' }}>
              <PersonAdd fontSize={'small'} />
            </ListItemIcon>
            <ListItemText>
              {t('entity.employee.actions.available')}
            </ListItemText>
          </MenuItem>
        )}
        {!employee.user.groups.some((g) => g.name === EmployeeGroup.Manager) && (
          <MenuItem onClick={handleFire}>
            <ListItemIcon sx={{ marginRight: '-8px' }}>
              <PersonRemove fontSize={'small'} />
            </ListItemIcon>
            <ListItemText>
              {t('entity.employee.actions.fire')}
            </ListItemText>
          </MenuItem>
        )}
      </ContextMenu>
    </>
  )
}
