import React from 'react'
import { useNavigate } from 'react-router-dom'
import { GroupNameEnum } from 'api/humanagro.generated'
import { EmployeeForm } from 'features/employees/components/EmployeeForm'
import { EmployeeGroupByName } from 'features/employees/data'
import { EmployeeFormData } from 'features/employees/types'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { useToast } from 'hooks/useToast'
import { api } from 'api'

const CreateEmployeePage = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useToast()
  const organizationID = useOrganizationID()

  const createEmployee = async (formData: EmployeeFormData) => api.authUsersCreate({
    organization: organizationID,
    department: formData.department.id,
    first_name: formData.name,
    last_name: formData.surname,
    middle_name: formData.patronymic ? formData.patronymic : undefined,
    phone_number: formData.phone ? formData.phone : null,
    email: formData.email,
    group_name: EmployeeGroupByName[formData.group.name] as unknown as GroupNameEnum,
    born_date: formData.bornDate,
    employment_date: formData.employedFrom,
    salary: formData.salary,
  })

  const handleCreate = async (data: EmployeeFormData) => {
    await createEmployee(data)
    enqueueSnackbar('Сотрудник успешно добавлен', { variant: 'success' })
    navigate(`/${organizationID}/employees`)
  }

  const handleCreateAndContinue = async (data: EmployeeFormData) => {
    await createEmployee(data)
    enqueueSnackbar('Сотрудник успешно добавлен', { variant: 'success' })
  }

  return (
    <EmployeeForm
      onSave={handleCreate}
      onSaveAndContinue={handleCreateAndContinue}
    />
  )
}

export default CreateEmployeePage
