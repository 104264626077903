import { Box, ImageList, ImageListItem, Typography } from '@mui/material'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import { theme } from 'index'
import { DrawerField } from 'components/Drawer'
import { EmployeeAvatar } from 'features/employees/components/EmployeeAvatar'
import { useQueryTaskPhotos } from 'features/tasks/api/useQueryTaskPhotos'
import { FORMAT_DATE_TIME_EXTENSIVE } from 'consts'

interface TaskDrawerTabPhotosProps {
  taskID: number
  departmentID: number
}

export const TaskDrawerTabPhotos = ({ taskID, departmentID }: TaskDrawerTabPhotosProps) => {
  const { data, isSuccess } = useQueryTaskPhotos(taskID, departmentID)

  return (
    <>
      {isSuccess && !!data?.results?.length ? (
        <ImageList
          gap={12}
          sx={{
            margin: 0,
          }}
        >
          {data.results.map((item) => (
            <ImageListItem
              key={item.photo}
            >
              <img
                src={item.photo}
                alt={item.name}
              />
              <Box
                sx={{
                  display: 'grid',
                  gap: '4px',
                  marginTop: '8px',
                }}
              >
                <DrawerField
                  size={'sm'}
                  title={'Автор'}
                  content={(
                    <EmployeeAvatar
                      user={{ id: item.id }}
                      departmentID={departmentID}
                    />
                  )}
                />
                <DrawerField
                  size={'sm'}
                  title={'Дата добавления'}
                  content={item.created_at ? format(new Date(item.created_at), FORMAT_DATE_TIME_EXTENSIVE, { locale: ru }) : '-'}
                />
              </Box>
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        <Typography
          variant={'body2'}
          color={theme.palette.text.secondary}
          sx={{
            textAlign: 'center',
          }}
        >
          Фотографий по задаче не найдено
        </Typography>
      )}
    </>
  )
}
