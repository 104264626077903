import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
import { LoadingButton } from '@mui/lab'
import { Box, Skeleton, Typography } from '@mui/material'
import { theme } from 'index'
import { Check, TaskStatus } from 'api/humanagro.generated'
import { FieldInput } from 'components/Field'
import { Subtask } from 'features/tasks/components/TasksSectionSubtasks/Subtask'
import { SubtaskProps } from 'features/tasks/components/TasksSectionSubtasks/Subtask/Subtask'
import { Writable } from 'types'

export type SubtaskData = Writable<Check> & Pick<Check, 'id'>

interface TaskDrawerTabSubtasksProps {
  taskStatus?: TaskStatus
  subtasks: SubtaskData[]
  loading?: boolean
  onAdd: (name: string) => Promise<void>
  onEdit: SubtaskProps['onEdit']
  onDelete: SubtaskProps['onDelete']
  onStatusChange?: SubtaskProps['onStatusChange']
}

export const TaskDrawerTabSubtasks = ({ taskStatus, subtasks, loading = false, onEdit, onAdd, onDelete, onStatusChange }: TaskDrawerTabSubtasksProps) => {
  const [name, setName] = useState('')
  const { t } = useTranslation()

  const handleAdd = useCallback(async () => {
    await onAdd(name)
    setName('')
  }, [name, onAdd])

  const readonly = taskStatus ? [TaskStatus.Value300, TaskStatus.Value101, TaskStatus.Value400].includes(taskStatus) : false

  return (
    <Box
      sx={{
        display: 'grid',
        gap: '16px',
      }}
    >
      {loading && (
        <>
          <Box
            sx={{
              display: 'flex',
              gap: '20px',
              alignItems: 'center',
              marginBottom: '20px',
            }}
          >
            <Skeleton variant="rounded"
              width={40}
              height={40}
            />
            <Skeleton variant="text"
              sx={{
                width: 570,
                height: 30,
              }}
            />
          </Box>
          <Box sx={{
            display: 'flex',
            gap: '20px',
            alignItems: 'center',
            marginBottom: '8px',
          }}
          >
            <Skeleton variant="rounded"
              width={40}
              height={40}
            />
            <Skeleton variant="text"
              sx={{
                fontSize: '1rem',
                width: 570,
                height: 30,
              }}
            />
          </Box>
        </>
      )}
      {subtasks.length === 0 && readonly && (
        <Typography
          variant={'body2'}
          color={theme.palette.text.secondary}
          sx={{ textAlign: 'center' }}
        >
          Подзадач по задаче нет
        </Typography>
      )}
      {!loading && subtasks.length > 0 && (
        <Box>
          {subtasks.map((subtask) => (
            <Subtask
              key={subtask.id}
              id={subtask.id}
              name={subtask.name}
              status={subtask.status ?? TaskStatus.Value100}
              actionable={taskStatus !== TaskStatus.Value300}
              bottomBorder={false}
              onDelete={onDelete}
              onEdit={onEdit}
              onStatusChange={onStatusChange}
            />
          ))}
        </Box>
      )}
      {!readonly && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '8px',
          }}
        >
          <FieldInput
            name={'name'}
            placeholder={t('entity.taskSubtask.placeholderName')}
            value={name}
            sx={{ flexGrow: 1 }}
            onChange={(e) => setName(e.target.value)}
          />
          <LoadingButton
            variant="outlined"
            size={'medium'}
            disabled={name.length === 0}
            loading={loading}
            onClick={handleAdd}
          >
            <AddIcon/> Добавить
          </LoadingButton>
        </Box>
      )}
    </Box>
  )
}
