import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { Box, Dialog, DialogProps } from '@mui/material'
import { useMutationSubtaskUpdate } from 'api/query/useMutationSubtaskUpdate'
import { DialogHeader } from 'components/DialogHeader'
import { FieldInput } from 'components/Field'

export interface SubtaskDialogEditProps extends Pick<DialogProps, 'open'> {
  name: string
  onClose: () => void
  onEdit: (newValue: string) => Promise<void>
}

export const SubtaskDialogEdit = ({ name, open, onClose, onEdit }: SubtaskDialogEditProps) => {
  const subtaskUpdating = useMutationSubtaskUpdate()
  const { t } = useTranslation()
  const [value, setValue] = useState(name)

  return (
    <Dialog
      open={open}
      maxWidth={'lg'}
      onClose={() => {
        setValue(name)
        onClose()
      }}
    >
      <DialogHeader
        title={t('entity.taskSubtask.titleEdit')}
        onClose={() => {
          setValue(name)
          onClose()
        }}
      />
      <Box
        sx={{
          width: 500,
          padding: '30px 24px',
        }}
      >
        <FieldInput
          name={'name'}
          placeholder={t('entity.taskSubtask.placeholderName')}
          InputContainerProps={{
            sx: {
              height: 40,
              width: '100%',
              marginBottom: '12px',
            },
          }}
          value={value}
          onChange={({ target }) => setValue(target.value)}
        />
        <LoadingButton
          variant="outlined"
          sx={{ height: 40 }}
          disabled={value.length === 0}
          loading={subtaskUpdating.isLoading}
          onClick={async () => {
            await onEdit(value)
            onClose()
          }}
        >
          {t('actions.change')}
        </LoadingButton>
      </Box>
    </Dialog>
  )
}
