import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { matchPath, useLocation } from 'react-router-dom'
import { Book, Dashboard, Group as GroupIcon, Groups, Home, LibraryBooks, TaskAlt } from '@mui/icons-material'
import { Divider, Drawer, List, Toolbar } from '@mui/material'
import { grey } from '@mui/material/colors'
import { SidebarListItem } from 'components/Sidebar/components/SidebarListItem'
import { SidebarOrganizationPanel } from 'components/Sidebar/components/SidebarOrganizationPanel'
import { MenuItem } from 'components/Sidebar/types'
import { useAuth } from 'services/auth'

const Sidebar = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const { isManager } = useAuth()

  const menuItems: MenuItem[] = [
    {
      title: t('menu.home'),
      icon: <Home />,
      route: '/',
      selected: !!matchPath('/:organizationID/', location.pathname),
    },
    {
      title: t('menu.panel'),
      icon: <Dashboard />,
      route: '/dashboard',
      selected: !!matchPath('/:organizationID/dashboard', location.pathname),
    },
    {
      title: t('menu.tasks'),
      icon: <TaskAlt />,
      route: '/tasks',
      selected: !!matchPath('/:organizationID/tasks/*', location.pathname),
      create: true,
    },
    {
      title: t('menu.reports'),
      icon: <LibraryBooks />,
      route: '/reports',
      selected: !!matchPath('/:organizationID/reports/*', location.pathname),
    },
    {
      title: t('menu.employees'),
      icon: <GroupIcon />,
      route: '/employees',
      selected: !!matchPath('/:organizationID/employees/*', location.pathname),
      create: isManager,
    },
    {
      title: t('menu.departments'),
      icon: <Groups />,
      route: '/departments',
      selected: !!matchPath('/:organizationID/departments/*', location.pathname),
      create: isManager,
    },
    {
      title: t('menu.dictionaries'),
      icon: <Book />,
      items: [
        {
          title: t('menu.taskTypes'),
          route: '/dictionaries/task-types',
          selected: !!matchPath('/:organizationID/dictionaries/task-types/*', location.pathname),
          create: true,
        },
        {
          title: t('menu.inventory'),
          route: '/dictionaries/inventory',
          selected: !!matchPath('/:organizationID/dictionaries/inventory/*', location.pathname),
          create: true,
        },
        {
          title: t('menu.equipment'),
          route: '/dictionaries/equipment',
          selected: !!matchPath('/:organizationID/dictionaries/equipment/*', location.pathname),
          create: true,
        },
        {
          title: t('menu.vehicle'),
          route: '/dictionaries/vehicle',
          selected: !!matchPath('/:organizationID/dictionaries/vehicle/*', location.pathname),
          create: true,
        },
      ],
    },
  ]

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: '300px',
        '& .MuiDrawer-paper': {
          width: '300px',
          boxSizing: 'border-box',
          bgcolor: 'grey.100',
        },
      }}
    >
      <Toolbar variant="dense" />
      <SidebarOrganizationPanel />
      <List>
        {menuItems.map(({ route, icon, title, items, selected, create }, index) => (
          <Fragment key={index}>
            <SidebarListItem
              route={route}
              icon={icon}
              title={title}
              create={create}
              items={items}
              selected={selected}
            />
            {index + 1 !== menuItems.length && <Divider color={grey['50']} />}
          </Fragment>
        ))}
      </List>
    </Drawer>
  )
}

export default Sidebar
