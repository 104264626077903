import { ChipProps } from '@mui/material'
import { FieldAutocompleteCommonValue } from 'components/Field/components/FieldAutocomplete/types'
import { EmployeeGroup, EmployeeStatusCustomized } from 'features/employees/types'

export const EmployeeStatusName: Record<EmployeeStatusCustomized, string> = {
  [EmployeeStatusCustomized.Unknown]: 'Неизвестно',
  [EmployeeStatusCustomized.Available]: 'Свободен',
  [EmployeeStatusCustomized.Busy]: 'Занят',
  [EmployeeStatusCustomized.Sick]: 'Болен',
  [EmployeeStatusCustomized.Fired]: 'Уволен',
  [EmployeeStatusCustomized.Absent]: 'Отсутствует',
}

export const EmployeeStatusByName = {
  [EmployeeStatusName[EmployeeStatusCustomized.Unknown]]: EmployeeStatusCustomized.Unknown,
  [EmployeeStatusName[EmployeeStatusCustomized.Available]]: EmployeeStatusCustomized.Available,
  [EmployeeStatusName[EmployeeStatusCustomized.Busy]]: EmployeeStatusCustomized.Busy,
  [EmployeeStatusName[EmployeeStatusCustomized.Sick]]: EmployeeStatusCustomized.Sick,
  [EmployeeStatusName[EmployeeStatusCustomized.Fired]]: EmployeeStatusCustomized.Fired,
  [EmployeeStatusName[EmployeeStatusCustomized.Absent]]: EmployeeStatusCustomized.Absent,
}

export const EmployeeStatusOptions: FieldAutocompleteCommonValue[] = Object.entries(EmployeeStatusName).map(([_, name]) => ({ name }))

export const EmployeeGroupName: Record<EmployeeGroup, string> = {
  [EmployeeGroup.Manager]: 'Руководитель',
  [EmployeeGroup.Specialist]: 'Специалист',
  [EmployeeGroup.Worker]: 'Работник',
}

export const EmployeeGroupByName = {
  [EmployeeGroupName.Manager]: EmployeeGroup.Manager,
  [EmployeeGroupName.Specialist]: EmployeeGroup.Specialist,
  [EmployeeGroupName.Worker]: EmployeeGroup.Worker,
}

export const EmployeeGroupColor: Record<EmployeeGroup, ChipProps['color']> = {
  [EmployeeGroup.Manager]: 'primary',
  [EmployeeGroup.Specialist]: 'info',
  [EmployeeGroup.Worker]: 'default',
}

export const EmployeeGroupOptions: FieldAutocompleteCommonValue[] = Object.entries(EmployeeGroupName).map(([_, name]) => ({ name }))

export const EmployeeGroupAssignable = [EmployeeGroup.Worker, EmployeeGroup.Specialist]

export const employeeFormDefaultValues = {
  name: '',
  surname: '',
  patronymic: '',
  email: '',
  phone: '',
  group: { name: EmployeeGroupName.Worker },
  bornDate: '',
  employedFrom: '',
}
