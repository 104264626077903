import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { LatLng } from 'leaflet'
import { useMutationSubtaskCreate } from 'api/query/useMutationSubtaskCreate'
import { TasksForm } from 'features/tasks/components/TasksForm'
import { TaskFormData } from 'features/tasks/components/TasksForm/types'
import { SubtaskData } from 'features/tasks/components/TasksSectionSubtasks/TaskDrawerTabSubtasks'
import { taskFormDataToRequestBody } from 'features/tasks/helpers'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { useToast } from 'hooks/useToast'
import { api } from 'api'

export const TasksCreatePage = () => {
  const organizationID = useOrganizationID()
  const { enqueueSnackbar } = useToast()
  const { t } = useTranslation()
  const subtaskCreation = useMutationSubtaskCreate()

  const create = useCallback(async (data: TaskFormData, subtasks: SubtaskData[], coords: LatLng | null) => {
    const { data: { id } } = await api.taskOrgDepTaskCreate(organizationID, data.department.id, taskFormDataToRequestBody(data, coords))
    enqueueSnackbar(t('entity.task.messages.createSuccess'), { variant: 'success' })

    for (const subtask of subtasks) {
      await subtaskCreation.mutateAsync({
        name: subtask.name,
        taskID: id,
        departmentID: data.department.id,
      })
    }

    return id
  }, [t, subtaskCreation, organizationID, enqueueSnackbar])

  return (
    <TasksForm
      onSave={create}
      onSaveAndContinue={create}
    />
  )
}
