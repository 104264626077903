import React from 'react'
import { useTranslation } from 'react-i18next'
import { FieldAutocomplete, FieldAutocompleteProps } from 'components/Field'
import { FieldAutocompleteCommonValue } from 'components/Field/components/FieldAutocomplete/types'
import { useDepartmentsQuery } from 'features/departments/hooks/useDepartmentsQuery'

interface DepartmentFieldAutocompleteProps extends Pick<FieldAutocompleteProps<FieldAutocompleteCommonValue>, 'disableClearable' | 'onChange'>{
  department: FieldAutocompleteProps<FieldAutocompleteCommonValue>['value']
  initialValue?: boolean

}

export const DepartmentFieldAutocomplete = ({ department, disableClearable = true, onChange, initialValue = false }: DepartmentFieldAutocompleteProps) => {
  const { t } = useTranslation()
  const departmentsQuery = useDepartmentsQuery((data) => {
    if (initialValue && !department) {
      onChange(data[0] ?? null)
    }
  })

  return (
    <FieldAutocomplete
      name={'department'}
      value={department}
      options={departmentsQuery.data ?? []}
      label={t('entity.department.title')}
      sx={{ width: '300px' }}
      disableClearable={disableClearable}
      labelInside
      filterOptions
      onChange={onChange}
    />
  )
}
