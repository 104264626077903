import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { ButtonAdd } from 'components/ButtonAdd'
import { FieldAutocomplete, FieldAutocompleteMultiple, FieldInput, FieldInputSearchAdornment } from 'components/Field'
import { FieldAutocompleteCommonValue } from 'components/Field/components/FieldAutocomplete/types'
import { Heading } from 'components/Heading'
import { SectionFilters } from 'components/SectionFilters'
import { DepartmentFieldAutocomplete } from 'features/departments/components/DepartmentFieldAutocomplete'
import { DepartmentsAbsentAlert } from 'features/departments/components/DepartmentsAbsentAlert'
import { useQueryTasks } from 'features/tasks/api/useQueryTasks'
import { TasksTable } from 'features/tasks/components/TasksTable'
import { PriorityOptions, PriorityValueByName, StatusOptions, TaskStatusLabel } from 'features/tasks/data'
import { getTasksInitialStatusFilter } from 'features/tasks/helpers'
import { useOrganizationID } from 'hooks/useOrganizationID'

export const TasksPage = () => {
  const { t } = useTranslation()
  const organizationID = useOrganizationID()
  const [searchParams] = useSearchParams()
  const headingRef = useRef<HTMLHeadingElement>()
  const [department, setDepartment] = useState<FieldAutocompleteCommonValue | null>(null)
  const [status, setStatus] = useState<FieldAutocompleteCommonValue[]>(getTasksInitialStatusFilter(searchParams))
  const [priority, setPriority] = useState<FieldAutocompleteCommonValue | null>(null)
  const [page, setPage] = useState(0)
  const { data, search, setSearch, isLoading, isSuccess } = useQueryTasks(
    department?.id ?? undefined,
    undefined,
    page + 1,
    {
      status: status.map(({ name }) => Object.keys(TaskStatusLabel)[Object.values(TaskStatusLabel).findIndex((v) => v === name)]).map((v) => +v) as (0 | 100 | 200 | 300 | 400 | 101 | 110 | 220)[],
      priority: priority?.name ? PriorityValueByName[priority.name] : undefined,
    }
  )

  useEffect(() => {
    document.documentElement.scrollIntoView({ block: 'start' })
  }, [page])

  const handleSetFilter = <T, >(cb: (...args: T[]) => void) => (...args: T[]) => {
    setPage(0)
    cb(...args)
  }

  return (
    <>
      <Heading
        ref={headingRef}
        title={t('menu.tasks')}
        count={!isLoading && isSuccess && data.count}
        actions={<ButtonAdd to={`/${organizationID}/tasks/create`} />}
        sx={{ marginBottom: 2 }}
      />
      <DepartmentsAbsentAlert />
      <SectionFilters>
        <FieldInput
          name={'title'}
          value={search}
          label={'Поиск по названию'}
          error={false}
          InputProps={{ endAdornment: <FieldInputSearchAdornment /> }}
          labelInside
          onChange={(e) => handleSetFilter(setSearch)(e.target.value)}
        />
        <DepartmentFieldAutocomplete
          department={department}
          initialValue
          onChange={handleSetFilter(setDepartment)}
        />
        <FieldAutocompleteMultiple
          name={'status'}
          value={status}
          options={StatusOptions}
          label={t('entity.task.fields.status')}
          sx={{ width: '200px' }}
          count
          labelInside
          filterOptions
          onChange={handleSetFilter(setStatus)}
        />
        <FieldAutocomplete
          name={'priority'}
          value={priority}
          options={PriorityOptions}
          label={t('entity.task.fields.priority')}
          sx={{ width: '200px' }}
          labelInside
          filterOptions
          onChange={handleSetFilter(setPriority)}
        />
      </SectionFilters>
      <TasksTable
        tasks={data?.results}
        isLoading={isLoading}
        isEmpty={!isSuccess}
        count={data?.count}
        page={page}
        onSetPage={setPage}
      />
    </>
  )
}
