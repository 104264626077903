import { useQuery } from 'react-query'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { api } from 'api'

interface QueryTaskPointsParams {
  departmentID: number | null
  query?: Parameters<typeof api.taskOrgDepTaskPointList>[2]
}

export const useQueryTaskPoints = ({ departmentID, query }: QueryTaskPointsParams) => {
  const organizationID = useOrganizationID()
  return useQuery(
    ['task-points', organizationID, departmentID, query],
    async () => {
      const { data } = await api.taskOrgDepTaskPointList(departmentID!.toString(), organizationID.toString(), query)
      return data
    },
    { enabled: !!departmentID }
  )
}
