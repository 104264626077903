import { useQuery } from 'react-query'
import { queryApi } from 'api/queryRequests'
import { useOrganizationID } from 'hooks/useOrganizationID'

export const useQueryTaskEvents = (taskID: number, departmentID: number, enabled = true) => {
  const organizationID = useOrganizationID()
  const query = useQuery(
    ['task-events', organizationID, taskID],
    () => queryApi.getTaskEvents(organizationID, departmentID, taskID, { o: ['-created_at'] }),
    { enabled: taskID !== -1 && enabled }
  )
  const isLoading = query.isFetching && !query.isRefetching

  return { ...query, isLoading }
}
