import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent, Typography,
} from '@mui/material'
import { languages } from 'i18n'
import Cookies from 'js-cookie'
import CookiesKeys from 'data/cookiesKeys'

const SettingsPage = () => {
  const { t, i18n } = useTranslation()
  const [language, setLanguage] = useState(i18n.language)

  const handleChangeLanguage = async (event: SelectChangeEvent) => {
    const language = event.target.value

    Cookies.set(CookiesKeys.language, language)
    setLanguage(language)
    await i18n.changeLanguage(language)
    window.location.reload()
  }

  return (
    <>
      <Typography
        variant={'h5'}
        component={'h1'}
        mb={2}
      >
        {t('userMenu.settings')}
      </Typography>
      <FormControl
        sx={{ minWidth: 120 }}
        size="small"
      >
        <FormLabel
          sx={{ marginBottom: '12px' }}
        >
          {t('common.language')}
        </FormLabel>
        <Select
          id="language"
          value={language}
          onChange={handleChangeLanguage}
        >
          {languages.map((language) => (
            <MenuItem
              key={language.id}
              value={language.id}
            >
              {language.icon} {language.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

export default SettingsPage
