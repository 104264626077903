import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { FullScreenLoading } from 'components/FullScreenLoading'
import { Layout } from 'components/Layout'
import { LayoutSidebar } from 'components/LayoutSidebar'
import NotFoundPage from 'pages/404'
import { DashboardPage } from 'pages/dashboard'
import DepartmentsListPage from 'pages/departments'
import { DepartmentsEditPage } from 'pages/departments/[id]/edit'
import DepartmentsCreatePage from 'pages/departments/create'
import { DictionariesEquipmentPage } from 'pages/dictionaries/equipment'
import { DictionariesEquipmentEditPage } from 'pages/dictionaries/equipment/[id]/edit'
import { DictionariesEquipmentCreatePage } from 'pages/dictionaries/equipment/create'
import { DictionariesInventoryPage } from 'pages/dictionaries/inventory'
import { DictionariesInventoryEditPage } from 'pages/dictionaries/inventory/[id]/edit'
import { DictionariesInventoryCreatePage } from 'pages/dictionaries/inventory/create'
import { DictionariesTaskTypePage } from 'pages/dictionaries/task-types'
import { DictionariesTaskTypeEditPage } from 'pages/dictionaries/task-types/[id]/edit'
import { DictionariesTaskTypeCreatePage } from 'pages/dictionaries/task-types/create'
import { DictionariesVehiclePage } from 'pages/dictionaries/vehicle'
import { DictionariesVehicleEditPage } from 'pages/dictionaries/vehicle/[id]/edit'
import { DictionariesVehicleCreatePage } from 'pages/dictionaries/vehicle/create'
import EmployeesListPage from 'pages/employees'
import { EmployeesEditPage } from 'pages/employees/[id]/edit'
import EmployeesCreatePage from 'pages/employees/create'
import LoginPage from 'pages/login'
import SettingsPage from 'pages/me'
import OrganizationPage from 'pages/organization'
import { ReportsPage } from 'pages/reports'
import { ReportPage } from 'pages/reports/[id]'
import { TasksPage } from 'pages/tasks'
import { TasksEditPage } from 'pages/tasks/[id]/edit'
import { TasksCreatePage } from 'pages/tasks/create'
import { useAuth } from 'services/auth'
import { api } from 'api'
import HomePage from 'pages'

const App = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user, isLoading } = useAuth()
  const dispatch = useDispatch()
  const organizationID = +location.pathname.split('/').slice(1)[0]

  useEffect(() => {
    if (isLoading) return

    if (location.pathname === '/login') {
      if (user) {
        navigate('/')
      }
    } else {
      if (!user) {
        navigate('/login')
      }
    }
  }, [isLoading, user, location, navigate])

  useEffect(() => {
    const fetchData = async () => {
      if (!organizationID) {
        return
      }

      const { data } = await api.organizationOrgRetrieve(+organizationID)

      dispatch({
        type: 'SET_ORGANIZATION',
        payload: data,
      })
    }

    fetchData()
  }, [organizationID, dispatch])

  if (isLoading) {
    return <FullScreenLoading />
  }

  return (
    <>
      <Routes>
        <Route
          path={'/login'}
          element={<LoginPage />}
        />
        <Route
          element={<Layout />}
          path={'/'}
        >
          <Route
            element={<HomePage />}
            index
          />
          <Route
            path={'/settings'}
            element={<SettingsPage />}
          />
        </Route>
        <Route
          element={<LayoutSidebar />}
        >
          <Route
            path={'/:organizationID'}
            element={<OrganizationPage />}
          />
          <Route
            path={'/:organizationID/dashboard'}
            element={<DashboardPage />}
          />
          <Route
            path={'/:organizationID/tasks'}
            element={<TasksPage />}
          />
          <Route
            path={'/:organizationID/tasks/:taskID/edit'}
            element={<TasksEditPage />}
          />
          <Route
            path={'/:organizationID/tasks/create'}
            element={<TasksCreatePage />}
          />
          <Route
            path={'/:organizationID/reports'}
            element={<ReportsPage />}
          />
          <Route
            path={'/:organizationID/reports/:reportID'}
            element={<ReportPage />}
          />
          <Route
            path={'/:organizationID/employees'}
            element={<EmployeesListPage />}
          />
          <Route
            path={'/:organizationID/employees/create'}
            element={<EmployeesCreatePage />}
          />
          <Route
            path={'/:organizationID/employees/:employeeID/edit'}
            element={<EmployeesEditPage />}
          />
          <Route
            path={'/:organizationID/departments'}
            element={<DepartmentsListPage />}
          />
          <Route
            path={'/:organizationID/departments/create'}
            element={<DepartmentsCreatePage />}
          />
          <Route
            path={'/:organizationID/departments/:departmentID/edit'}
            element={<DepartmentsEditPage />}
          />
          <Route
            path={'/:organizationID/dictionaries/task-types'}
            element={<DictionariesTaskTypePage />}
          />
          <Route
            path={'/:organizationID/dictionaries/task-types/create'}
            element={<DictionariesTaskTypeCreatePage />}
          />
          <Route
            path={'/:organizationID/dictionaries/task-types/:entityID/edit'}
            element={<DictionariesTaskTypeEditPage />}
          />
          <Route
            path={'/:organizationID/dictionaries/equipment'}
            element={<DictionariesEquipmentPage />}
          />
          <Route
            path={'/:organizationID/dictionaries/equipment/create'}
            element={<DictionariesEquipmentCreatePage />}
          />
          <Route
            path={'/:organizationID/dictionaries/equipment/:entityID/edit'}
            element={<DictionariesEquipmentEditPage />}
          />
          <Route
            path={'/:organizationID/dictionaries/inventory'}
            element={<DictionariesInventoryPage />}
          />
          <Route
            path={'/:organizationID/dictionaries/inventory/create'}
            element={<DictionariesInventoryCreatePage />}
          />
          <Route
            path={'/:organizationID/dictionaries/inventory/:entityID/edit'}
            element={<DictionariesInventoryEditPage />}
          />
          <Route
            path={'/:organizationID/dictionaries/vehicle'}
            element={<DictionariesVehiclePage />}
          />
          <Route
            path={'/:organizationID/dictionaries/vehicle/create'}
            element={<DictionariesVehicleCreatePage />}
          />
          <Route
            path={'/:organizationID/dictionaries/vehicle/:entityID/edit'}
            element={<DictionariesVehicleEditPage />}
          />
        </Route>
        <Route
          path={'*'}
          element={<NotFoundPage />}
        />
      </Routes>
    </>
  )
}

export default App
