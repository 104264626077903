import { format, isPast, isToday } from 'date-fns'
import { theme } from 'index'
import { LatLng } from 'leaflet'
import { TaskCreate, TaskDetailed, TaskStatus } from 'api/humanagro.generated'
import { FieldAutocompleteCommonValue } from 'components/Field/components/FieldAutocomplete/types'
import { MapMarkerProps } from 'components/Map/components/MapMarker'
import { getUserFullName } from 'features/employees/helpers'
import { getDateTimeString } from 'features/tasks/components/TasksForm/helpers'
import { TaskFormData } from 'features/tasks/components/TasksForm/types'
import { Priority, PriorityName, PriorityValue, TaskStatusCustomized, TaskStatusLabel } from 'features/tasks/data'
import { FORMAT_TIME, LIMIT_QUERY_DEFAULT } from 'consts'
import { LIST_FILTER_KEY } from 'data'
import { Writable } from 'types'

export const getDeadlineColor = (deadline: Date, status = TaskStatus.Value0): string => {
  if (isToday(deadline)) {
    return theme.palette.warning.main
  } else if (isPast(deadline) && [TaskStatusCustomized.Process, TaskStatusCustomized.Created].some(s => s as number === status)) {
    return theme.palette.error.main
  } else {
    return theme.palette.grey['600']
  }
}

export const getTasksPaginationCount = (count: number): number => {
  return Math.trunc(count / LIMIT_QUERY_DEFAULT) + (count % LIMIT_QUERY_DEFAULT > 0 ? 1 : 0)
}

export const getTaskMarkerColorByStatus = (status: TaskStatus): MapMarkerProps['color'] => {
  switch (status) {
    case TaskStatus.Value100:
      return 'grey'
    case TaskStatus.Value110:
      return 'orange'
    case TaskStatus.Value200:
      return 'orange'
    default:
      return 'base'
  }
}

export const taskFormDataToRequestBody = (data: TaskFormData, coords: LatLng | null): Writable<TaskCreate> => ({
  name: data.title,
  executors: data.executors.map(({ id }) => id),
  task_type: data.type.id,
  task_subtype: data.subtype?.id,
  equipment: data.equipment?.id,
  inventory: data.inventory?.id,
  vehicle: data.vehicle?.id,
  start_latitude: coords?.lat ?? 0,
  start_longitude: coords?.lng ?? 0,
  priority: PriorityValue[data.priority.name as Priority],
  planned_start_at: getDateTimeString(data.startDate, data.startTime.name),
  deadline: getDateTimeString(data.endDate, data.endTime.name),
  description: data.description ?? '',
  status: TaskStatus.Value100,
})

export const getTaskFormDefaultValuesFromData = (data: TaskDetailed) => ({
  title: data.name ?? '',
  description: data.description ?? '',
  type: data.task_type,
  subtype: data.task_subtype ? {
    id: data.task_subtype.id,
    name: data.task_subtype.name,
  } : undefined,
  department: data.department,
  executors: data.executors.map((user) => ({ id: user.id, name: getUserFullName(user) ?? user.email })),
  startDate: data.planned_start_at ? new Date(data.planned_start_at) : undefined,
  startTime: data.planned_start_at ? { name: format(new Date(data.planned_start_at), FORMAT_TIME) } : undefined,
  endDate: data.deadline ? new Date(data.deadline) : undefined,
  endTime: data.deadline ? { name: format(new Date(data.deadline), FORMAT_TIME) } : undefined,
  priority: data.priority ? {
    id: data.priority,
    name: PriorityName[data.priority],
  } : undefined,
  equipment: data.equipment,
  vehicle: data.vehicle,
  inventory: data.inventory,
})

export const getTasksInitialStatusFilter = (searchParams: URLSearchParams): FieldAutocompleteCommonValue[] => {
  switch (searchParams.get(LIST_FILTER_KEY)) {
    case 'done':
      return [{ id: TaskStatusCustomized.Complete, name: TaskStatusLabel[TaskStatusCustomized.Complete] }]
    case 'started':
      return [
        { id: TaskStatusCustomized.Process, name: TaskStatusLabel[TaskStatusCustomized.Process] },
        { id: TaskStatusCustomized.OnTheWay, name: TaskStatusLabel[TaskStatusCustomized.OnTheWay] },
      ]
    case 'cancelled':
      return [{ id: TaskStatusCustomized.Canceled, name: TaskStatusLabel[TaskStatusCustomized.Canceled] }]
    default:
      return []
  }
}
