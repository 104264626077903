import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDebounce } from '@react-hook/debounce'
import { UserPosition } from 'api/humanagro.generated'
import { queryApi } from 'api/queryRequests'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { api } from 'api'
import { LIMIT_QUERY_DEFAULT, WAIT_DEBOUNCE_DEFAULT } from 'consts'

export const useQueryTasks = (
  departmentID: number | undefined,
  employee: UserPosition | undefined,
  page: number,
  body?: Parameters<typeof api.taskOrgDepTaskList>[2]
) => {
  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useDebounce(search, WAIT_DEBOUNCE_DEFAULT)
  const organizationID = useOrganizationID()
  const query = useQuery(
    ['tasks', organizationID, departmentID ?? employee?.department, page, body, debouncedSearch, employee?.id],
    () => queryApi.getTasks(organizationID, departmentID ?? employee?.department?.id!, {
      ...(body ? body : {}),
      name: debouncedSearch,
      limit: body?.limit ?? LIMIT_QUERY_DEFAULT,
      offset: (page - 1) * (body?.limit ?? LIMIT_QUERY_DEFAULT),
      executors: employee ? [employee.user.id] : [],
    }),
    { enabled: !!organizationID && !!(departmentID ?? employee?.department) }
  )
  const isLoading = query.isFetching && !query.isRefetching

  useEffect(() => {
    setDebouncedSearch(search)
  }, [search, setDebouncedSearch])

  return { ...query, search, setSearch, isLoading }
}
