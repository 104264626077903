import { isAfter, isEqual, setHours, setMinutes } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { TaskFormData } from 'features/tasks/components/TasksForm/types'
import { TIMEZONE_CURRENT } from 'consts'

export const getDateTime = (date: Date, time: string): Date => {
  const [hours, minutes] = time.split(':')
  let resultDate = date

  resultDate = setHours(resultDate, +hours)
  resultDate = setMinutes(resultDate, +minutes)

  return resultDate
}

export const getDateZoned = (date: Date): Date => {
  return zonedTimeToUtc(date, TIMEZONE_CURRENT)
}

export const getDateString = (date: Date): string => {
  return getDateZoned(date).toISOString()
}

export const getDateTimeString = (date: Date, time: string): string => {
  return getDateZoned(getDateTime(date, time)).toISOString()
}
export const isEndDateValid = (data: TaskFormData): boolean => {
  const [startDateTime, endDateTime] = [
    getDateTime(data.startDate, data.startTime.name),
    getDateTime(data.endDate, data.endTime.name),
  ]
  return !isAfter(startDateTime, endDateTime) && !isEqual(startDateTime, endDateTime)
}
