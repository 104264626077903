import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { useQueryEmployee } from 'api/query/useQueryEmployee'
import { FullScreenLoading } from 'components/FullScreenLoading'
import { EmployeeForm } from 'features/employees/components/EmployeeForm'
import { EmployeeFormData } from 'features/employees/types'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { useToast } from 'hooks/useToast'
import { api } from 'api'
import { FORMAT_DATE_DASHBOARD } from 'consts'

export const EmployeesEditPage = () => {
  const navigate = useNavigate()
  const { employeeID } = useParams()
  const id = +(employeeID ?? '0')
  const { enqueueSnackbar } = useToast()
  const organizationID = useOrganizationID()
  const { data, isLoading, isSuccess } = useQueryEmployee(id, !!employeeID, { cacheTime: 0 })

  const editEmployee = async (formData: EmployeeFormData) => api.organizationOrgDepUserPositionsPartialUpdate(
    formData.department.id.toString(),
    id,
    organizationID.toString(),
    {
      first_name: formData.name,
      last_name: formData.surname,
      middle_name: formData.patronymic ? formData.patronymic : undefined,
      phone_number: formData.phone ? formData.phone : undefined,
      email: formData.email,
      born_date: format(new Date(formData.bornDate), FORMAT_DATE_DASHBOARD),
      employment_date: format(new Date(formData.employedFrom), FORMAT_DATE_DASHBOARD),
      salary: formData.salary,
    }
  )

  const handleEdit = async (data: EmployeeFormData) => {
    await editEmployee(data)
    enqueueSnackbar('Сотрудник успешно изменен', { variant: 'success' })
    navigate(`/${organizationID}/employees`)
  }

  return isLoading || !isSuccess ? (
    <FullScreenLoading />
  ) : (
    <EmployeeForm
      data={data}
      onSave={handleEdit}
    />
  )
}
