import { useMutation } from 'react-query'
import { PatchedTaskCreate } from 'api/humanagro.generated'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { queryClient } from 'services/query'
import { api } from 'api'
import { Writable } from 'types'

export const useMutationTask = () => {
  const organizationID = useOrganizationID()

  return useMutation({
    mutationFn: async ({
      taskID,
      departmentID,
      body,
    }: {
      taskID: number,
      departmentID: number
      body: Writable<PatchedTaskCreate>
    }) => {
      return await api.taskOrgDepTaskPartialUpdate(departmentID.toString(), taskID, organizationID.toString(), body)
    },
    onSuccess: async ({ data }, { taskID, departmentID }) => {
      await Promise.all([
        queryClient.invalidateQueries(['task', organizationID, taskID]),
        queryClient.invalidateQueries(['tasks', organizationID, departmentID]),
      ])
    },
  })
}
