import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { ArrowDropDown, Logout, Person, Settings } from '@mui/icons-material'
import { Avatar, Box, Button, Divider, ListItemIcon, MenuItem, Typography } from '@mui/material'
import { green } from '@mui/material/colors'
import ContextMenu from 'components/ContextMenu'
import { EmployeeGroupTags } from 'features/employees/components/EmployeeGroupTags'
import { getUserFullName } from 'features/employees/helpers'
import { useAuth } from 'services/auth'

const HeaderProfileMenu = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { signOut, user } = useAuth()
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const isUserMenuOpen = Boolean(anchorEl)

  const handleUserMenuClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSignOutClick = async () => {
    signOut()
    dispatch({
      type: 'SET_ORGANIZATION',
      payload: null,
    })
    navigate('/login')
  }

  return (
    <>
      <Button
        size={'small'}
        aria-controls={isUserMenuOpen ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isUserMenuOpen ? 'true' : undefined}
        sx={{ padding: 0, minWidth: 0 }}
        disableRipple
        onClick={handleUserMenuClick}
      >
        <Avatar
          id={'account-menu-avatar'}
          sx={{
            width: '28px',
            height: '28px',
            backgroundColor: green['400'],
            boxShadow: 1,
          }}
        >
          <Person sx={{ fontSize: '20px' }} />
        </Avatar>
        <ArrowDropDown
          sx={{
            color: 'white',
            ...(!isUserMenuOpen ? {} : {
              transform: 'rotate(180deg)',
            }),
          }}
        />
      </Button>
      <ContextMenu
        anchorEl={anchorEl}
        id={'account-menu'}
        open={isUserMenuOpen}
        disableScrollLock
        onClose={handleClose}
        onClick={handleClose}
      >
        {user && (
          <Box>
            <Box
              sx={{
                padding: '0 20px 12px 12px',
              }}
            >
              <Typography
                variant={'body1'}
              >
                {getUserFullName(user)}
              </Typography>
              <Typography
                variant={'body2'}
              >
                {user?.email}
              </Typography>
              <EmployeeGroupTags
                groups={user?.groups ?? []}
                sx={{ marginTop: '8px' }}
              />
            </Box>
            <Divider sx={{ marginBottom: '8px' }} />
          </Box>
        )}
        <MenuItem
          to={'/settings'}
          component={RouterLink}
        >
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          {t('userMenu.settings')}
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleSignOutClick}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t('userMenu.logout')}
        </MenuItem>
      </ContextMenu>
    </>
  )
}

export default HeaderProfileMenu
