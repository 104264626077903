import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { subYears } from 'date-fns'
import { UserPosition } from 'api/humanagro.generated'
import { FieldAutocompleteControlled, FieldDatepickerControlled, FieldInputControlled } from 'components/Field'
import { Form } from 'components/Form'
import { FormActions } from 'components/FormActions'
import { useDepartmentsAutocompleteQuery } from 'features/departments/hooks/useDepartmentsAutocompleteQuery'
import {
  employeeFormDefaultValues,
  EmployeeGroupByName,
  EmployeeGroupName,
  EmployeeGroupOptions,
} from 'features/employees/data'
import { EmployeeFormData } from 'features/employees/types'
import { useGoBack } from 'hooks/useGoBack'
import { useHandleError } from 'hooks/useHandleError'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { emailValidationOptions, phoneValidationOptions, titleValidationOptions } from 'data'

export interface EmployeeFormProps {
  data?: UserPosition
  onSave: (data: EmployeeFormData) => Promise<void>
  onSaveAndContinue?: (data: EmployeeFormData) => Promise<void>
}

export const EmployeeForm = ({ data, onSave, onSaveAndContinue }: EmployeeFormProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const handleError = useHandleError()
  const organizationID = useOrganizationID()
  const departmentsQuery = useDepartmentsAutocompleteQuery()
  const editing = !!data
  const { formState: { isSubmitting, isSubmitSuccessful }, control, getValues, reset, handleSubmit } = useForm<EmployeeFormData>({
    defaultValues: data ? {
      name: data.user.first_name ?? '',
      surname: data.user.last_name ?? '',
      patronymic: data.user.middle_name ?? '',
      email: data.user.email ?? '',
      phone: data.user.phone_number ?? '',
      group: { name: EmployeeGroupByName[data.user.groups[0]?.name] ?? EmployeeGroupName.Worker },
      department: data.department,
      bornDate: data.born_date ?? '',
      employedFrom: data.employment_date ?? '',
      salary: data.salary,
    } : employeeFormDefaultValues,
  })
  const goBack = useGoBack({ getValues })

  const handleCreate = async (data: EmployeeFormData) => {
    try {
      await onSave(data)
      navigate(`/${organizationID}/employees`)
    } catch (error) {
      handleError(error)
    }
  }

  const handleCreateAndContinue = async (data: EmployeeFormData) => {
    try {
      await onSaveAndContinue?.(data)
      reset()
    } catch (error) {
      handleError(error)
    }
  }

  return (
    <Form
      title={t('pages.addEmployee')}
      content={(
        <>
          <FieldInputControlled
            name={'name'}
            control={control}
            rules={titleValidationOptions}
            placeholder={'Иван'}
            label={t('entity.employee.fields.name')}
            autoFocus
          />
          <FieldInputControlled
            name={'surname'}
            control={control}
            rules={titleValidationOptions}
            placeholder={'Иванов'}
            label={t('entity.employee.fields.surname')}
          />
          <FieldInputControlled
            name={'patronymic'}
            control={control}
            rules={{
              ...titleValidationOptions,
              required: false,
            }}
            placeholder={'Не обязательно'}
            label={t('entity.employee.fields.patronymic')}
          />
          <FieldInputControlled
            name={'phone'}
            control={control}
            rules={phoneValidationOptions}
            placeholder={'+7'}
            label={t('entity.employee.fields.telephone')}
          />
          <FieldInputControlled
            name={'email'}
            control={control}
            rules={emailValidationOptions}
            placeholder={'ivanov@yandex.ru'}
            label={t('entity.employee.fields.email')}
          />
          <FieldAutocompleteControlled
            name={'department'}
            control={control}
            rules={{ required: true }}
            disabled={editing}
            label={t('entity.employee.fields.department')}
            options={departmentsQuery.data ?? []}
            isLoading={departmentsQuery.isLoading}
            onInputChange={departmentsQuery.setSearch}
          />
          <FieldAutocompleteControlled
            name={'group'}
            control={control}
            rules={{ required: true }}
            disabled={editing}
            label={t('entity.employee.fields.role')}
            options={EmployeeGroupOptions.slice(1)}
            disableClearable
          />
          <FieldInputControlled
            name={'salary'}
            control={control}
            rules={{
              required: true,
              min: 0,
              max: 10000000,
            }}
            type={'number'}
            placeholder={'50000'}
            label={'Заработная плата'}
          />
          <FieldDatepickerControlled
            name={'bornDate'}
            control={control}
            rules={{ required: true }}
            label={'Дата рождения'}
            maxDate={subYears(new Date(), 18)}
            disableFuture
          />
          <FieldDatepickerControlled
            name={'employedFrom'}
            control={control}
            rules={{ required: true }}
            label={'Дата трудоустройства'}
            disableFuture
          />
        </>
      )}
      actions={(
        <FormActions
          isSubmitting={isSubmitting}
          isSubmitSuccessful={isSubmitSuccessful}
          onGoBack={goBack}
          onSubmitAndSave={onSaveAndContinue ? handleSubmit(handleCreateAndContinue) : undefined}
        />
      )}
      onSubmit={handleSubmit(handleCreate)}
    />
  )
}
