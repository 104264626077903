import { ReactNode, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Add, ExpandLess, ExpandMore } from '@mui/icons-material'
import { Collapse, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { blue, grey } from '@mui/material/colors'
import { theme } from 'index'
import { SubMenuItem } from 'components/Sidebar/types'
import { useOrganizationID } from 'hooks/useOrganizationID'

interface SidebarListItemProps {
  icon: ReactNode
  title: string
  route?: string
  create?: boolean
  items?: SubMenuItem[]
  selected?: boolean
}

export const SidebarListItem = ({ route, icon, title, create = false, items = [], selected = false }: SidebarListItemProps) => {
  const organizationID = useOrganizationID()
  const [isExpanded, setIsExpanded] = useState<boolean>(items!.some(({ selected }) => selected))

  const handleClick = () => {
    setIsExpanded((state) => !state)
  }

  return (
    <>
      <ListItem
        secondaryAction={(route && create) && (
          <IconButton
            to={`/${organizationID}${route}/create`}
            component={RouterLink}
            size={'small'}
            sx={{
              width: 28,
              height: 28,
              background: theme.palette.common.white,
              border: '1px solid',
              borderColor: theme.palette.divider,
              marginRight: '-4px',
            }}
          >
            <Add fontSize={'small'} />
          </IconButton>
        )}
        disablePadding
      >
        <ListItemButton
          {...(route ? {
            to: `/${organizationID}${route}`,
            component: RouterLink,
          } : {
            onClick: handleClick,
          })}
          sx={{
            paddingY: '12px',
          }}
          selected={selected || (!isExpanded && items?.some(({ selected }) => selected))}
        >
          <ListItemIcon
            sx={{
              minWidth: '40px',
              color: selected ? blue['700'] : undefined,
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            primary={title}
            sx={{ margin: 0 }}
          />
          {!route && (
            <>
              {isExpanded ? <ExpandLess sx={{ color: grey['600'] }} /> : <ExpandMore sx={{ color: grey['600'] }} />}
            </>
          )}
        </ListItemButton>
      </ListItem>
      <Collapse
        in={isExpanded}
        timeout={0}
        unmountOnExit
      >
        <List
          component="div"
          disablePadding
        >
          {items?.map(({ title, route, create, selected }) => (
            <ListItem
              key={title}
              secondaryAction={(route && create) && (
                <IconButton
                  to={`/${organizationID}${route}/create`}
                  component={RouterLink}
                  size={'small'}
                  sx={{
                    width: 28,
                    height: 28,
                    background: theme.palette.common.white,
                    border: '1px solid',
                    borderColor: theme.palette.divider,
                    marginRight: '-4px',
                  }}
                >
                  <Add fontSize={'small'} />
                </IconButton>
              )}
              disablePadding
            >
              <ListItemButton
                to={`/${organizationID}${route}`}
                component={RouterLink}
                sx={{ pl: 7, paddingY: '4px' }}
                selected={selected}
              >
                <ListItemText primary={title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  )
}
