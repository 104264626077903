import { useQuery } from 'react-query'
import { TaskTimeRecommendation } from 'api/humanagro.generated'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { api } from 'api'
import { Writable } from 'types'

export type BestExecutorsRequestBody = Writable<TaskTimeRecommendation>

export const isBestExecutorsRequestBodyValid = (body: Partial<BestExecutorsRequestBody>): body is BestExecutorsRequestBody => {
  return !!(body.planned_start_at && body.task_type)
}

export const useQueryTaskBestExecutors = (departmentID: number, executorIDs: number[], body: Partial<BestExecutorsRequestBody>) => {
  const organizationID = useOrganizationID()
  return useQuery(
    ['task-best-executors', departmentID, executorIDs, body],
    async () => {
      if (!isBestExecutorsRequestBodyValid(body)) {
        return
      }

      const { data } = await api.analyticsOrgDepBestWorkerCreate(
        departmentID.toString(),
        organizationID.toString(),
        body
      )

      return data.result.sort((a, b) => b.value - a.value).filter(({ user: { id } }) => !executorIDs.includes(id))
    },
    { enabled: isBestExecutorsRequestBodyValid(body) && !!departmentID }
  )
}
