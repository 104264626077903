import React, { ReactNode } from 'react'
import { Close } from '@mui/icons-material'
import { Box, Drawer as MUIDrawer, DrawerProps as MUIDrawerProps, IconButton } from '@mui/material'
import { DrawerSize } from 'components/Drawer/types'

export interface DrawerProps extends Pick<MUIDrawerProps, 'open'> {
  children: ReactNode
  size?: 'small' | 'medium' | 'large'
  onClose: () => void
}

export const Drawer = ({ children, open, size = 'medium', onClose }: DrawerProps) => {
  return (
    <MUIDrawer
      anchor={'right'}
      open={open}
      sx={{ zIndex: 1201 }}
      onClose={onClose}
    >
      <IconButton
        aria-label="close"
        sx={{
          position: 'absolute',
          zIndex: 1,
          right: 12,
          top: 12,
        }}
        onClick={onClose}
      >
        <Close />
      </IconButton>
      <Box
        sx={{
          position: 'relative',
          display: 'grid',
          gridTemplateRows: 'min-content 1fr min-content',
          width: DrawerSize[size],
          height: '100vh',
        }}
      >
        {children}
      </Box>
    </MUIDrawer>
  )
}
