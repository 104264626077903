import { useQuery } from 'react-query'
import { Dashboard, OrganizationDashboard } from 'api/humanagro.generated'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { api } from 'api'

export const useQueryDashboard = (departmentID: number | null, from: string, to: string) => {
  const organizationID = useOrganizationID()
  const query = useQuery(
    ['dashboard', organizationID, departmentID, from, to],
    async () => {
      const body: Parameters<typeof api.analyticsOrgDepDashboardList>[2] & Parameters<typeof api.analyticsOrgDashboardList>[1] = {
        period_end_before: from,
        period_end_after: to,
        o: ['-period_end'],
      }
      let results: Dashboard[] | OrganizationDashboard[]

      if (departmentID) {
        const { data } = await api.analyticsOrgDepDashboardList(departmentID!.toString(), organizationID.toString(), body)
        results = data.results ?? []
      } else {
        const { data } = await api.analyticsOrgDashboardList(organizationID.toString(), body)
        results = data.results ?? []
      }

      return results
    }
  )
  const isLoading = query.isFetching && !query.isRefetching

  return { ...query, isLoading }
}
