import { LatLng } from 'leaflet'

export const MAX_TOAST_STACK = 5
export const LIMIT_QUERY_UNLIMITED = 9999
export const LIMIT_QUERY_SMALL = 10
export const LIMIT_QUERY_DEFAULT = 20
export const LIMIT_QUERY_AUTOCOMPLETE = 30
export const WAIT_DEBOUNCE_DEFAULT = 500
export const DEFAULT_ROW_SKELETONS_COUNT = 10
export const TABLE_ID_CELL_WIDTH = '56px'
export const TABLE_CONTEXT_BUTTON_CELL_WIDTH = '50px'
export const TABLE_CELL_DENSE_PADDING = '12px'
export const FORMAT_DATE_DEFAULT = 'dd.MM.yyyy'
export const FORMAT_DATE_TIME = 'dd.MM.yyyy HH:mm'
export const FORMAT_DATE_TIME_EXTENSIVE = 'd MMMM yyyy HH:mm'
export const FORMAT_DATE_DASHBOARD = 'yyyy-MM-dd'
export const FORMAT_TIME = 'HH:mm'
export const UNKNOWN_ERROR_MESSAGE = 'Произошла неизвестная ошибка'
export const DIALOG_WIDTH_LG = '900px'
export const DIALOG_WIDTH_MD = '500px'
export const TIMEZONE_CURRENT = Intl.DateTimeFormat().resolvedOptions().timeZone
export const TODAY = new Date()
export const LAT_LNG_INITIAL: LatLng = new LatLng(55.7558, 37.6173)
export const COLOR_MAIN = '#6BA704'
