import React, { ReactNode } from 'react'
import {
  Box, Paper, SxProps,
} from '@mui/material'
import { Heading, HeadingProps } from 'components/Heading'

type PickedHeadingProps = Pick<HeadingProps, 'title' | 'actions' | 'count'>

export interface SectionProps extends PickedHeadingProps {
  disableBorder?: boolean
  children: ReactNode
  sx?: SxProps,
  componentProps?: {
    header?: {
      sx?: SxProps,
    },
    content?: {
      sx?: SxProps,
    }
  }
}

export const Section = ({ title, actions, count, disableBorder = false, children, sx, componentProps }: SectionProps) => {
  return (
    <Paper
      component={'section'}
      sx={{
        borderRadius: 3,
        border: disableBorder ? '0' : undefined,
        ...(sx ?? {}),
      }}
      variant={'outlined'}
    >
      {(title || actions) && (
        <Heading
          title={title}
          actions={actions}
          count={count}
          sx={{
            padding: '20px 24px 16px',
            ...(componentProps?.header?.sx ?? {}),
          }}
        />
      )}
      <Box
        sx={{
          padding: '0 24px 24px',
          ...(componentProps?.content?.sx ?? {}),
        }}
      >
        {children}
      </Box>
    </Paper>
  )
}
