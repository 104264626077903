import * as React from 'react'
import { Box, SxProps } from '@mui/material'
import { UserGroupsShort } from 'api/humanagro.generated'
import { EmployeeGroupTag } from 'features/employees/components/EmployeeGroupTag'

interface EmployeeGroupTagsProps {
  groups: UserGroupsShort[]
  sx?: SxProps
}

export const EmployeeGroupTags = ({ groups, sx }: EmployeeGroupTagsProps) => {
  return (
    <Box
      sx={{
        display: 'flex', gap: '4px',
        ...(sx ?? {}),
      }}
    >
      {groups.length > 0 ? groups.map((group) => (
        <EmployeeGroupTag
          key={group.id}
          group={group}
        />
      )) : '-'}
    </Box>
  )
}
