import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { Add } from '@mui/icons-material'
import { Button, ButtonProps } from '@mui/material'
import { BUTTON_ADD_ICON_SIZE } from 'components/ButtonAdd/data'

export interface ButtonAddProps {
  to?: string
  size?: 'small' | 'medium'
  onClick?: ButtonProps['onClick']
}

export const ButtonAdd = ({ to, size = 'medium', onClick }: ButtonAddProps) => {
  const { t } = useTranslation()
  return (
    <Button
      {...(to ? {
        to,
        component: RouterLink,
      } : {})}
      size={'small'}
      variant={'outlined'}
      sx={{
        gap: '3px',
        borderRadius: 2,
        paddingLeft: '6px',
        ...(size === 'small' ? {
          padding: '2px 6px 2px 4px',
          fontSize: '12px',
        } : {}),
      }}
      disableElevation
      onClick={onClick}
    >
      <Add sx={{ fontSize: BUTTON_ADD_ICON_SIZE[size] }} />
      {t('actions.add')}
    </Button>
  )
}
