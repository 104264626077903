import React from 'react'
import { Box, Button, Dialog, Typography } from '@mui/material'
import { DialogContent } from 'components/DialogContent'
import { DialogHeader } from 'components/DialogHeader'

export interface DialogConfirmProps {
  open: boolean
  options: {
    title?: string
    description?: string
    confirmText?: string
  }
  onClose: () => void
  onCancel: () => void
  onConfirm: () => void
}

export const DialogConfirm = ({ open, options, onClose, onCancel, onConfirm }: DialogConfirmProps) => {
  return (
    <Dialog
      open={open}
      maxWidth={'lg'}
      onClose={onClose}
    >
      <DialogHeader
        title={options.title ?? 'Вы уверены?'}
        onClose={onClose}
      />
      <DialogContent>
        {!!options.description && (
          <Typography
            variant={'body1'}
            sx={{
              margin: '-12px 0 12px',
            }}
          >
            {options.description}
          </Typography>
        )}
        <Box
          sx={{
            display: 'flex',
            gap: '12px',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            variant={'outlined'}
            onClick={onCancel}
          >
            Отмена
          </Button>
          <Button
            variant={'contained'}
            onClick={onConfirm}
          >
            {options.confirmText ?? 'Да'}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
