import React from 'react'
import { Chip, SxProps, Typography } from '@mui/material'
import { TaskStatus } from 'api/humanagro.generated'
import { StatusChipColor, TaskStatusLabel } from 'features/tasks/data'

interface TaskStatusChipProps {
  status: TaskStatus
  sx?: SxProps
}

export const TaskStatusChip = ({ status, sx }: TaskStatusChipProps) => {
  return (
    <Chip
      size={'small'}
      variant={'outlined'}
      color={StatusChipColor[status]}
      sx={sx}
      label={(
        <Typography
          variant={'caption'}
          fontWeight={600}
          sx={{ textTransform: 'uppercase' }}
        >
          {TaskStatusLabel[status]}
        </Typography>
      )}
    />
  )
}
