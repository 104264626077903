import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Delete, Edit, KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material'
import {
  Collapse,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TableCell,
  TableRow,
} from '@mui/material'
import { format } from 'date-fns'
import { theme } from 'index'
import { TaskType } from 'api/humanagro.generated'
import { ButtonContextActions } from 'components/ButtonContextActions'
import ContextMenu from 'components/ContextMenu'
import { TaskSubtypeTable } from 'features/tasks/components/TaskSubtypeTable'
import { FORMAT_DATE_DEFAULT, TABLE_CELL_DENSE_PADDING } from 'consts'
import { tableRowDefaultSx } from 'data'

interface TaskTypeTableRowProps {
  type: TaskType
  onDelete: (id: number) => void
  onEdit: (id: number) => void
}

const toggleCellWidth = 34+8*2+'px'

export const TaskTypeTableRow = ({ type, onDelete, onEdit }: TaskTypeTableRowProps) => {
  const { id, name, created_at } = type
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const isContextMenuOpen = !!anchorEl

  const handleMenuClick = (e: React.MouseEvent) => {
    setAnchorEl(e.currentTarget)
  }

  return (
    <>
      <TableRow
        sx={{ ...tableRowDefaultSx, '& > *': { borderBottom: 'unset' } }}
        hover
      >
        <TableCell
          sx={{
            width: toggleCellWidth,
            paddingX: '8px',
            verticalAlign: 'top',
            borderBottom: `1px solid ${theme.palette.divider}`,
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          rowSpan={2}
          onClick={() => setOpen((state) => !state)}
        >
          <IconButton
            size="small"
          >
            {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ borderLeft: `1px solid ${theme.palette.divider}`, borderBottom: 'unset' }}>
          {id}
        </TableCell>
        <TableCell>
          {name}
        </TableCell>
        <TableCell>
          {format(new Date(created_at), FORMAT_DATE_DEFAULT)}
        </TableCell>
        <TableCell
          align="right"
          sx={{ paddingRight: TABLE_CELL_DENSE_PADDING }}
        >
          <ButtonContextActions onClick={handleMenuClick} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={4}
          sx={{ padding: 0, borderLeft: `1px solid ${theme.palette.divider}` }}
        >
          <Collapse
            in={open}
            timeout={0}
            unmountOnExit
          >
            <TaskSubtypeTable type={type} />
          </Collapse>
        </TableCell>
      </TableRow>
      <ContextMenu
        open={isContextMenuOpen}
        anchorEl={anchorEl}
        onClick={() => setAnchorEl(null)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => onEdit(id)}>
          <ListItemIcon sx={{ marginRight: '-8px' }}>
            <Edit fontSize={'small'} />
          </ListItemIcon>
          <ListItemText>
            {t('actions.change')}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => onDelete(id)}>
          <ListItemIcon sx={{ marginRight: '-8px' }}>
            <Delete fontSize={'small'} />
          </ListItemIcon>
          <ListItemText>
            {t('actions.delete')}
          </ListItemText>
        </MenuItem>
      </ContextMenu>
    </>
  )
}
