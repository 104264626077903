import { useEffect, useMemo } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import { camelCase } from 'case-anything'
import { FieldInputControlled } from 'components/Field/components/FieldInputControlled'
import { Form } from 'components/Form'
import { getEditRequest, getRetrieveRequest } from 'features/dictionaries/components/DictionariesEditPage/helpers'
import { DictionaryType } from 'features/dictionaries/types'
import { useGoBack } from 'hooks/useGoBack'
import { useHandleError } from 'hooks/useHandleError'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { useToast } from 'hooks/useToast'
import { titleValidationOptions } from 'data'

interface DictionariesEditPageProps {
  type: DictionaryType
}

export const DictionariesEditPage = ({ type }: DictionariesEditPageProps) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useToast()
  const handleError = useHandleError()
  const organizationID = useOrganizationID()
  const { entityID } = useParams()
  const query = useQuery(
    [type, entityID],
    () => getRetrieveRequest(type)(+entityID!, organizationID.toString())
  )
  const {
    formState: { isSubmitting, isSubmitted, isSubmitSuccessful },
    control,
    getValues,
    handleSubmit,
    reset,
  } = useForm({ defaultValues: useMemo(() => ({ name: query.data?.data.name }), [query.data]) })
  const goBack = useGoBack({ getSaved() { return query.data?.data.name === getValues('name') } })

  const handleChange = async ({ name }: FieldValues) => {
    if (isSubmitting || (isSubmitted && isSubmitSuccessful)) {
      return
    }

    try {
      await getEditRequest(type)(+entityID!, organizationID.toString(), { name })
      enqueueSnackbar(t(`entity.${camelCase(type)}.messages.editSuccess`), { variant: 'success' })

      await query.refetch()
    } catch (error) {
      handleError(error)
      throw error
    }
  }

  useEffect(() => {
    reset({ name: query.data?.data.name })
  }, [query.data, reset])

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
  }, [isSubmitSuccessful, reset])

  return (
    <Form
      title={`${t(`pages.${camelCase(type)}Edit`)}${query.data?.data ? ` "${query.data.data.name}"` : '...'}`}
      content={(
        <FieldInputControlled
          name={'name'}
          control={control}
          rules={titleValidationOptions}
          label={t(`entity.${camelCase(type)}.fields.title`)}
          placeholder={t(`entity.${camelCase(type)}.fields.title`)}
          disabled={query.isFetching}
        />
      )}
      actions={(
        <>
          <Button
            type={'button'}
            variant={'outlined'}
            onClick={goBack}
          >
            {t('actions.back')}
          </Button>
          <LoadingButton
            type={'submit'}
            variant={'contained'}
            disabled={isSubmitting && !isSubmitSuccessful}
            loading={query.isFetching}
            disableRipple
            disableElevation
          >
            {t('actions.save')}
          </LoadingButton>
        </>
      )}
      onSubmit={handleSubmit(handleChange)}
    />
  )
}
