import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'

interface FormActionsProps {
  isSubmitting: boolean
  isSubmitSuccessful: boolean
  submitText?: string
  onGoBack: () => void
  onSubmitAndSave?: () => void
}

export const FormActions = ({ isSubmitting, isSubmitSuccessful, submitText, onGoBack, onSubmitAndSave }: FormActionsProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Button
        type={'button'}
        variant={'outlined'}
        onClick={onGoBack}
      >
        {t('actions.back')}
      </Button>
      {!!onSubmitAndSave && (
        <Button
          type={'button'}
          variant={'contained'}
          disabled={isSubmitting && !isSubmitSuccessful}
          disableElevation
          disableRipple
          onClick={onSubmitAndSave}
        >
          {t('actions.saveAndContinue')}
        </Button>
      )}
      <Button
        type={'submit'}
        variant={'contained'}
        disabled={isSubmitting && !isSubmitSuccessful}
        disableRipple
        disableElevation
      >
        {submitText ?? t('actions.save')}
      </Button>
    </>
  )
}
