import { useQuery } from 'react-query'
import { TaskTimeRecommendation } from 'api/humanagro.generated'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { api } from 'api'
import { Writable } from 'types'

export type BestTimeRequestBody = Omit<Writable<TaskTimeRecommendation>, 'deadline' | 'priority'>

export const isBestTimeRequestBodyValid = (body: Partial<TaskTimeRecommendation>): body is BestTimeRequestBody => {
  const { planned_start_at, task_type, user } = body
  return !!(planned_start_at && task_type && user)
}

export const useQueryTaskBestTime = (departmentID: number, body: Partial<BestTimeRequestBody>) => {
  const organizationID = useOrganizationID()
  return useQuery(
    ['task-best-time', departmentID, body],
    async () => {
      if (!isBestTimeRequestBodyValid(body)) {
        return
      }

      const { data } = await api.analyticsOrgDepBestTimeCreate(
        departmentID.toString(),
        organizationID.toString(),
        body
      )

      return data
    },
    { enabled: isBestTimeRequestBodyValid(body) }
  )
}
