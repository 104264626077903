import { ChipProps } from '@mui/material'
import i18n from 'i18n'
import { TaskStatus } from 'api/humanagro.generated'
import { TaskPriority } from 'api/humanagro.generated'
import { FieldAutocompleteCommonValue } from 'components/Field/components/FieldAutocomplete/types'

export enum Priority {
  Unknown = 'Не определен',
  Max = 'Критичный',
  High = 'Высокий',
  Medium = 'Средний',
  Low = 'Низкий',
  Lowest = 'Очень низкий',
}

export const PriorityValue: Record<Priority, TaskPriority> = {
  [Priority.Unknown]: TaskPriority.Value0,
  [Priority.Max]: TaskPriority.Value1,
  [Priority.High]: TaskPriority.Value2,
  [Priority.Medium]: TaskPriority.Value3,
  [Priority.Low]: TaskPriority.Value4,
  [Priority.Lowest]: TaskPriority.Value5,
}

export const PriorityName: Record<TaskPriority, Priority> = {
  [TaskPriority.Value0]: Priority.Unknown,
  [TaskPriority.Value1]: Priority.Max,
  [TaskPriority.Value2]: Priority.High,
  [TaskPriority.Value3]: Priority.Medium,
  [TaskPriority.Value4]: Priority.Low,
  [TaskPriority.Value5]: Priority.Lowest,
}

export const PriorityValueByName: Record<string, TaskPriority> = {
  [PriorityName[TaskPriority.Value0]]: TaskPriority.Value0,
  [PriorityName[TaskPriority.Value1]]: TaskPriority.Value1,
  [PriorityName[TaskPriority.Value2]]: TaskPriority.Value2,
  [PriorityName[TaskPriority.Value3]]: TaskPriority.Value3,
  [PriorityName[TaskPriority.Value4]]: TaskPriority.Value4,
  [PriorityName[TaskPriority.Value5]]: TaskPriority.Value5,
}

export const PriorityOptions: FieldAutocompleteCommonValue[] = Object.values(Priority).map((name) => ({
  name,
})).slice(1)

export enum TaskStatusCustomized {
  Unknown = TaskStatus.Value0,
  Created = TaskStatus.Value100,
  Canceled = TaskStatus.Value101,
  OnTheWay = TaskStatus.Value110,
  Process = TaskStatus.Value200,
  Pause = TaskStatus.Value220,
  Complete = TaskStatus.Value300,
  Error = TaskStatus.Value400,
}

export const TaskStatusRelevant = [
  TaskStatus.Value0,
  TaskStatus.Value100,
  TaskStatus.Value110,
  TaskStatus.Value200,
  TaskStatus.Value400,
]

export const TaskStatusLabel: Record<TaskStatusCustomized, string> = {
  [TaskStatusCustomized.Unknown]:  i18n.t(`entity.taskStatus.${TaskStatusCustomized.Unknown}`),
  [TaskStatusCustomized.Created]: i18n.t(`entity.taskStatus.${TaskStatusCustomized.Created}`),
  [TaskStatusCustomized.Canceled]: i18n.t(`entity.taskStatus.${TaskStatusCustomized.Canceled}`),
  [TaskStatusCustomized.OnTheWay]: i18n.t(`entity.taskStatus.${TaskStatusCustomized.OnTheWay}`),
  [TaskStatusCustomized.Process]: i18n.t(`entity.taskStatus.${TaskStatusCustomized.Process}`),
  [TaskStatusCustomized.Pause]: i18n.t(`entity.taskStatus.${TaskStatusCustomized.Pause}`),
  [TaskStatusCustomized.Complete]: i18n.t(`entity.taskStatus.${TaskStatusCustomized.Complete}`),
  [TaskStatusCustomized.Error]: i18n.t(`entity.taskStatus.${TaskStatusCustomized.Error}`),
}

export const StatusOptions: FieldAutocompleteCommonValue[] = Object.entries(TaskStatusLabel).map(([_, name]) => ({ name }))

export const StatusChipColor: Record<TaskStatusCustomized, ChipProps['color']> = {
  [TaskStatusCustomized.Unknown]: 'default',
  [TaskStatusCustomized.Created]: 'default',
  [TaskStatusCustomized.Canceled]: 'error',
  [TaskStatusCustomized.OnTheWay]: 'warning',
  [TaskStatusCustomized.Process]: 'warning',
  [TaskStatusCustomized.Complete]: 'success',
  [TaskStatusCustomized.Error]: 'error',
  [TaskStatusCustomized.Pause]: 'error',
}
