import React from 'react'
import { useGeolocated } from 'react-geolocated'
import { useTranslation } from 'react-i18next'
import { LayersControl, TileLayer, useMapEvent } from 'react-leaflet'
import { MyLocation } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import { LatLng, LeafletMouseEvent } from 'leaflet'
import { MapAddressSearch } from 'components/Map/components/MapAddressSearch'
import { MapMarker, MapMarkerProps } from 'components/Map/components/MapMarker'
import { MapMarkers } from 'components/Map/components/MapMarkers'
import { MapLayer } from 'components/Map/data'
import { MAP_ACTIONS_Z_INDEX, MarkerData } from 'components/Map/Map'
import { Tooltip } from 'components/Tooltip'
import { useToast } from 'hooks/useToast'
import { V1AddressItem } from 'api'

export interface MapInnerProps extends Pick<MapMarkerProps, 'initiallyOpen'> {
  coords?: LatLng
  markers?: MarkerData[]
  addressSearch?: boolean
  onChange?: (coords: LatLng) => void
}

export const MapInner = ({ coords, markers, addressSearch = false, initiallyOpen, onChange }: MapInnerProps) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useToast()
  const map = useMapEvent('click', (e: LeafletMouseEvent) => {
    if (onChange && (e.originalEvent.target as HTMLDivElement).classList.contains('leaflet-container')) {
      onChange(e.latlng)
    }
  })
  const { coords: geolocation, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
    positionOptions: { enableHighAccuracy: false },
    userDecisionTimeout: 100,
  })

  const handleLocateMe = () => {
    if (isGeolocationAvailable && isGeolocationEnabled && geolocation?.latitude) {
      map.flyTo(new LatLng(geolocation.latitude, geolocation.longitude), 15)
    } else {
      enqueueSnackbar('Невозможно определить местоположение. Проверьте, предоставлен ли доступ', {
        variant: 'error',
      })
    }
  }

  const handleAddressSelect = (address: V1AddressItem | null) => {
    if (address) {
      map.flyTo(new LatLng(+address.lat, +address.lon), 13)
    }
  }

  map.attributionControl.setPrefix('')

  return (
    <>
      <LayersControl position="topright">
        <LayersControl.BaseLayer
          name={t('entity.map.scheme')}
          checked
        >
          <TileLayer
            url={MapLayer.OpenMaps}
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer
          name={t('entity.map.satellite')}
        >
          <TileLayer
            url={MapLayer.Satellite}
            maxZoom={19}
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer
          name={t('entity.map.hybrid')}
        >
          <TileLayer
            url={MapLayer.Hybrid}
            maxZoom={19}
          />
        </LayersControl.BaseLayer>
      </LayersControl>
      {typeof coords !== 'undefined' && (
        <MapMarker
          coords={coords}
          initiallyOpen={initiallyOpen}
          onChangeCoords={onChange}
        />
      )}
      <MapMarkers markers={markers} />
      {!coords && (
        <Tooltip
          placement={'bottom-start'}
          content={(
            <Typography
              variant={'body2'}
            >
              Моё местоположение
            </Typography>
          )}
        >
          <Button
            variant={'contained'}
            sx={{
              position: 'absolute',
              zIndex: MAP_ACTIONS_Z_INDEX,
              bottom: '12px',
              right: '12px',
              minWidth: '44px',
              height: '44px',
              paddingX: 0,
            }}
            onClick={handleLocateMe}
          >
            <MyLocation />
          </Button>
        </Tooltip>
      )}
      {addressSearch && <MapAddressSearch onSelect={handleAddressSelect} />}
    </>
  )
}
