import { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react'
import { DialogConfirm, DialogConfirmProps } from 'components/DialogConfirm/DialogConfirm'

export const useConfirm = () => {
  return useContext(ConfirmContext)
}

const ConfirmContext = createContext<(options: DialogConfirmProps['options']) => Promise<boolean>>(async () => false)

export interface ConfirmProviderProps {}

export const ConfirmProvider = ({ children }: PropsWithChildren<ConfirmProviderProps>) => {
  const [options, setOptions] = useState<DialogConfirmProps['options']>({})
  const [{ resolve }, setResolve] = useState<{ resolve: ((value: boolean) => void) | null }>({ resolve: null })

  const confirm = useCallback((options: DialogConfirmProps['options']): Promise<boolean> => {
    return new Promise((resolve) => {
      setOptions(options)
      setResolve({ resolve })
    })
  }, [])

  const handleClose = useCallback(() => {
    setResolve({ resolve: null })
  }, [])

  const handleCancel = useCallback(() => {
    if (resolve) {
      resolve(false)
      handleClose()
    }
  }, [resolve, handleClose])

  const handleConfirm = useCallback(() => {
    if (resolve) {
      resolve(true)
      handleClose()
    }
  }, [resolve, handleClose])

  return (
    <>
      <ConfirmContext.Provider value={confirm}>
        {children}
      </ConfirmContext.Provider>
      <DialogConfirm
        open={resolve !== null}
        options={options}
        onConfirm={handleConfirm}
        onClose={handleCancel}
        onCancel={handleCancel}
      />
    </>
  )
}
