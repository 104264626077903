import { api } from 'api'
import { LIMIT_QUERY_AUTOCOMPLETE, LIMIT_QUERY_DEFAULT, LIMIT_QUERY_UNLIMITED } from 'consts'

/**
 * Лучше использовать запросы напрямую в хуках
 * @deprecated
 */
export const queryApi = {
  async getUser(userID: number) {
    const { data } = await api.authUsersRetrieve(userID)
    return data
  },
  async getUserPositions(organizationID: number, body: Parameters<typeof api.organizationOrgUserPositionsList>[1]) {
    const { data } = await api.organizationOrgUserPositionsList(`${organizationID}`, body)
    return data
  },
  async getReport(organizationID: number, reportID: number) {
    const { data } = await api.exportOrgReportsRetrieve(reportID, organizationID.toString())
    return data
  },
  async getTaskEvents(organizationID: number, departmentID: number, taskID: number, query?: Parameters<typeof api.taskOrgDepTaskCommentsList>[3]) {
    const { data } = await api.taskOrgDepTaskCommentsList(
      `${departmentID}`,
      `${organizationID}`,
      `${taskID}`,
      query
    )

    return data
  },
  async getTaskPhotos(organizationID: number, departmentID: number, taskID: number) {
    const { data } = await api.taskOrgDepTaskPhotosList(
      `${departmentID}`,
      `${organizationID}`,
      `${taskID}`
    )

    return data
  },
  async getTaskResults(organizationID: number, departmentID: number, taskID: number) {
    const { data } = await api.taskOrgDepTaskResultsList(
      `${departmentID}`,
      `${organizationID}`,
      `${taskID}`
    )

    return data
  },
  async getDepartmentsAutocomplete(organizationID: number, name: string) {
    const { data: { results } } = await api.organizationOrgDepList(`${organizationID}`, {
      limit: LIMIT_QUERY_AUTOCOMPLETE,
      name,
    })
    return results ?? []
  },
  async getTaskTypes(organizationID: number) {
    const { data: { results } } = await api.taskOrgTaskTypesList(`${organizationID}`, {
      limit: LIMIT_QUERY_UNLIMITED,
    })
    return results ?? []
  },
  async getTaskTypesAutocomplete(organizationID: number, name: string) {
    const { data: { results } } = await api.taskOrgTaskTypesList(`${organizationID}`, {
      limit: LIMIT_QUERY_AUTOCOMPLETE,
      name,
    })

    return results ?? []
  },
  async getTaskSubtypes(organizationID: number, taskTypeID: number, body?: Parameters<typeof api.taskOrgTaskSubtypesList>[1]) {
    const { data: { results } } = await api.taskOrgTaskSubtypesList( `${organizationID}`, {
      limit: body?.limit ?? LIMIT_QUERY_DEFAULT,
      task_type: taskTypeID,
      ...(body ?? {}),
    })
    return results ?? []
  },
  async getEquipment(organizationID: number, name?: string) {
    const { data: { results } } = await api.organizationOrgEquipmentList(`${organizationID}`, {
      limit: LIMIT_QUERY_UNLIMITED,
      name,
    })
    return results ?? []
  },
  async getInventory(organizationID: number, name?: string) {
    const { data: { results } } = await api.organizationOrgInventoryList(`${organizationID}`, {
      limit: LIMIT_QUERY_UNLIMITED,
      name,
    })
    return results ?? []
  },
  async getVehicle(organizationID: number, name?: string) {
    const { data: { results } } = await api.organizationOrgVehicleList(`${organizationID}`, {
      limit: LIMIT_QUERY_UNLIMITED,
      name,
    })
    return results ?? []
  },
  async getTasks(organizationID: number, departmentID: number, body?: Parameters<typeof api.taskOrgDepTaskList>[2]) {
    const { data } = await api.taskOrgDepTaskList(`${departmentID}`, `${organizationID}`, {
      limit: body?.limit ?? LIMIT_QUERY_UNLIMITED,
      ...(body ?? {}),
    })

    return {
      ...data,
      results: data.results ?? [],
    }
  },
}

export const getOrganizations = async () => {
  const { data: { results } } = await api.organizationOrgList({ limit: LIMIT_QUERY_UNLIMITED })
  return results ?? []
}

export const getDepartments = async (id: number) => {
  const { data: { results } } = await api.organizationOrgDepList(id.toString(), { limit: LIMIT_QUERY_UNLIMITED })
  return results ?? []
}

export const getDepartment = async (organizationID: number, departmentID: number) => {
  const { data } = await api.organizationOrgDepRetrieve(departmentID, `${organizationID}`)
  return data
}
