import { useMutation } from 'react-query'
import { TaskSubType } from 'api/humanagro.generated'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { queryClient } from 'services/query'
import { api } from 'api'

export const useMutationSubtype = (typeID: number, subtypeID?: number) => {
  const organizationID = useOrganizationID()

  return useMutation({
    mutationFn: async ({ name }: { name: string }) => {
      return subtypeID
        ? await api.taskOrgTaskSubtypesPartialUpdate(subtypeID, organizationID.toString(), { name })
        : await api.taskOrgTaskSubtypesCreate(organizationID.toString(), {
          name,
          task_type: typeID,
        })
    },
    onSuccess: async ({ data }) => {
      if (subtypeID) {
        await queryClient.invalidateQueries(['task', 'subtypes', organizationID, typeID])
      } else {
        queryClient.setQueryData<TaskSubType[]>(['task', 'subtypes', organizationID, typeID, ''], (oldData) => oldData ? [
          ...oldData,
          data,
        ] : (oldData ?? []))
      }
    },
  })
}
