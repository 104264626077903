import { QueryOptions, useQuery } from 'react-query'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { api } from 'api'

export const useQueryEmployee = (employeeID: number, enabled = true, options?: Pick<QueryOptions, 'cacheTime'>) => {
  const organizationID = useOrganizationID()
  const query = useQuery(
    ['employee', organizationID, employeeID],
    async () => {
      const { data } = await api.organizationOrgUserPositionsRetrieve(employeeID, organizationID.toString())
      return data
    },
    {
      enabled: !!organizationID && employeeID !== -1 && enabled,
      ...(options ?? {}),
    }
  )
  const isLoading = query.isFetching && !query.isRefetching

  return { ...query, isLoading }
}
