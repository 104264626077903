import { ReactNode } from 'react'
import { DialogContent as MUIDialogContent } from '@mui/material'
import { DIALOG_CONTENT_SIZE } from 'components/DialogContent/data'

export interface DialogContentProps {
  size?: 'medium' | 'large'
  children: ReactNode
}

export const DialogContent = ({ size = 'medium', children }: DialogContentProps) => {
  return (
    <MUIDialogContent sx={{ width: DIALOG_CONTENT_SIZE[size] }}>
      {children}
    </MUIDialogContent>
  )
}
