import { format, subDays } from 'date-fns'
import { DashboardPeriodOption } from 'features/dashboard/types'
import { getDateZoned } from 'features/tasks/components/TasksForm/helpers'
import { FORMAT_DATE_DASHBOARD, TODAY } from 'consts'

export const DashboardPeriodOptions: DashboardPeriodOption[] = [
  {
    name: 'Сегодня',
    period: {
      from: format(getDateZoned(TODAY), FORMAT_DATE_DASHBOARD),
      to:  format(subDays(getDateZoned(TODAY), 6), FORMAT_DATE_DASHBOARD),
    },
  },
  {
    name: 'Вчера',
    period: {
      from: format(getDateZoned(subDays(TODAY, 1)), FORMAT_DATE_DASHBOARD),
      to: format(getDateZoned(subDays(subDays(TODAY, 1), 6)), FORMAT_DATE_DASHBOARD),
    },
  },
  {
    name: '2 дня назад',
    period: {
      from: format(getDateZoned(subDays(TODAY, 2)), FORMAT_DATE_DASHBOARD),
      to: format(getDateZoned(subDays(subDays(TODAY, 2), 6)), FORMAT_DATE_DASHBOARD),
    },
  },
]

export enum DashboardPageTabKey {
  Stats = 'stats',
  Rating = 'rating'
}
