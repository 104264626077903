import { Fragment, useState } from 'react'
import { Card, Divider, Pagination, Skeleton } from '@mui/material'
import { DashboardEmployeeRatingRow } from 'features/dashboard/components/DashboardEmployeeRatingRow'
import { ANALYTICS_RATING_RESULTS_LIMIT } from 'features/dashboard/constants'
import { getDashboardRatingPaginationCount } from 'features/dashboard/helpers'
import { useQueryRating } from 'features/dashboard/hooks/useQueryRating'

export const DashboardTabRating = () => {
  const [page, setPage] = useState(1)
  const { data, isLoading, isSuccess } = useQueryRating(page)

  return (
    <>
      <Card
        sx={{
          display: 'inline-block',
          padding: '16px',
        }}
      >
        {isLoading || !isSuccess ? (
          <>
            {Array.from({ length: ANALYTICS_RATING_RESULTS_LIMIT } ).map((_, index) => (
              <Fragment key={index}>
                <Skeleton
                  component={'div'}
                  width={450}
                  height={32}
                />
                {index + 1 !== ANALYTICS_RATING_RESULTS_LIMIT && <Divider sx={{ margin: '8px 0' }} />}
              </Fragment>
            ))}
          </>
        ) : (
          <>
            {data.results?.map((userPosition, index) => (
              <Fragment key={userPosition.id}>
                <DashboardEmployeeRatingRow userPosition={userPosition} />
                {index + 1 !== (data.results?.length ?? 0) && <Divider sx={{ margin: '8px 0' }} />}
              </Fragment>
            ))}
          </>
        )}
        {typeof data?.count !== 'undefined' && (
          <Pagination
            variant={'outlined'}
            page={page}
            count={getDashboardRatingPaginationCount(data.count)}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '16px',
            }}
            onChange={(_, page) => setPage(page)}
          />
        )}
      </Card>
    </>
  )
}
