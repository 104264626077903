import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, CircularProgress } from '@mui/material'
import { theme } from 'index'
import { FieldAutocompleteCommonValue } from 'components/Field/components/FieldAutocomplete/types'
import { Map, MarkerData } from 'components/Map'
import { DepartmentFieldAutocomplete } from 'features/departments/components/DepartmentFieldAutocomplete'
import { useQueryTaskPoints } from 'features/tasks/api/useQueryTaskPoints'
import { useQueryTasks } from 'features/tasks/api/useQueryTasks'
import { TasksTable } from 'features/tasks/components/TasksTable'
import { TaskStatusRelevant } from 'features/tasks/data'
import { getMarkerDataFromTaskPoints } from 'features/tasks/helpers/getMarkerDataFromTaskPoints'
import { LIMIT_QUERY_SMALL } from 'consts'
import { RootState } from 'store'

const OrganizationPage = () => {
  const organization = useSelector((state: RootState) => state.organization)
  const [department, setDepartment] = useState<FieldAutocompleteCommonValue | null>(null)
  const [page, setPage] = useState(0)

  const { data: tasks, isLoading: isLoadingTasks, isSuccess: isSuccessTasks } = useQueryTasks(
    department?.id ?? undefined,
    undefined,
    page + 1,
    {
      limit: LIMIT_QUERY_SMALL,
      status: TaskStatusRelevant,
      o: ['-created_at'],
    }
  )

  const { data: taskPoints } = useQueryTaskPoints({
    departmentID: department?.id ?? null,
    query: {
      limit: LIMIT_QUERY_SMALL,
      offset: page * LIMIT_QUERY_SMALL,
      status: TaskStatusRelevant,
      o: ['-created_at'],
    },
  })

  const taskPointsPositions: MarkerData[] = useMemo(() => getMarkerDataFromTaskPoints(taskPoints?.results ?? []), [taskPoints?.results])

  const handleSetFilter = <T, >(cb: (...args: T[]) => void) => (...args: T[]) => {
    setPage(0)
    cb(...args)
  }

  return (
    <>
      {organization ? (
        <Box sx={{ display: 'grid', gap: 3 }}>
          <Map
            markers={taskPointsPositions}
            height={'400px'}
            sx={{
              margin: '-24px -24px 0',
              borderRadius: 0,
              border: 0,
              borderBottom: `1px solid ${theme.palette.grey['400']}`,
            }}
            addressSearch
          />
          <TasksTable
            tasks={tasks?.results}
            count={tasks?.count}
            limit={'small'}
            page={page}
            isLoading={isLoadingTasks}
            isEmpty={!isSuccessTasks}
            renderFilters={(
              <DepartmentFieldAutocomplete
                department={department}
                initialValue
                onChange={handleSetFilter(setDepartment)}
              />
            )}
            onSetPage={setPage}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: '36px 0',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  )
}

export default OrganizationPage
