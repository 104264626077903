import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { ArrowDropDown, Logout } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Card,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
} from '@mui/material'
import { blue, grey } from '@mui/material/colors'
import { RootState } from 'store'

export const SidebarOrganizationPanel = () => {
  const organization = useSelector((state: RootState) => state.organization)
  const [menuAnchorElement, setMenuAnchorElement] = useState<Element | null>(null)

  const isMenuOpen = !!menuAnchorElement
  const menuItems = [
    {
      title: 'Сменить организацию',
      icon:  <Logout />,
      to: '/',
    },
  ]

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          gap: '12px',
          maxWidth: 345,
          margin: '16px 16px 12px',
          padding: '12px',
          flexShrink: 0,
          ':hover': {
            backgroundColor: grey[50],
            cursor: 'pointer',
          },
        }}
        variant={'outlined'}
        title={organization?.full_name}
        onClick={({ currentTarget }) => setMenuAnchorElement(currentTarget)}
      >
        <Avatar
          variant={'square'}
          sx={{ bgcolor: blue[400] }}
        >
          {organization?.full_name?.[0]?.toUpperCase() ?? ''}
        </Avatar>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="body1"
            fontWeight={'bold'}
            component="h2"
            lineHeight={1.2}
            mb={0}
            sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '90%' }}
            gutterBottom
          >
            {organization ? organization.full_name : <Skeleton variant="text" />}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
          >
            Ваша организация
          </Typography>
        </Box>
        <IconButton
          sx={{ marginLeft: 'auto', padding: 0 }}
          disableRipple
        >
          <ArrowDropDown />
        </IconButton>
      </Card>
      <Menu
        open={isMenuOpen}
        anchorEl={menuAnchorElement}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            minWidth: '280px',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        onClose={() => setMenuAnchorElement(null)}
      >
        {menuItems.map(({ title, to, icon }) => (
          <MenuItem
            key={title}
            to={to}
            component={RouterLink}
            onClick={() => setMenuAnchorElement(null)}
          >
            <ListItemIcon>
              {icon}
            </ListItemIcon>
            <ListItemText>
              {title}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
