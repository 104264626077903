import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { TaskStatus } from 'api/humanagro.generated'
import { useConfirm } from 'components/DialogConfirm/ConfirmProvider'
import { useMutationTask } from 'features/tasks/api/useMutationTask'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { useToast } from 'hooks/useToast'

const TaskStatusNonEditable = [TaskStatus.Value101, TaskStatus.Value300, TaskStatus.Value400]

export const useTaskActions = (id: number, status: TaskStatus | null) => {
  const organizationID = useOrganizationID()
  const navigate = useNavigate()
  const confirm = useConfirm()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useToast()
  const mutation = useMutationTask()

  const handleEdit = useCallback(() => navigate(`/${organizationID}/tasks/${id}/edit`), [id, navigate, organizationID])

  const handleCancel = useCallback(async (departmentID: number) => {
    const proceed = await confirm({
      title: 'Отменить задачу?',
      description: 'Это необратимое действие',
    })

    if (!proceed) {
      return
    }

    await mutation.mutateAsync({
      taskID: id,
      departmentID,
      body: {
        status: TaskStatus.Value101,
      },
    })
    enqueueSnackbar(t('entity.task.messages.cancelSuccess'), { variant: 'success' })
  }, [confirm, id, mutation, t, enqueueSnackbar])

  const actions = useMemo(() => ({
    edit: TaskStatusNonEditable.some((s) => s === status) ? null : {
      call: handleEdit,
      loading: mutation.isLoading,
    },
    cancel: TaskStatusNonEditable.some((s) => s === status) ? null : {
      call: handleCancel,
      loading: mutation.isLoading,
    },
  }), [status, handleEdit, handleCancel, mutation.isLoading])

  const actionable = useMemo(() => Object.values(actions).some(Boolean), [actions])

  return {
    actions,
    actionable,
  }
}
