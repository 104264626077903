import { FormEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, AlertTitle, Button, Snackbar, TextField } from '@mui/material'
import Cookies from 'js-cookie'
import AuthFormLayout from 'components/LayoutAuthForm/AuthFormLayout'
import CookiesKeys from 'data/cookiesKeys'
import { EmployeeGroup } from 'features/employees/types'
import { useToast } from 'hooks/useToast'
import { api } from 'api'

const LoginPage = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useToast()
  const [formData, setFormData] = useState({ email: '', password: '' })
  const [isLoading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleSnackbarClose = () => {
    setErrorMessage('')
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    if (isLoading) return

    try {
      setLoading(true)

      const { data: { auth_token } } = await api.authTokenLoginCreate(formData)
      Cookies.set(CookiesKeys.token, auth_token)

      const { data: user } = await api.accountMeRetrieve()

      if (user.groups.every((g) => g.name === EmployeeGroup.Worker) && !user.is_superuser && !user.is_staff) {
        Cookies.remove(CookiesKeys.token)
        enqueueSnackbar('У вас недостаточно прав для входа в систему', { variant: 'error' })
        enqueueSnackbar('Войдите в мобильное приложение, чтобы увидеть ваши задачи', { variant: 'info' })
        return
      }

      navigate('/')
    } catch (error) {
      setErrorMessage('Проверьте правильность ввода эл. почты и пароля')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(errorMessage)}
        autoHideDuration={5000}
        sx={{ width: 400 }}
        onClose={handleSnackbarClose}
      >
        <Alert
          severity="error"
          sx={{ width: '100%' }}
          onClose={handleSnackbarClose}
        >
          <AlertTitle>Ошибка при входе</AlertTitle>
          {errorMessage}
        </Alert>
      </Snackbar>
      <AuthFormLayout
        title={'Вход в систему'}
        onSubmit={handleSubmit}
      >
        <TextField
          margin="normal"
          id="email"
          label="Эл. почта"
          name="email"
          autoComplete="email"
          value={formData.email}
          required
          fullWidth
          autoFocus
          onChange={({ target }) => setFormData({ ...formData, email: target.value })}
        />
        <TextField
          margin="normal"
          name="password"
          label="Пароль"
          type="password"
          id="password"
          autoComplete="current-password"
          value={formData.password}
          required
          fullWidth
          onChange={({ target }) => setFormData({ ...formData, password: target.value })}
        />
        <Button
          size={'large'}
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={isLoading || !formData.email || !formData.password}
          fullWidth
        >
          Войти
        </Button>
      </AuthFormLayout>
    </>
  )
}

export default LoginPage
