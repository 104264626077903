import { queryApi } from 'api/queryRequests'
import { DictionaryType } from 'features/dictionaries/types'
import { api } from 'api'

export const getQueryRequest = (type: DictionaryType) => {
  switch (type) {
    case DictionaryType.Equipment:
      return queryApi.getEquipment
    case DictionaryType.Inventory:
      return queryApi.getInventory
    case DictionaryType.Vehicle:
      return queryApi.getVehicle
    case DictionaryType.TaskTypes:
      return queryApi.getTaskTypes
  }
}

export const getDeleteRequest = (type: DictionaryType) => {
  switch (type) {
    case DictionaryType.Equipment:
      return api.organizationOrgEquipmentDestroy
    case DictionaryType.Inventory:
      return api.organizationOrgInventoryDestroy
    case DictionaryType.Vehicle:
      return api.organizationOrgVehicleDestroy
    case DictionaryType.TaskTypes:
      return api.taskOrgTaskTypesDestroy
  }
}
