import React from 'react'
import { QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import { createTheme, ThemeProvider } from '@mui/material'
import { ruRU } from '@mui/material/locale'
import { SnackbarProvider } from 'notistack'
import { ConfirmProvider } from 'components/DialogConfirm/ConfirmProvider'
import { AuthProvider } from 'services/auth'
import { queryClient } from 'services/query'
import App from 'App'
import { MAX_TOAST_STACK } from 'consts'
import store from 'store'

import 'i18n'
import type {} from '@mui/x-date-pickers/themeAugmentation'
import 'leaflet/dist/leaflet.css'
import 'styles/index.css'
export const theme = createTheme({}, ruRU)
const root = createRoot(document.getElementById('root')!)

root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <AuthProvider>
            <SnackbarProvider maxSnack={MAX_TOAST_STACK}>
              <React.StrictMode>
                <ConfirmProvider>
                  <App />
                </ConfirmProvider>
              </React.StrictMode>
            </SnackbarProvider>
          </AuthProvider>
        </Provider>
      </QueryClientProvider>
    </ThemeProvider>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
