import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDebounce } from '@react-hook/debounce'
import { EmployeeGroupAssignable } from 'features/employees/data'
import { getUserFullName } from 'features/employees/helpers'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { api } from 'api'
import { LIMIT_QUERY_UNLIMITED, WAIT_DEBOUNCE_DEFAULT } from 'consts'

export const useQueryExecutorsAutocomplete = (departmentID: number) => {
  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useDebounce(search, WAIT_DEBOUNCE_DEFAULT)
  const organizationID = useOrganizationID()
  const query = useQuery(
    ['users', organizationID, departmentID, debouncedSearch],
    async () => {
      const { data } = await api.organizationOrgUserPositionsList(`${organizationID}`, {
        department: departmentID,
        name: debouncedSearch,
        status: [0,100,200,300,500],
        group_name: EmployeeGroupAssignable,
        limit: LIMIT_QUERY_UNLIMITED,
      })
      return data
    },
    { enabled: !!organizationID && !!departmentID }
  )
  const data = query.data?.results?.map(({ user }) => ({ id: user.id, name: getUserFullName(user) ?? user.email })) ?? []
  const isLoading = query.isFetching && !query.isRefetching

  useEffect(() => {
    setDebouncedSearch(search)
  }, [search, setDebouncedSearch])

  return { ...query, data, setSearch, isLoading }
}
