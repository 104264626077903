import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Cancel, Edit } from '@mui/icons-material'
import { Box, ListItemIcon, ListItemText, MenuItem, TableCell, TableRow } from '@mui/material'
import { format } from 'date-fns'
import { Task } from 'api/humanagro.generated'
import { ButtonContextActions } from 'components/ButtonContextActions'
import ContextMenu from 'components/ContextMenu'
import { EmployeeAvatar } from 'features/employees/components/EmployeeAvatar'
import { useOpenTaskDrawer } from 'features/tasks/components/TasksDrawer'
import { TaskStatusChip } from 'features/tasks/components/TaskStatusChip'
import { PriorityName } from 'features/tasks/data'
import { useTaskActions } from 'features/tasks/hooks/useTaskActions'
import { FORMAT_DATE_DEFAULT, TABLE_CELL_DENSE_PADDING } from 'consts'
import { tableRowDefaultSx } from 'data'

interface TasksTableRowProps {
  task: Task
}

export const TasksTableRow = ({ task }: TasksTableRowProps) => {
  const { id, name, task_type, status, priority, executors, deadline } = task
  const { t } = useTranslation()
  const openTaskDrawer = useOpenTaskDrawer()
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const { actions, actionable } = useTaskActions(task.id, task.status ?? null)

  return (
    <>
      <TableRow
        sx={{
          ...tableRowDefaultSx,
          cursor: 'pointer',
        }}
        hover
        onClick={() => openTaskDrawer(task.id)}
      >
        <TableCell sx={{ paddingLeft: TABLE_CELL_DENSE_PADDING }}>
          {id}
        </TableCell>
        <TableCell>
          {name}
        </TableCell>
        <TableCell>
          {task_type?.name ?? '-'}
        </TableCell>
        <TableCell>
          {deadline ? format(new Date(deadline), FORMAT_DATE_DEFAULT) : '-'}
        </TableCell>
        <TableCell>
          {status ? <TaskStatusChip status={status} /> : '-'}
        </TableCell>
        <TableCell>
          {priority ? PriorityName[priority] : '-'}
        </TableCell>
        <TableCell>
          {executors.length > 0 ? (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '4px',
                maxWidth: '220px',
              }}
            >
              {executors.map((user) => (
                <EmployeeAvatar
                  key={user.id}
                  user={user}
                  departmentID={task.department}
                />
              ))}
            </Box>
          ) : '-'}
        </TableCell>
        <TableCell
          align="right"
          sx={{ paddingRight: TABLE_CELL_DENSE_PADDING }}
        >
          {actionable && (
            <ButtonContextActions
              onClick={(e) => {
                e.stopPropagation()
                setAnchorEl(e.currentTarget)
              }}
            />
          )}
        </TableCell>
      </TableRow>
      {actionable && (
        <ContextMenu
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClick={() => setAnchorEl(null)}
          onClose={() => setAnchorEl(null)}
        >
          {actions.edit && (
            <MenuItem onClick={actions.edit.call}>
              <ListItemIcon sx={{ marginRight: '-8px' }}>
                <Edit fontSize={'small'} />
              </ListItemIcon>
              <ListItemText>
                {t('actions.change')}
              </ListItemText>
            </MenuItem>
          )}
          {actions.cancel && (
            <MenuItem
              onClick={() => actions.cancel?.call(task.department)}
            >
              <ListItemIcon sx={{ marginRight: '-8px' }}>
                <Cancel fontSize={'small'} />
              </ListItemIcon>
              <ListItemText>
                {t('entity.task.actions.cancel')}
              </ListItemText>
            </MenuItem>
          )}
        </ContextMenu>
      )}
    </>
  )
}
