import { QueryOptions, useQuery } from 'react-query'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { api } from 'api'

export const useQueryTask = (taskID: number, enabled = true, options?: Pick<QueryOptions, 'cacheTime'>) => {
  const organizationID = useOrganizationID()
  return useQuery(
    ['task', organizationID, taskID],
    async () => {
      const { data } = await api.taskTaskRetrieve(taskID)
      return data
    },
    {
      enabled: !!organizationID && taskID !== -1 && enabled,
      ...(options ?? {}),
    }
  )
}
