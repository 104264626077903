import { Box, Card } from '@mui/material'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import { LatLng } from 'leaflet'
import { TaskStatus, UserName } from 'api/humanagro.generated'
import { DrawerField } from 'components/Drawer'
import { Map } from 'components/Map'
import { EmployeeAvatar } from 'features/employees/components/EmployeeAvatar'
import { TaskStatusChip } from 'features/tasks/components/TaskStatusChip'
import { getTaskMarkerColorByStatus } from 'features/tasks/helpers'
import { FORMAT_DATE_TIME_EXTENSIVE } from 'consts'

interface TaskEventCardProps {
  author: UserName
  departmentID: number
  status: TaskStatus | undefined
  date: string | undefined
  comment: string | undefined
  coords?: LatLng
}

export const TaskEventCard = ({ author, departmentID, status, date, comment, coords }: TaskEventCardProps) => {
  return (
    <Card
      variant={'outlined'}
      sx={{
        display: 'grid',
        gap: '8px',
        padding: '12px',
      }}
    >
      <Box>
        <EmployeeAvatar
          user={author}
          departmentID={departmentID}
        />
      </Box>
      {typeof status !== 'undefined' && status !== TaskStatus.Value0 && (
        <DrawerField
          size={'sm'}
          title={'Статус'}
          content={<TaskStatusChip status={status} />}
        />
      )}
      <DrawerField
        size={'sm'}
        title={'Дата'}
        content={date ? format(new Date(date), FORMAT_DATE_TIME_EXTENSIVE, { locale: ru }) : '-'}
      />
      <DrawerField
        size={'sm'}
        title={'Комментарий'}
        content={comment ?? '-'}
      />
      {coords && (
        <Map
          height={'200px'}
          markers={[{
            content: '',
            coords,
            color: getTaskMarkerColorByStatus(status ?? TaskStatus.Value0),
          }]}
          sx={{ marginTop: '8px' }}
        />
      )}
    </Card>
  )
}
