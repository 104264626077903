import { FieldValues } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

export const useGoBack = ({ getValues, getSaved }: { getValues?: () => FieldValues, getSaved?: () => void }) => {
  const navigate = useNavigate()

  return () => {
    if (!getValues && !getSaved) {
      navigate(-1)
      return
    }

    const isUnsaved: boolean = getValues ? Object.values(getValues()).some(Boolean) : !getSaved?.()

    if (isUnsaved) {
      if (window.confirm('На странице есть несохраненные данные. Они могут быть потеряны. Вы уверены?')) {
        navigate(-1)
      }
    } else {
      navigate(-1)
    }
  }
}
