import { grey, red } from '@mui/material/colors'
import { theme } from 'index'

export const useEmployeeRatingColor = (rating: number | null) => {
  if (rating === null) {
    return grey['400']
  } else if (rating <= 1) {
    return red['800']
  } else if (rating <= 2) {
    return red['600']
  } else if (rating <= 3) {
    return theme.palette.warning.dark
  } else if (rating <= 3.5) {
    return theme.palette.warning.main
  } else if (rating <= 4) {
    return theme.palette.warning.light
  } else if (rating <= 4.5) {
    return theme.palette.success.light
  } else {
    return theme.palette.success.main
  }
}
