import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useQueryEmployees } from 'api/query/useQueryEmployees'
import { ButtonAdd } from 'components/ButtonAdd'
import { FieldAutocompleteMultiple } from 'components/Field/components/FieldAutocomplete'
import { FieldAutocompleteCommonValue } from 'components/Field/components/FieldAutocomplete/types'
import { FieldInput, FieldInputSearchAdornment } from 'components/Field/components/FieldInput'
import { Heading } from 'components/Heading'
import { SectionFilters } from 'components/SectionFilters'
import { Switch } from 'components/Switch'
import { TableRowEmpty } from 'components/TableRowEmpty'
import { TableRowSkeleton } from 'components/TableRowSkeleton'
import { DepartmentFieldAutocomplete } from 'features/departments/components/DepartmentFieldAutocomplete'
import { EmployeeTableRow } from 'features/employees/components/EmployeeTableRow'
import { EMPLOYEES_TABLE_COLUMNS } from 'features/employees/constants'
import {
  EmployeeGroupByName,
  EmployeeGroupOptions,
  EmployeeStatusByName,
  EmployeeStatusOptions,
} from 'features/employees/data'
import { getEmployeesInitialStatusFilter } from 'features/employees/helpers'
import { assertEmployeeStatus } from 'features/employees/types'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { useAuth } from 'services/auth'
import { TABLE_CELL_DENSE_PADDING, TABLE_CONTEXT_BUTTON_CELL_WIDTH, TABLE_ID_CELL_WIDTH } from 'consts'

const EmployeesListPage = () => {
  const { t } = useTranslation()
  const organizationID = useOrganizationID()
  const [searchParams] = useSearchParams()
  const { isManager } = useAuth()
  const [department, setDepartment] = useState<FieldAutocompleteCommonValue | null>(null)
  const [group, setGroup] = useState<FieldAutocompleteCommonValue[]>([])
  const [status, setStatus] = useState<FieldAutocompleteCommonValue[]>(getEmployeesInitialStatusFilter(searchParams))
  const { data: employees, search, setSearch, isLoading, isSuccess } = useQueryEmployees({
    department: department?.id,
    group_name: group.map(({ name }) => EmployeeGroupByName[name]),
    status: status.map(({ name }) => assertEmployeeStatus(EmployeeStatusByName[name])),
  })

  return (
    <>
      <Heading
        title={t('menu.employees')}
        count={employees?.length}
        actions={isManager ? <ButtonAdd to={`/${organizationID}/employees/create`} /> : undefined}
        sx={{ marginBottom: 2 }}
      />
      <SectionFilters>
        <FieldInput
          name={'name'}
          value={search}
          label={t('entity.employee.filters.name')}
          error={false}
          InputProps={{ endAdornment: <FieldInputSearchAdornment /> }}
          labelInside
          onChange={(e) => setSearch(e.target.value)}
        />
        <DepartmentFieldAutocomplete
          department={department}
          initialValue={false}
          onChange={setDepartment}
        />
        <FieldAutocompleteMultiple
          name={'status'}
          value={status}
          options={EmployeeStatusOptions}
          label={t('entity.employee.fields.status')}
          sx={{ width: '200px' }}
          count
          labelInside
          filterOptions
          onChange={setStatus}
        />
        <FieldAutocompleteMultiple
          name={'group'}
          value={group}
          options={EmployeeGroupOptions}
          label={t('entity.employee.fields.role')}
          sx={{ width: '200px' }}
          labelInside
          filterOptions
          onChange={setGroup}
        />
      </SectionFilters>
      <Paper variant={'outlined'}>
        <Table size={'small'}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: TABLE_ID_CELL_WIDTH, paddingLeft: TABLE_CELL_DENSE_PADDING }}>
                ID
              </TableCell>
              <TableCell>
                {t('entity.employee.fields.fio')}
              </TableCell>
              {!department && (
                <TableCell>
                  {t('entity.employee.fields.department')}
                </TableCell>
              )}
              <TableCell>
                {t('entity.employee.fields.status')}
              </TableCell>
              <TableCell>
                {t('entity.employee.fields.role')}
              </TableCell>
              <TableCell sx={{ width: '85px' }}>
                {t('entity.employee.fields.rating')}
              </TableCell>
              <TableCell>
                {t('entity.employee.fields.contacts')}
              </TableCell>
              <TableCell
                align="right"
                sx={{ width: TABLE_CONTEXT_BUTTON_CELL_WIDTH, paddingRight: TABLE_CELL_DENSE_PADDING }}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            <Switch
              data={employees}
              renderLoading={() => <TableRowSkeleton columns={EMPLOYEES_TABLE_COLUMNS} />}
              renderEmpty={() => <TableRowEmpty columns={EMPLOYEES_TABLE_COLUMNS} />}
              renderData={(employees) => employees.map((employee) => (
                <EmployeeTableRow
                  key={employee.id}
                  employee={employee}
                  department={!department}
                />
              ))}
              isLoading={isLoading}
              isEmpty={!isSuccess || (employees?.length ?? 0) === 0}
            />
          </TableBody>
        </Table>
      </Paper>
    </>
  )
}

export default EmployeesListPage
