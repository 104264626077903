import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { omit } from 'ramda'
import { searchStringToObject } from 'serialize-query-params'
import { USER_POSITION_DRAWER_QUERY_KEY } from 'features/employees/components/EmployeeDrawer/constants'
import { TASK_DEPARTMENT_DRAWER_QUERY_KEY, TASK_DRAWER_QUERY_KEY } from 'features/tasks/components/TasksDrawer/constants'

export const useOpenEmployeeDrawer = () => {
  const navigate = useNavigate()
  const { search } = useLocation()

  return (userPositionID: number) => navigate({
    search: createSearchParams(omit([TASK_DEPARTMENT_DRAWER_QUERY_KEY, TASK_DRAWER_QUERY_KEY], {
      ...searchStringToObject(search),
      [USER_POSITION_DRAWER_QUERY_KEY]: userPositionID.toString(),
    })).toString(),
  })
}
