import * as React from 'react'
import { Skeleton, TableCell, TableRow } from '@mui/material'
import { TABLE_CELL_DENSE_PADDING, DEFAULT_ROW_SKELETONS_COUNT } from 'consts'
import { tableRowDefaultSx } from 'data'

interface TableRowSkeletonProps {
  columns: number
  amount?: number
}

export const TableRowSkeleton = ({ columns, amount = DEFAULT_ROW_SKELETONS_COUNT }: TableRowSkeletonProps) => {
  return (
    <>
      {Array.from({ length: amount }).map((_, index) => (
        <TableRow
          key={index}
          sx={tableRowDefaultSx}
        >
          <TableCell sx={{ paddingLeft: TABLE_CELL_DENSE_PADDING }}>
            <Skeleton />
          </TableCell>
          {Array.from({ length: columns - 2 > 0 ? columns - 2 : 0 }).map((_, index) => (
            <TableCell key={index}>
              <Skeleton />
            </TableCell>
          ))}
          <TableCell
            align="right"
            sx={{ paddingRight: TABLE_CELL_DENSE_PADDING }}
          />
        </TableRow>
      ))}
    </>
  )
}
