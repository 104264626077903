import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider, Table, TableBody, Typography } from '@mui/material'
import { theme } from 'index'
import { TaskType } from 'api/humanagro.generated'
import { ButtonAdd } from 'components/ButtonAdd'
import { Switch } from 'components/Switch'
import { TableRowEmpty } from 'components/TableRowEmpty'
import { TableRowSkeleton } from 'components/TableRowSkeleton'
import { DictionariesTableHead } from 'features/dictionaries/components/DictionariesTableHead'
import { DictionariesTableRow } from 'features/dictionaries/components/DictionariesTableRow'
import { DICTIONARIES_TABLE_ROW_COLUMNS } from 'features/dictionaries/components/DictionariesTableRow/data'
import { useQueryTaskSubtypes } from 'features/tasks/api/useQueryTaskSubtypes'
import { TaskSubtypeDialogCreate } from 'features/tasks/components/TaskSubtypeDialogCreate'
import { TaskSubtypeDialogEdit } from 'features/tasks/components/TaskSubtypeDialogEdit'
import { useHandleError } from 'hooks/useHandleError'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { queryClient } from 'services/query'
import { api } from 'api'

interface TaskSubtypeTableProps {
  type: TaskType
}

export const TaskSubtypeTable = ({ type }: TaskSubtypeTableProps) => {
  const { t } = useTranslation()
  const organizationID = useOrganizationID()
  const handleError = useHandleError()
  const { data: subtypes, isLoading, isSuccess } = useQueryTaskSubtypes(type.id)
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [editingID, setEditingID] = useState<null | number>(null)

  const handleAddClick = () => {
    setCreateDialogOpen(true)
  }

  const handleEdit = (id: number) => {
    setEditingID(id)
  }

  const handleDelete = async (id: number) => {
    try {
      await api.taskOrgTaskSubtypesDestroy(id, organizationID.toString())
      await queryClient.invalidateQueries(['task', 'subtypes', organizationID, type.id])
    } catch (error) {
      handleError(error)
    }
  }

  return (
    <Box sx={{ background: theme.palette.grey['50'] }}>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          gap: '12px',
          padding: '8px 16px 8px 12px',
        }}
      >
        <Typography
          variant="subtitle1"
          component="div"
          fontWeight={500}
        >
          {t('entity.taskSubtype.title')}
        </Typography>
        <ButtonAdd
          size={'small'}
          onClick={handleAddClick}
        />
      </Box>
      <Divider />
      <Table
        size="small"
      >
        <DictionariesTableHead />
        <TableBody>
          <Switch
            data={subtypes}
            renderEmpty={() => <TableRowEmpty columns={DICTIONARIES_TABLE_ROW_COLUMNS} />}
            renderLoading={() => (
              <TableRowSkeleton
                columns={DICTIONARIES_TABLE_ROW_COLUMNS}
                amount={3}
              />
            )}
            renderData={(subtypes) => subtypes.map(subtype => (
              <DictionariesTableRow
                key={subtype.id}
                id={subtype.id}
                name={subtype.name}
                createdAt={subtype.created_at}
                onDelete={handleDelete}
                onEdit={handleEdit}
              />
            ))}
            isLoading={isLoading}
            isEmpty={!subtypes?.length || !isSuccess}
          />
        </TableBody>
      </Table>
      <TaskSubtypeDialogCreate
        open={createDialogOpen}
        type={type}
        onClose={() => setCreateDialogOpen(false)}
      />
      <TaskSubtypeDialogEdit
        open={!!editingID}
        type={type}
        subtypeID={editingID}
        onClose={() => setEditingID(null)}
      />
    </Box>
  )
}
