import { useQuery } from 'react-query'
import { Department } from 'api/humanagro.generated'
import { getDepartments } from 'api/queryRequests'
import { useOrganizationID } from 'hooks/useOrganizationID'

export const useDepartmentsQuery = (onSuccess?: (data: Department[]) => void) => {
  const organizationID = useOrganizationID()
  const query = useQuery(
    ['departments', organizationID],
    () => getDepartments(organizationID),
    {
      enabled: !!organizationID,
      onSuccess: (data) => onSuccess?.(data),
    }
  )
  const isLoading = query.isFetching && !query.isRefetching

  return { ...query, isLoading }
}
