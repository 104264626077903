import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Box, CssBaseline, Toolbar } from '@mui/material'
import BreadcrumbsNavigation from 'components/BreadcrumbsNavigation/BreadcrumbsNavigation'
import Header from 'components/Header/Header'

export const Layout = () => {
  const location = useLocation()
  const isHomepage = location.pathname === '/'

  return (
    <main>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Header />
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: '1', minHeight: '100vh', bgcolor: 'white' }}>
          <Toolbar variant="dense" />
          {!isHomepage && <BreadcrumbsNavigation />}
          <Box sx={{ padding: 3, flexGrow: 1 }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </main>
  )
}
