import { createSearchParams, NavigateOptions, useLocation, useNavigate } from 'react-router-dom'
import { omit } from 'ramda'
import { searchStringToObject } from 'serialize-query-params'
import { USER_POSITION_DRAWER_QUERY_KEY } from 'features/employees/components/EmployeeDrawer/constants'
import { TASK_DRAWER_QUERY_KEY } from 'features/tasks/components/TasksDrawer/constants'

export const useOpenTaskDrawer = () => {
  const navigate = useNavigate()
  const { search } = useLocation()

  return (taskID: number, options?: NavigateOptions) => navigate({
    search: createSearchParams(omit([USER_POSITION_DRAWER_QUERY_KEY], {
      ...searchStringToObject(search),
      [TASK_DRAWER_QUERY_KEY]: taskID.toString(),
    })).toString(),
  }, options)
}
