import { useTheme } from '@mui/material'
import { EmployeeStatusCustomized } from 'features/employees/types'

export const useEmployeeStatusColor = () => {
  const theme = useTheme()

  const EmployeeStatusColor: Record<EmployeeStatusCustomized, string> = {
    [EmployeeStatusCustomized.Unknown]: theme.palette.grey['400'],
    [EmployeeStatusCustomized.Available]: theme.palette.success.main,
    [EmployeeStatusCustomized.Busy]: theme.palette.error.main,
    [EmployeeStatusCustomized.Absent]: theme.palette.error.main,
    [EmployeeStatusCustomized.Sick]: theme.palette.error.main,
    [EmployeeStatusCustomized.Fired]: theme.palette.error.main,
  }

  return EmployeeStatusColor
}
