import React, { forwardRef, ReactNode } from 'react'
import { Box, SxProps, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

export interface HeadingProps {
  title?: ReactNode | string
  count?: ReactNode | string
  actions?: ReactNode
  sx?: SxProps
}

export const Heading = forwardRef(({ title, count, actions, sx }: HeadingProps, ref) => {
  return (
    <Box
      ref={ref}
      component={'header'}
      sx={{
        display: 'flex',
        gap: '16px',
        ...(sx ?? {}),
      }}
    >
      {!!title && (
        <Typography
          variant={'h6'}
          component={'h2'}
          fontWeight={500}
        >
          <span>
            {title}
          </span>
          {(!!count || count === 0) && (
            <Box
              component={'span'}
              sx={{ fontWeight: '400', color: grey[500] }}
            >
              {' '}({count})
            </Box>
          )}
        </Typography>
      )}
      {!!actions && (
        <Box>
          {actions}
        </Box>
      )}
    </Box>
  )
})
