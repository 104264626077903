import React, { ReactNode } from 'react'
import { Box, SxProps } from '@mui/material'

interface DrawerContentProps {
  sx?: SxProps
  children: ReactNode
}

export const DrawerContent = ({ sx = {}, children }: DrawerContentProps) => {
  return (
    <Box
      sx={{
        padding: '20px',
        overflowY: 'auto',
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}
