import { useQuery } from 'react-query'
import { queryApi } from 'api/queryRequests'
import { useOrganizationID } from 'hooks/useOrganizationID'

export const useQueryTaskResults = (taskID: number, departmentID: number, enabled = true) => {
  const organizationID = useOrganizationID()
  const query = useQuery(
    ['task-results', organizationID, taskID],
    () => queryApi.getTaskResults(organizationID, departmentID, taskID),
    { enabled: taskID !== -1 && enabled }
  )
  const isLoading = query.isFetching && !query.isRefetching

  return { ...query, isLoading }
}
