import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDebounce } from '@react-hook/debounce'
import { queryApi } from 'api/queryRequests'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { WAIT_DEBOUNCE_DEFAULT } from 'consts'

export const useEquipmentAutocompleteQuery = () => {
  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useDebounce(() => search, WAIT_DEBOUNCE_DEFAULT)
  const organizationID = useOrganizationID()
  const query = useQuery(
    ['equipment', organizationID, debouncedSearch],
    () => queryApi.getEquipment(organizationID, debouncedSearch),
    { enabled: !!organizationID }
  )
  const isLoading = query.isFetching && !query.isRefetching

  useEffect(() => {
    setDebouncedSearch(search)
  }, [search, setDebouncedSearch])

  return { ...query, setSearch, isLoading }
}
