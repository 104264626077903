import React, { forwardRef, Ref } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, TextField, TextFieldProps } from '@mui/material'
import { DatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { languages } from 'i18n'
import { FieldLabel } from 'components/Field/components/FieldLabel'
import { FieldMessage } from 'components/Field/components/FieldMessage'
import { getSxTextFieldDefault } from 'components/Field/data'
import { FieldCommonProps } from 'components/Field/types'

export interface FieldDatepickerProps extends
  FieldCommonProps,
  Pick<DatePickerProps<Date, Date>, 'value' | 'disableFuture' | 'maxDate' | 'onChange'>
{
  onBlur?: TextFieldProps['onBlur']
}

export const FieldDatepicker = forwardRef(({
  name,
  value,
  label,
  disableFuture,
  maxDate,
  required = false,
  error = false,
  onChange,
  onBlur,
}: FieldDatepickerProps, ref: Ref<HTMLInputElement>) => {
  const { i18n, t } = useTranslation()

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={languages.find(({ id }) => id === i18n.language)?.dateFnsLocale}
    >
      <DatePicker
        value={value}
        disableFuture={disableFuture}
        maxDate={maxDate}
        renderInput={(params) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {label && (
              <FieldLabel
                htmlFor={name}
                label={label}
                required={required}
              />
            )}
            <TextField
              inputRef={ref}
              type={'date'}
              id={name}
              size={'small'}
              error={error}
              sx={getSxTextFieldDefault()}
              onBlur={onBlur}
              {...params}
            />
            <FieldMessage
              show={error}
              message={t('validation.commonDate')}
            />
          </Box>
        )}
        onChange={onChange}
      />
    </LocalizationProvider>
  )
})
