import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { theme } from 'index'
import { Task } from 'api/humanagro.generated'
import { Switch } from 'components/Switch'
import { TableRowEmpty } from 'components/TableRowEmpty'
import { TableRowSkeleton } from 'components/TableRowSkeleton'
import {
  TasksTablePagination,
  TasksTablePaginationProps,
} from 'features/tasks/components/TasksTable/components/TasksTablePagination'
import { TasksTableRow } from 'features/tasks/components/TasksTableRow'
import { TASKS_TABLE_COLUMNS } from 'features/tasks/constants'
import {
  LIMIT_QUERY_DEFAULT,
  LIMIT_QUERY_SMALL,
  TABLE_CELL_DENSE_PADDING,
  TABLE_CONTEXT_BUTTON_CELL_WIDTH,
  TABLE_ID_CELL_WIDTH,
} from 'consts'

interface TasksTableProps extends Omit<TasksTablePaginationProps, 'sx' | 'loading'> {
  tasks: Task[] | undefined
  isLoading: boolean
  isEmpty: boolean
  renderFilters?: ReactElement
}

export const TasksTable = ({ tasks, count, page, isLoading, isEmpty, limit, renderFilters, onSetPage }: TasksTableProps) => {
  const { t } = useTranslation()

  return (
    <Paper variant={'outlined'}>
      {(count || renderFilters) && (
        <Box
          sx={{
            position: 'relative',
            minHeight: renderFilters ? '64px' : undefined,
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          <TasksTablePagination
            page={page}
            limit={limit}
            count={count}
            loading={isLoading}
            sx={{
              ...(renderFilters ? { paddingY: '6px' } : {}),
            }}
            onSetPage={onSetPage}
          />
          {!!renderFilters && (
            <Box
              sx={{
                position: 'absolute',
                left: '0',
                top: '55%',
                padding: '4px 12px',
                transform: 'translateY(-50%)',
              }}
            >
              {renderFilters}
            </Box>
          )}
        </Box>
      )}
      <Table
        size={'small'}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: TABLE_ID_CELL_WIDTH, paddingLeft: TABLE_CELL_DENSE_PADDING }}>
              ID
            </TableCell>
            <TableCell>
              {t('entity.task.fields.title')}
            </TableCell>
            <TableCell>
              {t('entity.task.fields.type')}
            </TableCell>
            <TableCell>
              {t('entity.task.fields.time')}
            </TableCell>
            <TableCell>
              {t('entity.task.fields.status')}
            </TableCell>
            <TableCell>
              {t('entity.task.fields.priority')}
            </TableCell>
            <TableCell>
              {t('entity.task.fields.executors')}
            </TableCell>
            <TableCell
              align="right"
              sx={{ width: TABLE_CONTEXT_BUTTON_CELL_WIDTH, paddingRight: TABLE_CELL_DENSE_PADDING }}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          <Switch
            data={tasks}
            renderLoading={() => (
              <TableRowSkeleton
                columns={TASKS_TABLE_COLUMNS}
                amount={limit === 'small' ? LIMIT_QUERY_SMALL : LIMIT_QUERY_DEFAULT}
              />
            )}
            renderEmpty={() => <TableRowEmpty columns={TASKS_TABLE_COLUMNS} />}
            renderData={(tasks) => tasks.map((task) => (
              <TasksTableRow
                key={task.name + task.id}
                task={task}
              />
            ))}
            isLoading={isLoading}
            isEmpty={(tasks?.length ?? 0) === 0 || isEmpty}
          />
        </TableBody>
      </Table>
      <TasksTablePagination
        page={page}
        limit={limit}
        count={count}
        loading={isLoading}
        sx={{ borderTop: `1px solid ${theme.palette.divider}` }}
        onSetPage={onSetPage}
      />
    </Paper>
  )
}
