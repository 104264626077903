import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDebounce } from '@react-hook/debounce'
import { queryApi } from 'api/queryRequests'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { LIMIT_QUERY_UNLIMITED, WAIT_DEBOUNCE_DEFAULT } from 'consts'

export const useQueryTaskSubtypes = (typeID?: number) => {
  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useDebounce(search, WAIT_DEBOUNCE_DEFAULT)
  const organizationID = useOrganizationID()
  const query = useQuery(
    ['task', 'subtypes', organizationID, typeID, debouncedSearch],
    () => queryApi.getTaskSubtypes(organizationID, typeID!, {
      limit: LIMIT_QUERY_UNLIMITED,
      name: debouncedSearch,
    }),
    { enabled: !!organizationID && !!typeID }
  )
  const isLoading = query.isFetching && !query.isRefetching

  useEffect(() => {
    setDebouncedSearch(search)
  }, [search, setDebouncedSearch])

  return { ...query, setSearch, isLoading }
}
