import React, { ReactNode } from 'react'
import { Box, SxProps, Typography } from '@mui/material'
import { theme } from 'index'

interface DrawerFieldProps {
  title: string
  content?: ReactNode | false
  variant?: 'row' | 'column'
  size?: 'sm' | 'md'
  sx?: SxProps
}

export const DrawerField = ({ title, content, variant = 'row', size = 'md', sx }: DrawerFieldProps) => {
  const column = variant === 'column' && !!content

  return (
    <Box
      sx={{
        ...(column ? {} : {
          display: 'grid',
          gridTemplateColumns: `${size === 'md' ? '250px' : '150px'} 1fr`,
          gap: '8px',
        }),
        ...(sx ?? {}),
      }}
    >
      <Typography
        variant={'body2'}
        component={'p'}
        fontWeight={400}
        color={theme.palette.grey['700']}
        mb={column ? '12px' : undefined}
      >
        {title}:
      </Typography>
      {column ? content : (
        <Typography
          variant={'body2'}
          component={'div'}
        >
          {content ? content : '-'}
        </Typography>
      )}
    </Box>
  )
}
