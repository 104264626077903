import { useQuery } from 'react-query'
import { queryApi } from 'api/queryRequests'
import { useOrganizationID } from 'hooks/useOrganizationID'

export const useQueryReport = (reportID: number, enabled = true) => {
  const organizationID = useOrganizationID()
  const query = useQuery(
    ['report', organizationID, reportID],
    () => queryApi.getReport(organizationID, reportID),
    {
      enabled: !!organizationID && reportID !== -1 && enabled,
      refetchInterval: data => {
        return data?.file ? false : 1000
      },
    }
  )
  const isLoading = query.isFetching && !query.isRefetching

  return { ...query, isLoading }
}
