import { useTranslation } from 'react-i18next'
import { camelCase } from 'case-anything'
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'
import { DictionaryType } from 'features/dictionaries/types'

export const useDictionariesBreadcrumbs = () => {
  const { t } = useTranslation()

  return Object.values(DictionaryType).reduce<BreadcrumbsRoute[]>((acc, type) => (
    [
      ...acc,
      { path: `/:organizationID/dictionaries/${type}`, breadcrumb: t(`menu.${camelCase(type)}`) },
      { path: `/:organizationID/dictionaries/${type}/create`, breadcrumb: t(`pages.${camelCase(type)}Create`) },
      { path: `/:organizationID/dictionaries/${type}/:entityID`, breadcrumb: null },
      { path: `/:organizationID/dictionaries/${type}/:entityID/edit`, breadcrumb: t(`pages.${camelCase(type)}Edit`) },
    ]
  ), [])
}
