import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Delete, Edit } from '@mui/icons-material'
import { ListItemIcon, ListItemText, MenuItem, TableCell, TableRow } from '@mui/material'
import { format } from 'date-fns'
import { Department } from 'api/humanagro.generated'
import { ButtonContextActions } from 'components/ButtonContextActions'
import ContextMenu from 'components/ContextMenu'
import { useHandleError } from 'hooks/useHandleError'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { api } from 'api'
import { TABLE_CELL_DENSE_PADDING, FORMAT_DATE_DEFAULT } from 'consts'
import { tableRowDefaultSx } from 'data'

interface DepartmentRowProps {
  department: Department
  onAfterDelete: () => void
}

export const DepartmentRow = ({ department, onAfterDelete }: DepartmentRowProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const handleError = useHandleError()
  const organizationID = useOrganizationID()
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const isContextMenuOpen = !!anchorEl

  const handleMenuClick = (e: React.MouseEvent) => {
    setAnchorEl(e.currentTarget)
  }

  const handleDelete = async () => {
    try {
      await api.organizationOrgDepDestroy(department.id, organizationID.toString())
      onAfterDelete()
    } catch (error) {
      handleError(error)
    }
  }

  const handleEdit = async () => {
    navigate(`/${organizationID}/departments/${department.id}/edit`)
  }

  return (
    <>
      <TableRow
        sx={tableRowDefaultSx}
        hover
      >
        <TableCell
          component="th"
          scope="row"
          sx={{ paddingLeft: TABLE_CELL_DENSE_PADDING }}
        >
          {department.id}
        </TableCell>
        <TableCell>
          {department.name}
        </TableCell>
        <TableCell>
          {format(new Date(department.created_at), FORMAT_DATE_DEFAULT)}
        </TableCell>
        <TableCell
          align="right"
          sx={{ paddingRight: TABLE_CELL_DENSE_PADDING }}
        >
          <ButtonContextActions onClick={handleMenuClick} />
        </TableCell>
      </TableRow>
      <ContextMenu
        id={'department-actions'}
        open={isContextMenuOpen}
        anchorEl={anchorEl}
        onClick={() => setAnchorEl(null)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={handleEdit}>
          <ListItemIcon sx={{ marginRight: '-8px' }}>
            <Edit fontSize={'small'} />
          </ListItemIcon>
          <ListItemText>
            {t('actions.change')}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon sx={{ marginRight: '-8px' }}>
            <Delete fontSize={'small'} />
          </ListItemIcon>
          <ListItemText>
            {t('actions.delete')}
          </ListItemText>
        </MenuItem>
      </ContextMenu>
    </>
  )
}
