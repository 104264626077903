import React from 'react'
import { useSelector } from 'react-redux'
import {
  AppBar, Avatar, Box,
  Toolbar,
  Typography,
} from '@mui/material'
import { theme } from 'index'
import HeaderCreationMenu from 'components/Header/components/HeaderCreationMenu'
import HeaderProfileMenu from 'components/Header/components/HeaderProfileMenu'
import { RootState } from 'store'

const Header = () => {
  const organization = useSelector((state: RootState) => state.organization)

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        borderBottom: `1px solid ${theme.palette.grey['400']}`,
      }}
    >
      <Toolbar
        variant="dense"
        sx={{
          justifyContent: 'space-between',
          paddingX: '16px !important',
          gap: '12px',
        }}
      >
        <Typography
          variant="h6"
          component="h1"
          sx={{
            display: 'flex',
            alignItems: 'center',
            paddingTop: '2px',
            marginRight: 'auto', fontSize: '.8rem', letterSpacing: '0.2px',
            textTransform: 'uppercase',
          }}
        >
          <Avatar
            src={'/logo-stripped.svg'}
            alt={'Humanagro'}
            sx={{
              margin: '-2px 8px 0 0',
              width: '26px',
              height: '26px',
              background: theme.palette.common.white,
            }}
          />
          <Box
            component={'span'}
            sx={{ marginRight: '1px', fontWeight: 400 }}
          >
            Human
          </Box>
          <Box
            component={'span'}
            sx={{ fontWeight: 600 }}
          >
            Agro
          </Box>
        </Typography>
        {!!organization && <HeaderCreationMenu />}
        <HeaderProfileMenu />
      </Toolbar>
    </AppBar>
  )
}

export default Header
