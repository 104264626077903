import { Box, SxProps, Typography } from '@mui/material'
import { theme } from 'index'
import { useEmployeeRatingColor } from 'features/employees/hooks/useEmployeeRatingColor'

interface EmployeeRatingIconProps {
  rating: number | null
  sx?: SxProps
  labelSx?: SxProps
}

export const EmployeeRatingIcon = ({ rating, sx, labelSx }: EmployeeRatingIconProps) => {
  const color = useEmployeeRatingColor(rating)

  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '28px',
        height: '28px',
        background: color,
        borderRadius: '4px',
        color: theme.palette.common.white,
        ...(sx ?? {}),
      }}
    >
      <Typography
        variant={'subtitle2'}
        letterSpacing={'-0.75px'}
        sx={labelSx}
      >
        {rating ? rating.toPrecision(2).slice(0, 3) : '-'}
      </Typography>
    </Box>
  )
}
