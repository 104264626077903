import { TaskFormData } from 'features/tasks/components/TasksForm/types'
import { TimeOptions } from 'data'

export const taskCreateDefaultValues: Partial<TaskFormData> = {
  title: '',
  description: '',
  startDate: new Date(),
  startTime: { name: TimeOptions[24].name },
  endDate: new Date(),
  endTime: { name: TimeOptions[24].name },
  executors: [],
}
