import { RegisterOptions } from 'react-hook-form'
import { SxProps } from '@mui/material'
import { NumberParam, StringParam } from 'serialize-query-params'
import { FieldAutocompleteCommonValue } from 'components/Field/components/FieldAutocomplete/types'
import { DASHBOARD_TAB_QUERY_KEY } from 'features/dashboard/constants'
import { USER_POSITION_DRAWER_QUERY_KEY } from 'features/employees/components/EmployeeDrawer/constants'
import { TASK_DEPARTMENT_DRAWER_QUERY_KEY, TASK_DRAWER_QUERY_KEY } from 'features/tasks/components/TasksDrawer/constants'

export const TimeOptions: FieldAutocompleteCommonValue[] = Array.from(Array(24).keys()).reduce<FieldAutocompleteCommonValue[]>((acc, value) => {
  return [
    ...acc,
    ...[
      { name: value.toString().padStart(2, '0') + ':00' },
      { name: value.toString().padStart(2, '0') + ':30' },
    ],
  ]
}, [])

export const tableRowDefaultSx: SxProps = { '&:last-child td, &:last-child th': { border: '0 !important' } }

export const titleValidationOptions: RegisterOptions = {
  required: true,
  maxLength: 120,
  pattern: /^[-a-zA-Zа-яА-я0-9() ]+$/i,
}

export const emailValidationOptions = {
  required: true,
  maxLength: 200,
  pattern:  /\S+@\S+\.\S+/,
}

export const phoneValidationOptions = {
  maxLength: 20,
  pattern: /^[\s-+()0-9]+$/i,
}

export const LIST_FILTER_KEY = 'filterKey'

export const queryParamsConfigMap = {
  [TASK_DEPARTMENT_DRAWER_QUERY_KEY]: NumberParam,
  [TASK_DRAWER_QUERY_KEY]: NumberParam,
  [USER_POSITION_DRAWER_QUERY_KEY]: NumberParam,
  [DASHBOARD_TAB_QUERY_KEY]: StringParam,
  [LIST_FILTER_KEY]: StringParam,
}
