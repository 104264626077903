import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { Work } from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Avatar,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { Organization } from 'api/humanagro.generated'
import { getOrganizations } from 'api/queryRequests'
import { Section } from 'components/Section'

const HomePage = () => {
  const dispatch = useDispatch()
  const organizationsQuery = useQuery<Organization[], Error>('companies', getOrganizations)
  const isWarning = !organizationsQuery.isFetching && organizationsQuery.data?.length === 0

  useEffect(() => {
    dispatch({
      type: 'SET_ORGANIZATION',
      payload: null,
    })
  }, [dispatch])

  return (
    <>
      {organizationsQuery.isSuccess && (
        <>
          {isWarning && (
            <Alert
              severity="warning"
              variant={'filled'}
              sx={{
                maxWidth: '480px',
                margin: '0 auto',
              }}
            >
              <AlertTitle>Внимание</AlertTitle>
              Вы не привязаны ни к одной организации. Пожалуйста, обратитесь к администратору системы.
            </Alert>
          )}
          {organizationsQuery.data.length > 0 && (
            <Section
              title={'Ваши организации'}
              sx={{
                overflow: 'hidden',
                maxWidth: '480px',
                margin: '0 auto',
              }}
              componentProps={{
                content: { sx: { padding: 0 } },
                header: { sx: { paddingBottom: '8px', justifyContent: 'center' } },
              }}
            >
              <List sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: 1 }}>
                {organizationsQuery.data.map((organization, index) => (
                  <ListItemButton
                    key={`${organization.id}-${index}`}
                    component={RouterLink}
                    to={`/${organization.id}`}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Work />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={organization.full_name}
                      secondary={`ID: ${organization.id}`}
                    />
                  </ListItemButton>
                ))}
              </List>
            </Section>
          )}
        </>
      )}
    </>
  )
}

export default HomePage
