import { useMutation } from 'react-query'
import { PatchedUserPositionUpdate } from 'api/humanagro.generated'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { queryClient } from 'services/query'
import { api } from 'api'
import { Writable } from 'types'

export const useMutationUserUpdate = () => {
  const organizationID = useOrganizationID()

  return useMutation({
    mutationFn: async ({
      departmentID,
      employeeID,
      body,
    }: {
      departmentID: number,
      employeeID: number
      body: Writable<PatchedUserPositionUpdate>
    }) => {
      await api.organizationOrgDepUserPositionsPartialUpdate(departmentID.toString(), employeeID, organizationID.toString(), body)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['executors', organizationID])
    },
  })
}
