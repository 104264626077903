import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDebounce } from '@react-hook/debounce'
import { queryApi } from 'api/queryRequests'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { LIMIT_QUERY_UNLIMITED, WAIT_DEBOUNCE_DEFAULT } from 'consts'

export const useQueryEmployees = (body?: Parameters<typeof queryApi.getUserPositions>[1]) => {
  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useDebounce(search, WAIT_DEBOUNCE_DEFAULT)
  const organizationID = useOrganizationID()
  const query = useQuery(
    ['executors', organizationID, body ?? {}, debouncedSearch],
    () => queryApi.getUserPositions(organizationID, {
      name: debouncedSearch,
      limit: LIMIT_QUERY_UNLIMITED,
      ...body,
    }),
    { enabled: !!organizationID }
  )
  const isLoading = query.isFetching && !query.isRefetching

  useEffect(() => {
    setDebouncedSearch(search)
  }, [search, setDebouncedSearch])

  return { ...query, data: query.data?.results, search, setSearch, isLoading }
}
