import { useRef } from 'react'
import { ChartProps, Doughnut } from 'react-chartjs-2'
import { Card, CardContent, Typography } from '@mui/material'
import { ActiveElement, ArcElement, Chart, ChartEvent, Legend, Tooltip } from 'chart.js'
import { omit } from 'ramda'

interface ChartDoughnutProps extends Pick<ChartProps<'doughnut', number[], string>, 'data' | 'title' | 'options'> {
  onArcClick: (dataIndex: number) => void
}

Chart.register(ArcElement, Tooltip, Legend)

export const ChartDoughnut = (props: ChartDoughnutProps) => {
  const doughnutRef = useRef<Chart<'doughnut', number[], string>>()

  return (
    <Card>
      <CardContent>
        {!!props.title && (
          <Typography
            variant={'subtitle2'}
            sx={{
              textAlign: 'center',
              marginBottom: '12px',
            }}
          >
            {props.title}
          </Typography>
        )}
        <Doughnut
          {...(omit(['onArcClick'], props) as ChartDoughnutProps)}
          ref={doughnutRef}
          options={{
            plugins: {
              title: {
                display: true,
                text: props.title,
              },
              legend: {
                position: 'bottom',
                labels: {
                  boxWidth: 40,
                  font: {
                    size: 16,
                  },
                },
              },
            },
            onClick(event: ChartEvent, elements: ActiveElement[]) {
              // @ts-expect-error
              const elem = elements.find((element) => typeof element.element?.$context?.dataIndex === 'number')

              if (elem) {
                // @ts-expect-error
                props.onArcClick(elem.element?.$context?.dataIndex)
              }
            },
            onHover: (event, chartElement) => {
              // @ts-expect-error
              if ( event.native?.target?.style) {
                // @ts-expect-error
                event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
              }
            },
            ...(props.options ?? {}),
          }}
        />
      </CardContent>
    </Card>
  )
}
