import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { format, subMonths } from 'date-fns'
import { useQueryExecutorsAutocomplete } from 'api/query/useQueryExecutorsAutocomplete'
import { useTaskTypeAutocompleteQuery } from 'api/query/useTaskTypeAutocompleteQuery'
import {
  FieldAutocompleteControlled,
  FieldAutocompleteMultipleControlled,
  FieldDatepickerControlled,
} from 'components/Field'
import { Form } from 'components/Form'
import { FormActions } from 'components/FormActions'
import { useDepartmentsAutocompleteQuery } from 'features/departments/hooks/useDepartmentsAutocompleteQuery'
import { ReportCreateFormValues } from 'features/reports/types'
import { getDateString } from 'features/tasks/components/TasksForm/helpers'
import { TASK_EXECUTORS_MAX } from 'features/tasks/constants'
import { useGoBack } from 'hooks/useGoBack'
import { useHandleError } from 'hooks/useHandleError'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { useToast } from 'hooks/useToast'
import { api } from 'api'
import { FORMAT_DATE_TIME } from 'consts'

const date = new Date()

export const ReportsPage = () => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useToast()
  const handleError = useHandleError()
  const organizationID = useOrganizationID()
  const {
    formState: { isSubmitting, isSubmitSuccessful },
    control, getValues, setValue, handleSubmit,
  } = useForm<ReportCreateFormValues>({ defaultValues: {
    from: subMonths(date, 1),
    to: date,
    executors: [],
    types: [],
  } })
  const departmentsQuery = useDepartmentsAutocompleteQuery()
  const executorsQuery = useQueryExecutorsAutocomplete(getValues('department')?.id)
  const typesQuery = useTaskTypeAutocompleteQuery()
  const goBack = useGoBack({ getValues })

  const handleCreate = async ({ from, to, department, executors, types }: ReportCreateFormValues) => {
    try {
      if (isSubmitting) {
        return
      }

      const { data: report } = await api.exportOrgReportsCreate(organizationID, {
        name: `${t('entity.report.messages.from')} ${format(date, FORMAT_DATE_TIME)}`,
        report_filter: {
          from: getDateString(from),
          to: getDateString(to),
          department: department?.id ?? undefined,
          executors: executors.length ? executors.map(({ id }) => id) : [],
          task_types: types.length ? types.map(({ id }) => id) : [],
        },
      })

      enqueueSnackbar(t('entity.report.messages.success'), { variant: 'success' })
      window.open(`${window.location.origin}/${organizationID}/reports/${report.id}`)
    } catch (error) {
      handleError(error)
      throw error
    }
  }

  return (
    <Form
      title={t('pages.reports')}
      content={(
        <>
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
            }}
          >
            <FieldDatepickerControlled
              name={'from'}
              control={control}
              rules={{ required: true }}
              label={t('entity.report.fields.from')}
              disableFuture
            />
            <FieldDatepickerControlled
              name={'to'}
              control={control}
              rules={{ required: true }}
              label={t('entity.report.fields.to')}
              disableFuture
            />
          </Box>
          <FieldAutocompleteControlled
            name={'department'}
            control={control}
            rules={{ required: false }}
            label={t('entity.task.fields.department')}
            options={departmentsQuery.data ?? []}
            isLoading={departmentsQuery.isLoading}
            onInputChange={departmentsQuery.setSearch}
            onAfterChange={() => { setValue('executors', [] ) }}
          />
          <FieldAutocompleteMultipleControlled
            name={'executors'}
            control={control}
            rules={{ required: false }}
            placeholder={!getValues('department') ?t('entity.task.actions.chooseDepartment') : undefined}
            label={t('entity.task.fields.executors')}
            disabled={!getValues('department')}
            max={TASK_EXECUTORS_MAX}
            options={executorsQuery.data}
            isLoading={executorsQuery.isLoading}
            onInputChange={executorsQuery.setSearch}
          />
          <FieldAutocompleteMultipleControlled
            name={'types'}
            control={control}
            rules={{ required: false }}
            label={t('entity.task.fields.type')}
            options={typesQuery.data ?? []}
            isLoading={typesQuery.isLoading}
            onInputChange={typesQuery.setSearch}
          />
        </>
      )}
      actions={(
        <FormActions
          submitText={t('actions.form')}
          isSubmitting={isSubmitting}
          isSubmitSuccessful={isSubmitSuccessful}
          onGoBack={goBack}
        />
      )}
      onSubmit={handleSubmit(handleCreate)}
    />
  )
}
