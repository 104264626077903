import { AddTask, CancelScheduleSend, EventBusy, Grade, Group, TaskAlt } from '@mui/icons-material'
import { Dashboard, OrganizationDashboard } from 'api/humanagro.generated'
import { ANALYTICS_RATING_RESULTS_LIMIT } from 'features/dashboard/constants'

export const getDashboardStatsData = (data?: {
  employeesTotal?: number,
  tasksTotal?: number,
  tasksDone?: string,
  tasksCancelled?: string,
  tasksOverdue?: string,
  employeesAverageRating?: string,
}) => ([
  {
    title: 'Всего сотрудников',
    value: data?.employeesTotal ?? 0,
    Icon: Group,
  },
  {
    title: 'Всего задач',
    value: data?.tasksTotal ?? 0,
    Icon: TaskAlt,
  },
  {
    title: 'Выполненные задачи',
    value: data?.tasksDone ?? '-',
    Icon: AddTask,
  },
  {
    title: 'Отклоненные задачи',
    value: data?.tasksCancelled ?? '-',
    Icon: CancelScheduleSend,
  },
  {
    title: 'Просроченные задачи',
    value: data?.tasksOverdue ?? '-',
    Icon: EventBusy,
  },
  {
    title: 'Средняя оценка сотрудников',
    value: data?.employeesAverageRating ?? '-',
    Icon: Grade,
  },
])

export const getCountedPercentageOfTotal = (total = 0, countable = 0): string => {
  const result = Math.floor((countable / total) * 100)
  return isNaN(result) ? '-' : result + '%'
}

export const getCountedStoppedTasks = (stopped = 0, cancelled = 0, error = 0): number => {
  return stopped + cancelled + error
}

export const getCardsData = (data?: Dashboard | OrganizationDashboard | null) => getDashboardStatsData(!data ? undefined : {
  employeesTotal: data.count_user_total,
  tasksTotal: data.count_task_total,
  tasksDone: getCountedPercentageOfTotal(data.count_task_total, data.count_task_done),
  tasksCancelled: getCountedPercentageOfTotal(data.count_task_total, getCountedStoppedTasks(data?.count_task_stop, data?.count_task_cancelled, data?.count_task_error)),
  tasksOverdue: getCountedPercentageOfTotal(data.count_task_total, data.count_task_expired),
  employeesAverageRating: data.avg_rating?.toFixed(2),
})

export const getEmployeesChartData = (data?: Dashboard | OrganizationDashboard | null) => ({
  busy: data?.count_user_busy ?? 0,
  available: data?.count_user_free ?? 0,
  absent: data?.count_user_absent ?? 0,
})

export const getTasksChartData = (data?: Dashboard | OrganizationDashboard | null) => ({
  started: data?.count_today_task_in_progress ?? 0,
  done: data?.count_today_task_done ?? 0,
  cancelled: getCountedStoppedTasks(data?.count_today_task_stop, data?.count_today_task_cancelled, data?.count_today_task_error),
})

export const getDashboardRatingPaginationCount = (count: number): number => {
  return Math.trunc(count / ANALYTICS_RATING_RESULTS_LIMIT) + (count % ANALYTICS_RATING_RESULTS_LIMIT > 0 ? 1 : 0)
}
