import React, { useState } from 'react'
import { Delete, Edit } from '@mui/icons-material'
import { Box, Checkbox, FormControlLabel, ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { t } from 'i18next'
import { theme } from 'index'
import { TaskStatus } from 'api/humanagro.generated'
import { useMutationSubtaskUpdate } from 'api/query/useMutationSubtaskUpdate'
import { ButtonContextActions } from 'components/ButtonContextActions'
import ContextMenu from 'components/ContextMenu'
import { SubtaskDialogEdit } from 'features/tasks/components/TasksSectionSubtasks/Subtask/SubtaskDialogEdit'
import { useHandleError } from 'hooks/useHandleError'

export interface SubtaskProps {
  id: number
  name: string
  status: TaskStatus
  actionable: boolean
  bottomBorder: boolean
  onDelete: (id: number) => void
  onEdit: (id: number, name: string) => Promise<void>
  onStatusChange?: (id: number, status: TaskStatus) => Promise<void>
}

export const Subtask = ({ id, status, name, actionable, bottomBorder, onDelete, onEdit, onStatusChange }: SubtaskProps) => {
  const handleError = useHandleError()
  const subtaskMutationUpdate = useMutationSubtaskUpdate()
  const [editingPopupOpen, setEditingPopupOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const isContextMenuOpen = !!anchorEl

  const handleChangeStatus = async () => {
    if (subtaskMutationUpdate.isLoading || !onStatusChange) {
      return
    }

    await onStatusChange(id, status === TaskStatus.Value300 ? TaskStatus.Value100 : TaskStatus.Value300)
  }

  const handleMenuClick = (e: React.MouseEvent) => {
    setAnchorEl(e.currentTarget)
  }

  const handleOpenEditPopup = () => {
    setEditingPopupOpen(true)
  }

  const handleEdit = async (name: string) => {
    try {
      await onEdit(id, name)
    } catch (error) {
      handleError(error)
    }
  }

  return (
    <>
      <Box
        sx={{
          borderBottom: bottomBorder ? undefined : `1px solid ${theme.palette.grey['200']}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: '4px',
          marginBottom: bottomBorder ? '8px' : undefined,
        }}
      >
        <FormControlLabel
          control={<Checkbox />}
          label={name}
          style={{ width: '100%' }}
          checked={status ? status === TaskStatus.Value300 : false}
          onChange={handleChangeStatus}
        />
        <ButtonContextActions onClick={handleMenuClick}/>
      </Box>
      {actionable && (
        <ContextMenu
          id={'subtasks-actions'}
          open={isContextMenuOpen}
          anchorEl={anchorEl}
          onClick={() => setAnchorEl(null)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem
            onClick={handleOpenEditPopup}
          >
            <ListItemIcon sx={{ marginRight: '-8px' }}>
              <Edit fontSize={'small'}/>
            </ListItemIcon>
            <ListItemText>
              {t('actions.change')}
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => onDelete(id)}
          >
            <ListItemIcon sx={{ marginRight: '-8px' }}>
              <Delete fontSize={'small'}/>
            </ListItemIcon>
            <ListItemText>
              {t('actions.delete')}
            </ListItemText>
          </MenuItem>
        </ContextMenu>
      )}
      <SubtaskDialogEdit
        open={editingPopupOpen}
        name={name}
        onEdit={handleEdit}
        onClose={() => setEditingPopupOpen(false)}
      />
    </>
  )
}
