import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Paper, Table, TableBody } from '@mui/material'
import { camelCase } from 'case-anything'
import { ButtonAdd } from 'components/ButtonAdd'
import { Heading } from 'components/Heading'
import { TableRowEmpty } from 'components/TableRowEmpty'
import { TableRowSkeleton } from 'components/TableRowSkeleton'
import { DICTIONARIES_TABLE_COLUMNS } from 'features/dictionaries/components/DictionariesListPage/constants'
import { getDeleteRequest, getQueryRequest } from 'features/dictionaries/components/DictionariesListPage/helpers'
import { DictionariesTableHead } from 'features/dictionaries/components/DictionariesTableHead'
import { DictionariesTableRow } from 'features/dictionaries/components/DictionariesTableRow'
import { DictionaryType } from 'features/dictionaries/types'
import { useHandleError } from 'hooks/useHandleError'
import { useOrganizationID } from 'hooks/useOrganizationID'

interface DictionariesListPageProps {
  type: DictionaryType
}

export const DictionariesListPage = ({ type }: DictionariesListPageProps) => {
  const { t } = useTranslation()
  const organizationID = useOrganizationID()
  const navigate = useNavigate()
  const handleError = useHandleError()
  const query = useQuery(
    [type, organizationID],
    () => getQueryRequest(type)(organizationID),
    { enabled: !!organizationID }
  )
  const isLoading = query.isFetching && !query.isRefetching

  const handleEdit = (id: number) => {
    navigate(`/${organizationID}/dictionaries/${type}/${id}/edit`)
  }

  const handleDelete = async (id: number) => {
    try {
      await getDeleteRequest(type)(id, organizationID.toString())
      await query.refetch()
    } catch (error) {
      handleError(error)
    }
  }

  return (
    <>
      <Heading
        title={t(`menu.${camelCase(type)}`)}
        count={!isLoading && query.isSuccess && query.data.length}
        actions={<ButtonAdd to={`/${organizationID}/dictionaries/${type}/create`} />}
        sx={{ marginBottom: 2 }}
      />
      <Paper variant={'outlined'}>
        <Table
          size={'small'}
          aria-label={t(`menu.${camelCase(type)}`)}
        >
          <DictionariesTableHead />
          <TableBody>
            {isLoading ? <TableRowSkeleton columns={DICTIONARIES_TABLE_COLUMNS} /> : (
              query.isSuccess && query.data.length > 0 ? (
                query.data.map(({ id, name, created_at }) => (
                  <DictionariesTableRow
                    key={id}
                    id={id}
                    name={name}
                    createdAt={created_at}
                    onDelete={handleDelete}
                    onEdit={handleEdit}
                  />
                ))
              ) : <TableRowEmpty columns={DICTIONARIES_TABLE_COLUMNS} />
            )}
          </TableBody>
        </Table>
      </Paper>
    </>
  )
}
