import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { theme } from 'index'

export interface FieldMessageProps {
  show?: boolean
  variant?: 'warning' | 'error'
  message?: string
}

export const FieldMessage = ({ show = false, variant = 'error', message }: FieldMessageProps) => {
  const { t } = useTranslation()

  return show ? (
    <Typography
      variant={'caption'}
      sx={{
        marginTop: 1,
        color: variant === 'error' ? theme.palette.error.main : theme.palette.warning.main,
      }}
    >
      {message ?? t('validation.common')}
    </Typography>
  ) : null
}
