import { useEffect } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { FieldInputControlled } from 'components/Field/components/FieldInputControlled'
import { Form } from 'components/Form'
import { FormActions } from 'components/FormActions'
import { useGoBack } from 'hooks/useGoBack'
import { useHandleError } from 'hooks/useHandleError'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { useToast } from 'hooks/useToast'
import { api } from 'api'
import { titleValidationOptions } from 'data'

const DepartmentsCreate = () => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useToast()
  const navigate = useNavigate()
  const handleError = useHandleError()
  const organizationID = useOrganizationID()
  const { formState: { isSubmitting, isSubmitted, isSubmitSuccessful }, control, getValues, reset, handleSubmit } = useForm()
  const goBack = useGoBack({ getValues })

  const create = async ({ name }: FieldValues) => {
    if (isSubmitting || (isSubmitted && isSubmitSuccessful)) {
      return
    }

    await api.organizationOrgDepCreate(organizationID.toString(), { name })
    enqueueSnackbar('Подразделение успешно добавлено', { variant: 'success' })
  }

  const handleCreate = async (values: FieldValues) => {
    try {
      await create(values)
      navigate(`/${organizationID}/departments`)
    } catch (error) {
      handleError(error)
      throw error
    }
  }

  const handleSaveAndContinue = async (values: FieldValues) => {
    try {
      await create(values)
    } catch (error) {
      handleError(error)
      throw error
    }
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
  }, [isSubmitSuccessful, reset])

  return (
    <Form
      title={t('pages.departmentCreate')}
      content={(
        <FieldInputControlled
          name={'name'}
          control={control}
          rules={titleValidationOptions}
          label={t('entity.department.fields.title')}
          placeholder={t('entity.department.fields.title')}
        />
      )}
      actions={(
        <FormActions
          isSubmitting={isSubmitting}
          isSubmitSuccessful={isSubmitSuccessful}
          onGoBack={goBack}
          onSubmitAndSave={handleSubmit(handleSaveAndContinue)}
        />
      )}
      onSubmit={handleSubmit(handleCreate)}
    />
  )
}

export default DepartmentsCreate
