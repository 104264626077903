import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { camelCase } from 'case-anything'
import { queryApi } from 'api/queryRequests'
import { ButtonAdd } from 'components/ButtonAdd'
import { Heading } from 'components/Heading'
import { TableRowEmpty } from 'components/TableRowEmpty'
import { TableRowSkeleton } from 'components/TableRowSkeleton'
import { DICTIONARIES_TABLE_COLUMNS } from 'features/dictionaries/components/DictionariesListPage/constants'
import { DictionaryType } from 'features/dictionaries/types'
import { TaskTypeTableRow } from 'features/tasks/components/TaskTypeTableRow'
import { useHandleError } from 'hooks/useHandleError'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { api } from 'api'
import { TABLE_CELL_DENSE_PADDING, TABLE_CONTEXT_BUTTON_CELL_WIDTH, TABLE_ID_CELL_WIDTH } from 'consts'

const type = DictionaryType.TaskTypes

export const DictionariesTaskTypePage = () => {
  const { t } = useTranslation()
  const organizationID = useOrganizationID()
  const navigate = useNavigate()
  const handleError = useHandleError()
  const query = useQuery(
    [DictionaryType.TaskTypes, organizationID],
    () => queryApi.getTaskTypes(organizationID),
    { enabled: !!organizationID }
  )
  const isLoading = query.isFetching && !query.isRefetching

  const handleEdit = (id: number) => {
    navigate(`/${organizationID}/dictionaries/${type}/${id}/edit`)
  }

  const handleDelete = async (id: number) => {
    try {
      await api.taskOrgTaskTypesDestroy(id, organizationID.toString())
      await query.refetch()
    } catch (error) {
      handleError(error)
    }
  }

  return (
    <>
      <Heading
        title={t(`menu.${camelCase(type)}`)}
        count={!isLoading && query.isSuccess && query.data.length}
        actions={<ButtonAdd to={`/${organizationID}/dictionaries/${type}/create`} />}
        sx={{ marginBottom: 2 }}
      />
      <Paper variant={'outlined'}>
        <Table
          size={'small'}
          aria-label={t(`menu.${camelCase(type)}`)}
        >
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell
                sx={{ width: TABLE_ID_CELL_WIDTH, paddingLeft: TABLE_CELL_DENSE_PADDING }}
              >
                ID
              </TableCell>
              <TableCell>
                {t('entity.taskTypes.fields.name')}
              </TableCell>
              <TableCell>
                {t('entity.taskTypes.fields.createdDate')}
              </TableCell>
              <TableCell
                align="right"
                sx={{ width: TABLE_CONTEXT_BUTTON_CELL_WIDTH, paddingRight: TABLE_CELL_DENSE_PADDING }}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? <TableRowSkeleton columns={DICTIONARIES_TABLE_COLUMNS} /> : (
              query.isSuccess && query.data.length > 0 ? (
                query.data.map((type) => (
                  <TaskTypeTableRow
                    key={type.id}
                    type={type}
                    onDelete={handleDelete}
                    onEdit={handleEdit}
                  />
                ))
              ) : <TableRowEmpty columns={DICTIONARIES_TABLE_COLUMNS} />
            )}
          </TableBody>
        </Table>
      </Paper>
    </>
  )
}
