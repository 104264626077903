import React, { useCallback, useState } from 'react'
import { useGeolocated } from 'react-geolocated'
import { useTranslation } from 'react-i18next'
import { Close, Place } from '@mui/icons-material'
import { Box, Button, Dialog } from '@mui/material'
import { LatLng } from 'leaflet'
import { DialogContent } from 'components/DialogContent'
import { DialogHeader } from 'components/DialogHeader'
import { FieldInput } from 'components/Field/components/FieldInput'
import { INPUT_MAX_WIDTH } from 'components/Field/constants'
import { FieldCommonProps } from 'components/Field/types'
import { Map } from 'components/Map'

interface FieldLocationChooseProps extends FieldCommonProps {
  value: LatLng | null
  onChange: (value: LatLng | null) => void
}

export const FieldLocationChoose = ({ name, value, label, required, labelInside, error, errorMessage, onChange }: FieldLocationChooseProps) => {
  const { t } = useTranslation()
  const { coords: geolocation, isGeolocationAvailable } = useGeolocated({
    positionOptions: { enableHighAccuracy: false },
    userDecisionTimeout: 5000,
  })
  const [coords, setCoords] = useState<LatLng>()
  const [open, setOpen] = useState(false)

  const handleOpenDialog = useCallback(() => {
    if (isGeolocationAvailable && !coords && geolocation?.latitude) {
      setCoords(new LatLng(geolocation.latitude, geolocation.longitude))
    }
    setOpen(true)
  }, [geolocation, coords, isGeolocationAvailable])

  const handleChoose = useCallback(() => {
    onChange(coords!)
    setOpen(false)
  }, [coords, onChange])

  return (
    <>
      <FieldInput
        name={name}
        value={value ? `${value.lat}, ${value.lng}` : ''}
        label={label}
        placeholder={t('common.notChosen')}
        error={error}
        required={required}
        labelInside={labelInside}
        errorMessage={errorMessage}
        InputContainerProps={{ sx: { maxWidth: INPUT_MAX_WIDTH } }}
        renderRight={(
          <>
            {value !== null && (
              <Button
                variant={'outlined'}
                color={'error'}
                sx={{ minWidth: '44px', paddingX: '8px' }}
                onClick={() => onChange(null)}
              >
                <Close />
              </Button>
            )}
            <Button
              variant={'outlined'}
              onClick={handleOpenDialog}
            >
              <Place />
              {t('actions.chooseShort')}
            </Button>
          </>
        )}
        readOnly
      />
      <Dialog
        open={open}
        maxWidth={'lg'}
        onClose={() => setOpen(false)}
      >
        <DialogHeader
          title={t('entity.task.actions.chooseLocation')}
          onClose={() => setOpen(false)}
        />
        <DialogContent size={'large'}>
          <Map
            coords={coords}
            height={'450px'}
            addressSearch
            onChange={setCoords}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '16px',
            }}
          >
            <Button
              size={'large'}
              variant={'contained'}
              disabled={!coords}
              onClick={handleChoose}
            >
              {t('actions.chooseShort')}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}
