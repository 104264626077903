import { useEffect, useMemo } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import { getDepartment } from 'api/queryRequests'
import { FieldInputControlled } from 'components/Field/components/FieldInputControlled'
import { Form } from 'components/Form'
import { useGoBack } from 'hooks/useGoBack'
import { useHandleError } from 'hooks/useHandleError'
import { useToast } from 'hooks/useToast'
import { api } from 'api'
import { titleValidationOptions } from 'data'

export const DepartmentsEditPage = () => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useToast()
  const handleError = useHandleError()
  const { organizationID, departmentID } = useParams()
  const { data: department, isFetching, refetch } = useQuery(
    ['department', departmentID],
    () => getDepartment(+organizationID!, +departmentID!)
  )
  const {
    formState: { isSubmitting, isSubmitted, isSubmitSuccessful },
    control,
    getValues,
    handleSubmit,
    reset,
  } = useForm({ defaultValues: useMemo(() => ({ name: department?.name }), [department]) })
  const goBack = useGoBack({ getSaved() { return department?.name === getValues('name') } })

  const handleChange = async ({ name }: FieldValues) => {
    if (isSubmitting || (isSubmitted && isSubmitSuccessful)) {
      return
    }

    try {
      await api.organizationOrgDepPartialUpdate(+departmentID!, organizationID!, { name })
      enqueueSnackbar('Подразделение сохранено', { variant: 'success' })

      await refetch()
    } catch (error) {
      handleError(error)
      throw error
    }
  }

  useEffect(() => {
    reset({ name: department?.name })
  }, [department, reset])

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
  }, [isSubmitSuccessful, reset])

  return (
    <Form
      title={`${t('pages.departmentEdit')}${department ? ` "${department.name}"` : '...'}`}
      content={(
        <FieldInputControlled
          name={'name'}
          control={control}
          rules={titleValidationOptions}
          label={t('entity.department.fields.title')}
          placeholder={t('entity.department.fields.title')}
          disabled={isFetching}
        />
      )}
      actions={(
        <>
          <Button
            type={'button'}
            variant={'outlined'}
            onClick={goBack}
          >
            {t('actions.back')}
          </Button>
          <LoadingButton
            type={'submit'}
            variant={'contained'}
            disabled={isSubmitting && !isSubmitSuccessful}
            loading={isFetching}
            disableRipple
            disableElevation
          >
            {t('actions.save')}
          </LoadingButton>
        </>
      )}
      onSubmit={handleSubmit(handleChange)}
    />
  )
}
