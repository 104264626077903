import { SvgIconComponent } from '@mui/icons-material'
import { Card, CardContent, Typography } from '@mui/material'
import { theme } from 'index'

interface DashboardStatsCardProps {
  title: string
  value: string
  Icon: SvgIconComponent
}

export const DashboardStatsCard = ({ title, value, Icon }: DashboardStatsCardProps) => {
  return (
    <Card
      sx={{
        display: 'flex',
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: 'auto',
          padding: '12px !important',
        }}
      >
        <Typography
          variant={'subtitle2'}
          sx={{
            marginBottom: '16px',
            fontSize: '.925rem',
            color: theme.palette.grey['800'],
          }}
        >
          {title}
        </Typography>
        <Typography
          variant={'h4'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontWeight: '500',
            marginTop: 'auto',
            lineHeight: 1,
          }}
        >
          <Icon
            sx={{
              fontSize: '32px',
              color: theme.palette.primary.dark,
            }}
          />
          {value}
        </Typography>
      </CardContent>
    </Card>
  )
}
