import { Fragment } from 'react'
import { ArrowUpward } from '@mui/icons-material'
import { Box, CircularProgress, Typography } from '@mui/material'
import { theme } from 'index'
import { LatLng } from 'leaflet'
import { TaskStatus } from 'api/humanagro.generated'
import { useQueryTaskEvents } from 'features/tasks/api/useQueryTaskEvents'
import { useQueryTaskResults } from 'features/tasks/api/useQueryTaskResults'
import { TaskEventCard } from 'features/tasks/components/TaskEventCard'

interface TaskDrawerTabEventsProps {
  taskID: number
  departmentID: number
  status: TaskStatus
}

export const TaskDrawerTabEvents = ({ taskID, departmentID, status }: TaskDrawerTabEventsProps) => {
  const { data: eventList, isSuccess, isLoading } = useQueryTaskEvents(taskID, departmentID)
  const { data: taskList } = useQueryTaskResults(taskID, departmentID, status === TaskStatus.Value300)
  const isTaskPossessingEvents = !taskList?.results?.length && !eventList?.results?.length

  return (
    <>
      {isLoading || !isSuccess ? (
        <CircularProgress />
      ) : (
        <Box
          sx={{
            display: 'grid',
            gap: '8px',
          }}
        >
          {isTaskPossessingEvents ? (
            <Typography
              variant={'body2'}
              color={theme.palette.text.secondary}
              sx={{
                textAlign: 'center',
              }}
            >
              Событий по задаче не найдено
            </Typography>
          ) : (
            <>
              {taskList?.results?.map((result, index) => (
                <Fragment
                  key={`${result.id}-${index}`}
                >
                  <TaskEventCard
                    author={result.owner}
                    status={TaskStatus.Value300}
                    date={result.created_at}
                    departmentID={departmentID}
                    comment={undefined}
                  />
                  <ArrowUpward
                    color={'info'}
                    sx={{
                      display: 'block',
                      margin: '0 auto',
                    }}
                  />
                </Fragment>
              ))}
              {eventList?.results?.map((event, index) => (
                <Fragment
                  key={`${event.id}-${index}`}
                >
                  <TaskEventCard
                    author={event.creator}
                    status={event.status}
                    date={event.created_at}
                    comment={event.text}
                    departmentID={departmentID}
                    coords={[event.latitude, event.longitude].every(Boolean) ? new LatLng(event.latitude!, event.longitude!) : undefined}
                  />
                  <ArrowUpward
                    color={'info'}
                    sx={{
                      display: 'block',
                      margin: '0 auto',
                    }}
                  />
                </Fragment>
              ))}
            </>
          )}
        </Box>
      )}
    </>
  )
}
