import { ReactElement, ReactNode } from 'react'

interface SwitchProps<T> {
  data: T
  renderLoading: () => ReactElement
  renderEmpty: () => ReactElement
  renderData: (value: NonNullable<T>) => ReactNode
  isLoading: boolean
  isEmpty: boolean
}

export const Switch = <T, >({ data, renderLoading, renderEmpty, renderData, isLoading, isEmpty }: SwitchProps<T>): JSX.Element => {
  if (isLoading) {
    return renderLoading()
  }

  if (isEmpty || !data) {
    return renderEmpty()

  }

  return <>{renderData(data)}</>
}
