import { FormEventHandler, ReactNode } from 'react'
import * as React from 'react'
import { Box } from '@mui/material'
import { Heading } from 'components/Heading'

interface FormProps {
  title?: string
  content: ReactNode
  actions?: ReactNode
  onSubmit: FormEventHandler<HTMLFormElement>
}

export const Form = ({ title, content, actions, onSubmit }: FormProps) => {
  return (
    <Box
      component={'form'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
      noValidate
      onSubmit={onSubmit}
    >
      {!!title && (
        <Heading
          title={title}
          sx={{ marginBottom: 2 }}
        />
      )}
      <Box
        sx={{
          display: 'grid',
          gap: '16px',
          alignItems: 'center',
          paddingBottom: actions ? '32px' : undefined,
        }}
      >
        {content}
      </Box>
      {actions && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 1,
            margin: 'auto -24px -36px',
            padding: 2,
            borderTop: '1px solid',
            borderColor: 'grey.300',
          }}
        >
          {actions}
        </Box>
      )}
    </Box>
  )
}
