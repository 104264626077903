import React from 'react'
import { CircularProgress } from '@mui/material'

export const FullScreenLoading = () => {
  return (
    <CircularProgress
      sx={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    />
  )
}
