import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { Breadcrumbs, Link, Typography } from '@mui/material'
import useBreadcrumbs, { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'
import { useDictionariesBreadcrumbs } from 'components/BreadcrumbsNavigation/hooks/useDictionariesBreadcrumbs'
import { RootState } from 'store'

const BreadcrumbsNavigation = () => {
  const { t } = useTranslation()
  const _dictionariesBreadcrumbs = useDictionariesBreadcrumbs()
  const routes: BreadcrumbsRoute[] = [
    { path: '/', breadcrumb: null },
    { path: '/settings', breadcrumb: t('userMenu.settings'), props: { single: true } },
    { path: '/:organizationID', breadcrumb: t('menu.home') },
    { path: '/:organizationID/dashboard', breadcrumb: t('menu.panel') },
    { path: '/:organizationID/tasks', breadcrumb: t('menu.tasks') },
    { path: '/:organizationID/tasks/:taskID', props: { inactive: true } },
    { path: '/:organizationID/tasks/:taskID/edit', breadcrumb: t('pages.taskEdit') },
    { path: '/:organizationID/tasks/create', breadcrumb: t('pages.tasksCreate') },
    { path: '/:organizationID/reports', breadcrumb: t('pages.reports') },
    { path: '/:organizationID/employees', breadcrumb: t('menu.employees') },
    { path: '/:organizationID/employees/:employeeID', props: { inactive: true } },
    { path: '/:organizationID/employees/:employeeID/edit', breadcrumb: t('pages.employeeEdit') },
    { path: '/:organizationID/employees/create', breadcrumb: t('pages.addEmployee') },
    { path: '/:organizationID/departments', breadcrumb: t('menu.departments') },
    { path: '/:organizationID/departments/create', breadcrumb: t('pages.departmentCreate') },
    { path: '/:organizationID/departments/:departmentID', breadcrumb: null },
    { path: '/:organizationID/departments/:departmentID/edit', breadcrumb: t('pages.departmentEdit') },
    { path: '/:organizationID/dictionaries', breadcrumb: t('menu.dictionaries'), props: { inactive: true } },
    ..._dictionariesBreadcrumbs,
  ]
  const breadcrumbs = useBreadcrumbs(routes)
  const organization = useSelector((state: RootState) => state.organization)

  return (
    <Breadcrumbs
      sx={{
        fontSize: 14,
        padding: '8px 12px',
        backgroundColor: 'grey.100',
        borderBottom: '1px solid',
        borderBottomColor: 'grey.300',
      }}
    >
      {breadcrumbs.map(({ match, breadcrumb }, index) => (
        <div key={match.pathname}>
          {!!match.route?.props?.single ? (
            <>
              {organization ? (
                <Link
                  underline="hover"
                  color="inherit"
                  component={RouterLink}
                  to={`/${organization.id}`}
                >
                  {t('menu.home')}
                </Link>
              ) : (
                <Link
                  underline="hover"
                  color="inherit"
                  component={RouterLink}
                  to={'/'}
                >
                  {t('menu.home')}
                </Link>
              )}
            </>
          ) : (
            <>
              {(index === breadcrumbs.length - 1 || match.route?.props?.inactive) ? (
                <Typography
                  variant={'body2'}
                  color="text.primary"
                >
                  {breadcrumb}
                </Typography>
              ) : (
                <Link
                  underline="hover"
                  color="inherit"
                  component={RouterLink}
                  to={match.pathname}
                >
                  {breadcrumb}
                </Link>
              )}
            </>
          )}
        </div>
      ))}
    </Breadcrumbs>
  )
}

export default BreadcrumbsNavigation
