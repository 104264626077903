import { useRef } from 'react'
import { Bar, ChartProps } from 'react-chartjs-2'
import { Card, CardContent, Typography } from '@mui/material'
import { ArcElement, BarElement, CategoryScale, Chart, Legend, LinearScale, Tooltip } from 'chart.js'

Chart.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement)

interface ChartBarProps extends Pick<ChartProps<'bar', number[], string>, 'data' | 'title' | 'options'> {}

export const ChartBar = (props: ChartBarProps) => {
  const barRef = useRef<Chart<'bar', number[], string>>()

  return (
    <Card>
      <CardContent>
        {!!props.title && (
          <Typography
            variant={'subtitle2'}
            sx={{
              textAlign: 'center',
              marginBottom: '12px',
            }}
          >
            {props.title}
          </Typography>
        )}
        <Bar
          ref={barRef}
          data={props.data}
          options={{
            // plugins: {
            //   title: {
            //     display: true,
            //     text: props.title,
            //   },
            //   legend: {
            //     position: 'bottom',
            //     labels: {
            //       boxWidth: 40,
            //       font: {
            //         size: 16,
            //       },
            //     },
            //   },
            // },
            // onClick(event: ChartEvent, elements: ActiveElement[]) {
            //   // @ts-expect-error
            //   const elem = elements.find((element) => typeof element.element?.$context?.dataIndex === 'number')
            //
            //   if (elem) {
            //     // @ts-expect-error
            //     props.onArcClick(elem.element?.$context?.dataIndex)
            //   }
            // },
            // onHover: (event, chartElement) => {
            //   // @ts-expect-error
            //   if ( event.native?.target?.style) {
            //     // @ts-expect-error
            //     event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
            //   }
            // },
            ...(props.options ?? {}),
          }}
        />
      </CardContent>
    </Card>
  )
}
