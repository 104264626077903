import { useTranslation } from 'react-i18next'
import { TableCell, TableRow } from '@mui/material'
import { grey } from '@mui/material/colors'
import { TABLE_CELL_DENSE_PADDING } from 'consts'
import { tableRowDefaultSx } from 'data'

interface TableRowEmptyProps {
  columns: number
}

export const TableRowEmpty = ({ columns }: TableRowEmptyProps) => {
  const { t } = useTranslation()

  return (
    <TableRow sx={tableRowDefaultSx}>
      <TableCell
        colSpan={columns}
        sx={{
          color: grey['700'],
          paddingLeft: TABLE_CELL_DENSE_PADDING,
          paddingRight: TABLE_CELL_DENSE_PADDING,
        }}
      >
        {t('common.noResults')}
      </TableCell>
    </TableRow>
  )
}
