import React from 'react'
import { Close } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'

interface DialogHeaderProps {
  title: string
  subtitle?: string
  onClose: () => void
}

export const DialogHeader = ({ title, subtitle, onClose }: DialogHeaderProps) => {
  return (
    <Box
      sx={{
        padding: '16px 58px 0 24px',
      }}
    >
      <Typography variant={'h6'}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant={'subtitle2'}>
          {subtitle}
        </Typography>
      )}
      <IconButton
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
        onClick={() => onClose?.()}
      >
        <Close />
      </IconButton>
    </Box>
  )
}
