import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import { theme } from 'index'
import { LatLng } from 'leaflet'
import { TaskDetailed } from 'api/humanagro.generated'
import { DrawerField } from 'components/Drawer'
import { Map } from 'components/Map'
import { DictionariesRecordChip } from 'features/dictionaries/components/DictionariesRecordChip'
import { FORMAT_DATE_TIME_EXTENSIVE } from 'consts'

interface TaskDrawerTabInformationProps {
  task: TaskDetailed
}

export const TaskDrawerTabInformation = ({ task }: TaskDrawerTabInformationProps) => {
  const { t } = useTranslation()

  return (
    <>
      <DrawerField
        title={t('entity.task.fields.type')}
        content={task.task_type && task.task_type.name}
      />
      {task.task_type && (
        <DrawerField
          title={t('entity.task.fields.subtype')}
          content={task.task_subtype && task.task_subtype.name}
        />
      )}
      <DrawerField
        title={t('entity.equipment.title')}
        content={task.equipment && <DictionariesRecordChip label={task.equipment.name} />}
      />
      <DrawerField
        title={t('entity.inventory.title')}
        content={task.inventory && <DictionariesRecordChip label={task.inventory.name} />}
      />
      <DrawerField
        title={t('entity.vehicle.title')}
        content={task.vehicle && <DictionariesRecordChip label={task.vehicle.name} />}
      />
      <DrawerField
        title={'Местоположение'}
        variant={'column'}
        content={!!(task.start_latitude && task.start_longitude) && (
          <Map
            coords={new LatLng(task.start_latitude, task.start_longitude)}
            initiallyOpen
          />
        )}
      />
      <DrawerField
        title={'Описание'}
        variant={'column'}
        content={task.description && (
          <Box
            sx={{
              padding: '16px',
              background: theme.palette.grey['50'],
            }}
          >
            {task.description.split(/\n/).map((text, index) => (
              <Fragment key={index}>
                {text ? (
                  <Typography
                    variant={'body2'}
                    color={theme.palette.text.secondary}
                    sx={{ marginTop: index !== 0 ? '8px' : undefined }}
                  >
                    {text}
                  </Typography>
                ) : <br />}
              </Fragment>
            ))}
          </Box>
        )}
      />
      <DrawerField
        title={'Дата создания'}
        content={task.created_at ? format(new Date(task.created_at), FORMAT_DATE_TIME_EXTENSIVE, { locale: ru }) : '-'}
      />
      <DrawerField
        title={'Дата обновления'}
        content={task.updated_at ? format(new Date(task.updated_at), FORMAT_DATE_TIME_EXTENSIVE, { locale: ru }) : '-'}
      />
    </>
  )
}
