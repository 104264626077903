import { useQuery } from 'react-query'
import { ANALYTICS_RATING_RESULTS_LIMIT } from 'features/dashboard/constants'
import { EmployeeGroupAssignable } from 'features/employees/data'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { api } from 'api'

export const useQueryRating = (page = 1) => {
  const organizationID = useOrganizationID()
  const query = useQuery(
    ['analytics-rating', organizationID, page],
    async () => {
      const { data } = await api.organizationOrgUserPositionsList(`${organizationID}`, {
        limit: ANALYTICS_RATING_RESULTS_LIMIT,
        offset: (page - 1) * ANALYTICS_RATING_RESULTS_LIMIT,
        o: ['-rating'],
        group_name: EmployeeGroupAssignable,
      })
      return data
    }
  )
  const isLoading = query.isFetching && !query.isRefetching

  return { ...query, isLoading }
}
