import * as React from 'react'
import { Chip } from '@mui/material'
import { UserGroupsShort } from 'api/humanagro.generated'
import { EmployeeGroupColor, EmployeeGroupName } from 'features/employees/data'
import { EmployeeGroup } from 'features/employees/types'

interface EmployeeGroupTagProps {
  group: UserGroupsShort
}

export const EmployeeGroupTag = ({ group }: EmployeeGroupTagProps) => {
  return (
    <Chip
      size={'small'}
      label={EmployeeGroupName[group.name as EmployeeGroup]}
      color={EmployeeGroupColor[group.name as EmployeeGroup]}
    />
  )
}
