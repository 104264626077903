import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Box, CssBaseline, Toolbar } from '@mui/material'
import BreadcrumbsNavigation from 'components/BreadcrumbsNavigation/BreadcrumbsNavigation'
import Header from 'components/Header/Header'
import Sidebar from 'components/Sidebar/Sidebar'
import { EmployeeDrawer } from 'features/employees/components/EmployeeDrawer'
import { TasksDrawer } from 'features/tasks/components/TasksDrawer'
import { useOrganizationID } from 'hooks/useOrganizationID'

export const LayoutSidebar = () => {
  const location = useLocation()
  const organizationID = useOrganizationID()
  const isDashboardPage = [`/${organizationID}`, `/${organizationID}/`].includes(location.pathname)

  return (
    <main>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Header />
        <Sidebar />
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: '1', minHeight: '100vh', bgcolor: 'white' }}>
          <Toolbar variant="dense" />
          {!isDashboardPage && <BreadcrumbsNavigation />}
          <Box sx={{ padding: 3, paddingBottom: 6, flexGrow: 1 }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
      <TasksDrawer />
      <EmployeeDrawer />
    </main>
  )
}
