import * as React from 'react'
import { TableCell, TableHead, TableRow } from '@mui/material'
import { TABLE_CELL_DENSE_PADDING, TABLE_CONTEXT_BUTTON_CELL_WIDTH, TABLE_ID_CELL_WIDTH } from 'consts'

export const DictionariesTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{ width: TABLE_ID_CELL_WIDTH, paddingLeft: TABLE_CELL_DENSE_PADDING }}
        >
          ID
        </TableCell>
        <TableCell>
          Название
        </TableCell>
        <TableCell>
          Дата создания
        </TableCell>
        <TableCell
          align="right"
          sx={{ width: TABLE_CONTEXT_BUTTON_CELL_WIDTH, paddingRight: TABLE_CELL_DENSE_PADDING }}
        />
      </TableRow>
    </TableHead>
  )
}
