import { useMutation } from 'react-query'
import { PaginatedCheckList, TaskStatus } from 'api/humanagro.generated'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { queryClient } from 'services/query'
import { api } from 'api'

export interface MutationSubtaskCreateData {
  name: string,
  departmentID: number,
  taskID: number,
}

export const useMutationSubtaskCreate = () => {
  const organizationID = useOrganizationID()

  return useMutation({
    mutationFn: async ({ name, departmentID, taskID }: MutationSubtaskCreateData) => {
      return await api.taskOrgDepTaskChecksCreate(departmentID.toString(), organizationID.toString(), taskID.toString(), {
        name,
        status: TaskStatus.Value100,
      })
    },
    onSuccess: async ({ data }, { departmentID, taskID }) => {
      queryClient.setQueryData<PaginatedCheckList>(
        ['task-checks', organizationID, departmentID, taskID],
        (oldData) => oldData?.results ? {
          ...oldData,
          results: [
            ...oldData.results,
            data,
          ],
        } : oldData!
      )
    },
  })
}
