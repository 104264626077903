import { DictionaryType } from 'features/dictionaries/types'
import { api } from 'api'

export const getCreateRequest = (type: DictionaryType) => {
  switch (type) {
    case DictionaryType.Equipment:
      return api.organizationOrgEquipmentCreate
    case DictionaryType.Inventory:
      return api.organizationOrgInventoryCreate
    case DictionaryType.Vehicle:
      return api.organizationOrgVehicleCreate
    case DictionaryType.TaskTypes:
      return api.taskOrgTaskTypesCreate
  }
}
