import { Department, UserStatus } from 'api/humanagro.generated'
import { FieldAutocompleteCommonValue } from 'components/Field/components/FieldAutocomplete/types'

export enum EmployeeGroup {
  'Worker' = 'Worker',
  'Specialist' = 'Specialist',
  'Manager' = 'Manager',
}

export enum EmployeeStatusCustomized {
  'Unknown' = UserStatus.Value0,
  'Available' = UserStatus.Value100,
  'Busy' = UserStatus.Value200,
  'Sick' = UserStatus.Value300,
  'Fired' = UserStatus.Value400,
  'Absent' = UserStatus.Value500,
}

export const assertEmployeeStatus = (status: EmployeeStatusCustomized) => status as unknown as UserStatus

export interface EmployeeFormData {
  name: string
  surname: string
  patronymic?: string
  email: string
  phone?: string
  department: Department
  group: FieldAutocompleteCommonValue
  salary: number
  bornDate: string
  employedFrom: string
}
