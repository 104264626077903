import { useQuery } from 'react-query'
import { queryApi } from 'api/queryRequests'
import { useOrganizationID } from 'hooks/useOrganizationID'

export const useQueryTaskPhotos = (taskID: number, departmentID: number) => {
  const organizationID = useOrganizationID()

  return useQuery(
    ['task-photos', organizationID, taskID],
    () => queryApi.getTaskPhotos(organizationID, departmentID, taskID),
    {
      keepPreviousData: true,
    }
  )
}
