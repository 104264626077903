import { DictionaryType } from 'features/dictionaries/types'
import { api } from 'api'

export const getEditRequest = (type: DictionaryType) => {
  switch (type) {
    case DictionaryType.Equipment:
      return api.organizationOrgEquipmentPartialUpdate
    case DictionaryType.Inventory:
      return api.organizationOrgInventoryPartialUpdate
    case DictionaryType.Vehicle:
      return api.organizationOrgVehiclePartialUpdate
    case DictionaryType.TaskTypes:
      return api.taskOrgTaskTypesPartialUpdate
  }
}

export const getRetrieveRequest = (type: DictionaryType) => {
  switch (type) {
    case DictionaryType.Equipment:
      return api.organizationOrgEquipmentRetrieve
    case DictionaryType.Inventory:
      return api.organizationOrgInventoryRetrieve
    case DictionaryType.Vehicle:
      return api.organizationOrgVehicleRetrieve
    case DictionaryType.TaskTypes:
      return api.taskOrgTaskTypesRetrieve
  }
}
