import { UserExtend } from 'api/humanagro.generated'
import { FieldAutocompleteCommonValue } from 'components/Field/components/FieldAutocomplete/types'
import { EmployeeStatusName } from 'features/employees/data'
import { EmployeeStatusCustomized } from 'features/employees/types'
import { LIST_FILTER_KEY } from 'data'

export const getUserFullName = ({ first_name, last_name, middle_name }: Pick<UserExtend, 'first_name' | 'last_name' | 'middle_name'>): string | null => {
  const values = [first_name, last_name, middle_name].filter((v): v is string => !!v)
  return values.length ? values.join(' ') : null
}

export const getUserShortName = ({ first_name, last_name, middle_name }: Pick<UserExtend, 'first_name' | 'last_name' | 'middle_name'>): string | null => {
  if ([first_name, last_name, middle_name].every((v): v is string => !v)) {
    return null
  }

  if (last_name) {
    return (
      (last_name.length > 12 ? `${last_name.slice(0, 10)}... ` : `${last_name} `)
      + (first_name ? `${first_name[0]}.` : '')
      + (middle_name ? `${middle_name[0]}.` : '')
    )
  }

  if (first_name) {
    return (
      `${first_name} `
      + (middle_name ? `${middle_name[0]}.` : '')
    )
  }

  return null
}

export const getEmployeesInitialStatusFilter = (searchParams: URLSearchParams): FieldAutocompleteCommonValue[] => {
  switch (searchParams.get(LIST_FILTER_KEY)) {
    case 'busy':
      return [{ id: EmployeeStatusCustomized.Busy, name: EmployeeStatusName[EmployeeStatusCustomized.Busy] }]
    case 'available':
      return [{ id: EmployeeStatusCustomized.Available, name: EmployeeStatusName[EmployeeStatusCustomized.Available] }]
    case 'absent':
      return [
        { id: EmployeeStatusCustomized.Sick, name: EmployeeStatusName[EmployeeStatusCustomized.Sick] },
        { id: EmployeeStatusCustomized.Absent, name: EmployeeStatusName[EmployeeStatusCustomized.Absent] },
      ]
    default:
      return []
  }
}
