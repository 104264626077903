import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Button, Card, CircularProgress, LinearProgress, Link, Typography } from '@mui/material'
import { Heading } from 'components/Heading'
import { Switch } from 'components/Switch'
import { useQueryReport } from 'features/reports/hooks/useQueryReport'

export const ReportPage = () => {
  const { t } = useTranslation()
  const { reportID } = useParams()
  const { data: report, isLoading } = useQueryReport(isNaN(+reportID!) ? -1 : +reportID!)

  return (
    <>
      <Heading
        title={report?.name ?? t('entity.report.title')}
        sx={{ marginBottom: 2 }}
      />
      <Switch
        data={report}
        isLoading={isLoading}
        isEmpty={!report}
        renderLoading={() => <CircularProgress />}
        renderEmpty={() => <div>no date</div>}
        renderData={(report) => (
          <Card
            variant={'outlined'}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              maxWidth: '600px',
              padding: 2,
            }}
          >
            {report.file ? (
              <Button
                variant={'contained'}
                component={Link}
                href={report.file}
                target={'_blank'}
              >
                {t('entity.report.actions.download')}
              </Button>
            ) : (
              <>
                <LinearProgress
                  sx={{
                    display: 'block',
                    width: '100%',
                  }}
                />
                <Typography
                  variant={'overline'}
                  sx={{
                    marginBottom: '-8px',
                  }}
                >
                  {t('entity.report.messages.creating')}
                </Typography>
              </>
            )}
          </Card>
        )}
      />
    </>
  )
}
