import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Alert, Box, Button, Skeleton, Typography } from '@mui/material'
import { LatLng } from 'leaflet'
import { TaskDetailed, UserPosition } from 'api/humanagro.generated'
import { useEquipmentAutocompleteQuery } from 'api/query/useEquipmentAutocompleteQuery'
import { useInventoryAutocompleteQuery } from 'api/query/useInventoryAutocompleteQuery'
import { useQueryExecutorsAutocomplete } from 'api/query/useQueryExecutorsAutocomplete'
import { useTaskTypeAutocompleteQuery } from 'api/query/useTaskTypeAutocompleteQuery'
import { useVehicleAutocompleteQuery } from 'api/query/useVehicleAutocompleteQuery'
import {
  FieldAutocompleteControlled,
} from 'components/Field/components/FieldAutocomplete/components/FieldAutocompleteControlled'
import {
  FieldAutocompleteMultipleControlled,
} from 'components/Field/components/FieldAutocomplete/components/FieldAutocompleteMultipleControlled'
import { FieldDatepickerControlled } from 'components/Field/components/FieldDatepickerControlled'
import { MAX_ROWS_DEFAULT, MIN_ROWS_DEFAULT } from 'components/Field/components/FieldInput/constants'
import { FieldInputControlled } from 'components/Field/components/FieldInputControlled'
import { FieldLocationChoose } from 'components/Field/components/FieldLocationChoose'
import { Form } from 'components/Form'
import { FormActions } from 'components/FormActions'
import { TaskFormSection } from 'components/TaskFormSection'
import { useDepartmentsAutocompleteQuery } from 'features/departments/hooks/useDepartmentsAutocompleteQuery'
import { EmployeeAvatar } from 'features/employees/components/EmployeeAvatar'
import { EmployeeRatingIcon } from 'features/employees/components/EmployeeRatingIcon'
import { getUserFullName } from 'features/employees/helpers'
import { useQueryTaskBestExecutors } from 'features/tasks/api/useQueryTaskBestExecutors'
import { useQueryTaskBestTime } from 'features/tasks/api/useQueryTaskBestTime'
import { useQueryTaskSubtypes } from 'features/tasks/api/useQueryTaskSubtypes'
import { taskCreateDefaultValues } from 'features/tasks/components/TasksForm/data'
import { getDateTimeString, isEndDateValid } from 'features/tasks/components/TasksForm/helpers'
import { TaskFormData } from 'features/tasks/components/TasksForm/types'
import { TaskDrawerTabSubtasks } from 'features/tasks/components/TasksSectionSubtasks'
import { SubtaskData } from 'features/tasks/components/TasksSectionSubtasks/TaskDrawerTabSubtasks'
import { TASK_EXECUTORS_MAX } from 'features/tasks/constants'
import { PriorityOptions } from 'features/tasks/data'
import { getTaskFormDefaultValuesFromData } from 'features/tasks/helpers'
import { useGoBack } from 'hooks/useGoBack'
import { useHandleError } from 'hooks/useHandleError'
import { useToast } from 'hooks/useToast'
import { TimeOptions, titleValidationOptions } from 'data'

export interface TasksFormProps {
  data?: TaskDetailed
  redirectPathname?: string
  onSave: (data: TaskFormData, subtasks: SubtaskData[], coords: LatLng | null) => Promise<number>
  onSaveAndContinue?: (data: TaskFormData, subtasks: SubtaskData[], coords: LatLng | null) => Promise<number | void>
}

export const TasksForm = ({ data, onSave, onSaveAndContinue }: TasksFormProps) => {
  const { t } = useTranslation()
  const handleError = useHandleError()
  const { enqueueSnackbar } = useToast()
  const navigate = useNavigate()
  const [coords, setCoords] = useState<LatLng | null>( !!data?.start_latitude && !!data?.start_longitude ? new LatLng(data.start_latitude, data.start_longitude) : null)
  const [subtasks, setSubtasks] = useState<SubtaskData[]>([])
  const { formState: { isSubmitting, isSubmitSuccessful }, control, setValue, getValues, reset, watch, handleSubmit } = useForm<TaskFormData>({
    defaultValues: data ? getTaskFormDefaultValuesFromData(data) : taskCreateDefaultValues,
  })
  const goBack = useGoBack({ getValues })
  const watchDepartmentId = watch('department.id')
  const watchExecutors = watch('executors')
  const watchTaskType = watch('type')
  const watchEndDate = watch('endDate')
  const watchEndTime = watch('endTime')

  const departmentsQuery = useDepartmentsAutocompleteQuery()
  const executorsQuery = useQueryExecutorsAutocomplete(getValues('department')?.id)
  const typesQuery = useTaskTypeAutocompleteQuery()
  const subtypesQuery = useQueryTaskSubtypes(getValues('type')?.id)
  const equipmentQuery = useEquipmentAutocompleteQuery()
  const inventoryQuery = useInventoryAutocompleteQuery()
  const vehicleQuery = useVehicleAutocompleteQuery()

  const bestExecutorsQuery = useQueryTaskBestExecutors(watchDepartmentId, watchExecutors.map(({ id }) => id), {
    task_type: watchTaskType?.id,
    planned_start_at: getDateTimeString(watchEndDate, watchEndTime.name),
  })

  const bestTimeQuery = useQueryTaskBestTime(watchDepartmentId, {
    user: watchExecutors[0]?.id,
    task_type: watchTaskType?.id,
    planned_start_at: getDateTimeString(watchEndDate, watchEndTime.name),
    latitude: coords?.lat,
    longitude: coords?.lng,
  })

  const handleCreate = async (data: TaskFormData) => {
    if (!isEndDateValid(data)) {
      enqueueSnackbar(t('entity.task.errors.invalidEndDate'), { variant: 'error' })
      return
    }

    try {
      await onSave(data, subtasks, coords)
      navigate(-1)
    } catch (error) {
      handleError(error)
    }
  }

  const handleSaveAndContinue = async (data: TaskFormData) => {
    try {
      if (!isEndDateValid(data)) {
        enqueueSnackbar(t('entity.task.errors.invalidEndDate'), { variant: 'error' })
        return
      }

      await onSaveAndContinue!(data, subtasks, coords)
      reset()
    } catch (error) {
      handleError(error)
    }
  }

  return (
    <Form
      title={data ? t('pages.taskEdit') : t('pages.tasksCreate')}
      content={(
        <>
          <TaskFormSection>
            <FieldInputControlled
              name={'title'}
              control={control}
              rules={titleValidationOptions}
              label={t('entity.task.fields.title')}
              placeholder={'Посеять...'}
              autoFocus
            />
            <FieldAutocompleteControlled
              name={'department'}
              control={control}
              rules={{ required: true }}
              label={t('entity.task.fields.department')}
              options={departmentsQuery.data ?? []}
              isLoading={departmentsQuery.isLoading}
              onInputChange={departmentsQuery.setSearch}
              onAfterChange={() => { setValue('executors', [] ) }}
            />
            <FieldAutocompleteControlled
              name={'type'}
              control={control}
              rules={{ required: true }}
              label={t('entity.task.fields.type')}
              options={typesQuery.data ?? []}
              isLoading={typesQuery.isLoading}
              onInputChange={typesQuery.setSearch}
              onAfterChange={() => { setValue('subtype', { name: '' } ) }}
            />
            {!!getValues('type') && (
              <FieldAutocompleteControlled
                name={'subtype'}
                control={control}
                rules={{ required: false }}
                label={t('entity.task.fields.subtype')}
                options={subtypesQuery.data ?? []}
                isLoading={subtypesQuery.isLoading}
                onInputChange={subtypesQuery.setSearch}
              />
            )}
            <FieldAutocompleteControlled
              name={'priority'}
              control={control}
              rules={{ required: true }}
              label={t('entity.task.fields.priority')}
              options={PriorityOptions}
            />
            <FieldAutocompleteMultipleControlled
              name={'executors'}
              control={control}
              rules={{ required: true }}
              label={t('entity.task.fields.executors')}
              disabled={!getValues('department')}
              max={TASK_EXECUTORS_MAX}
              options={executorsQuery.data ?? []}
              isLoading={executorsQuery.isLoading}
              onInputChange={executorsQuery.setSearch}
            />
            <Alert
              severity={'info'}
              color={bestExecutorsQuery.isError ? 'error' : bestExecutorsQuery.isSuccess && !!bestExecutorsQuery.data?.length ? 'success' : 'info'}
            >
              <Box>
                {bestExecutorsQuery.status === 'idle' && (
                  <>
                    Для получения предложенных сотрудников необходимо заполнить поля{' '}
                    <strong>подразделение</strong>, <strong>тип задачи</strong> и <strong>дата начала</strong>
                  </>
                )}
                {bestExecutorsQuery.isError && (
                  <>
                    Ошибка при получении предложенных сотрудников
                  </>
                )}
                {bestExecutorsQuery.isLoading && (
                  <>
                    <Skeleton
                      variant={'text'}
                      width={'300px'}
                    />
                    <Skeleton
                      variant={'text'}
                      width={'180px'}
                    />
                  </>
                )}
              </Box>
              {bestExecutorsQuery.isSuccess && (
                <>
                  <Box>
                    Предложенные сотрудники:
                  </Box>
                  {bestExecutorsQuery.data?.length ? (
                    <Box
                      sx={{
                        display: 'grid',
                        gap: '4px',
                        marginTop: '8px',
                      }}
                    >
                      {bestExecutorsQuery.data!.map(({ user, value, rating }) => (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                          }}
                        >
                          <EmployeeAvatar
                            key={user.id}
                            user={user}
                            departmentID={watchDepartmentId!}
                            sx={{ paddingRight: '12px' }}
                            renderAfterChip={(
                              <EmployeeRatingIcon
                                rating={rating ?? null}
                                sx={{
                                  position: 'absolute',
                                  top: '-2px',
                                  right: '0',
                                  width: '20px',
                                  height: '20px',
                                }}
                                labelSx={{
                                  fontSize: '11px !important',
                                }}
                              />
                            )}
                            onClick={() => {
                              setValue('executors', [
                                ...watchExecutors,
                              {
                                id: user.id,
                                name: getUserFullName(user) ?? '',
                              } as unknown as UserPosition,
                              ])
                            }}
                          />
                          <Typography
                            variant={'body2'}
                          >
                            Подходит на <strong>{Math.floor(value * 100)}%</strong>
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  ) : (
                    <Box
                      sx={{ marginTop: '4px' }}
                    >
                      <strong>Не найдено</strong>
                    </Box>
                  )}
                  {!!bestExecutorsQuery.data?.[0]?.user && (
                    <Button
                      variant={'contained'}
                      color="primary"
                      size="small"
                      sx={{ marginTop: '12px' }}
                      disableElevation
                      onClick={() => {
                        setValue('executors', [
                          ...watchExecutors,
                          {
                            id: bestExecutorsQuery.data![0].user.id,
                            name: getUserFullName(bestExecutorsQuery.data![0].user) ?? '',
                          } as unknown as UserPosition,
                        ])
                      }}
                    >
                      Автоназначение
                    </Button>
                  )}
                </>
              )}
            </Alert>
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
              }}
            >
              <FieldDatepickerControlled
                name={'startDate'}
                control={control}
                rules={{ required: true }}
                label={t('entity.task.fields.startDate')}
              />
              <FieldAutocompleteControlled
                name={'startTime'}
                control={control}
                rules={{ required: true }}
                label={t('entity.task.fields.startTime')}
                options={TimeOptions}
                sx={{ width: '128px' }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
              }}
            >
              <FieldDatepickerControlled
                name={'endDate'}
                control={control}
                rules={{ required: true }}
                label={t('entity.task.fields.endDate')}
              />
              <FieldAutocompleteControlled
                name={'endTime'}
                control={control}
                rules={{ required: true }}
                label={t('entity.task.fields.endTime')}
                options={TimeOptions}
                sx={{ width: '128px' }}
              />
            </Box>
            <Alert
              severity={'info'}
              color={bestTimeQuery.isError ? 'error' : bestTimeQuery.isSuccess ? 'success' : 'info'}
            >
              <Box>
                {bestTimeQuery.status === 'idle' && (
                  <>
                    Для получения рекомендуемого времени выполнения задачи сотрудником необходимо заполнить поля{' '}
                    <strong>подразделение</strong>, <strong>исполнитель</strong>, <strong>тип задачи</strong> и <strong>дата начала</strong>
                  </>
                )}
                {bestTimeQuery.isError && (
                  <>
                    Ошибка при получении рекомендуемого времени
                  </>
                )}
                {bestTimeQuery.isLoading && (
                  <>
                    <Skeleton
                      variant={'text'}
                      width={'300px'}
                    />
                    <Skeleton
                      variant={'text'}
                      width={'180px'}
                    />
                  </>
                )}
                {bestTimeQuery.isSuccess && (
                  <>
                    Рекомендуемое время выполнения задачи:{' '}
                    <strong>
                      {Math.floor(bestTimeQuery.data!.value / 60 / 60)} часов{' '}
                      {Math.floor(((bestTimeQuery.data!.value / 60) % 60))} минут
                    </strong>
                  </>
                )}
              </Box>
            </Alert>
            {bestTimeQuery.isSuccess && (
              <Alert
                severity={'info'}
                color={bestTimeQuery.isError ? 'error' : bestTimeQuery.isSuccess ? 'success' : 'info'}
              >
                <Box>
                    Расчитанная себестоимость работ:{' '}
                  <strong>
                    {bestTimeQuery.data?.cost ? (
                      <>
                        {Math.trunc(bestTimeQuery.data.cost)}{' '}
                        руб.
                      </>
                    ) : 'недостаточно данных для расчета'}
                  </strong>
                </Box>
              </Alert>
            )}
            <FieldLocationChoose
              value={coords ?? null}
              name={'location'}
              label={t('entity.task.fields.location')}
              required={false}
              onChange={setCoords}
            />
          </TaskFormSection>
          <TaskFormSection>
            <FieldAutocompleteControlled
              name={'equipment'}
              control={control}
              rules={{ required: false }}
              label={t('entity.equipment.title')}
              options={equipmentQuery.data ?? []}
              isLoading={equipmentQuery.isLoading}
              onInputChange={equipmentQuery.setSearch}
            />
            <FieldAutocompleteControlled
              name={'inventory'}
              control={control}
              rules={{ required: false }}
              label={t('entity.inventory.title')}
              options={inventoryQuery.data ?? []}
              isLoading={inventoryQuery.isLoading}
              onInputChange={inventoryQuery.setSearch}
            />
            <FieldAutocompleteControlled
              name={'vehicle'}
              control={control}
              rules={{ required: false }}
              label={t('entity.vehicle.title')}
              options={vehicleQuery.data ?? []}
              isLoading={vehicleQuery.isLoading}
              onInputChange={vehicleQuery.setSearch}
            />
            <FieldInputControlled
              name={'description'}
              control={control}
              rules={{ required: false }}
              label={t('entity.task.fields.description')}
              placeholder={t('entity.task.fields.descriptionPlaceholder')}
              minRows={MIN_ROWS_DEFAULT}
              maxRows={MAX_ROWS_DEFAULT}
              multiline
            />
          </TaskFormSection>
          {!data && (
            <TaskFormSection>
              <TaskDrawerTabSubtasks
                subtasks={subtasks}
                onAdd={async (name) => setSubtasks((state) => [...state, { id: Date.now(), name }])}
                onDelete={(id) => setSubtasks((state) => state.filter((subtask) => id !== subtask.id))}
                onEdit={async (id, name) => setSubtasks((state) => state.map((subtask) => subtask.id === id ? { ...subtask, name } : subtask))}
              />
            </TaskFormSection>
          )}
        </>
      )}
      actions={(
        <FormActions
          isSubmitting={isSubmitting}
          isSubmitSuccessful={isSubmitSuccessful}
          onGoBack={goBack}
          onSubmitAndSave={onSaveAndContinue ? handleSubmit(handleSaveAndContinue) : undefined}
        />
      )}
      onSubmit={handleSubmit(handleCreate)}
    />
  )
}
