import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Cookies from 'js-cookie'
import { User } from 'api/humanagro.generated'
import CookiesKeys from 'data/cookiesKeys'
import { EmployeeGroup } from 'features/employees/types'
import { api } from 'api'

interface AuthContextProps {
  user: User | null
  isLoading: boolean
  isManager: boolean
  signOut: () => void
}

const AuthContext = createContext<AuthContextProps>({
  user: null,
  isLoading: true,
  isManager: false,
  signOut: () => {},
})

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation()
  const [user, setUser] = useState<User | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    (async () => {
      const token = Cookies.get(CookiesKeys.token)

      if (!token || !!user) {
        setIsLoading(false)
        return
      }

      try {
        const { data: user } = await api.accountMeRetrieve()
        setUser(user)
      } catch (error) {
        console.log('error', error)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [location, user])

  const logout = () => {
    setUser(null)
    Cookies.remove(CookiesKeys.token)
  }

  return (
    <AuthContext.Provider value={{
      user,
      isLoading,
      isManager: user?.groups.some((g) => g.name === EmployeeGroup.Manager) ?? false,
      signOut: logout,
    }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
