import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab } from '@mui/material'
import { decodeQueryParams, searchStringToObject } from 'serialize-query-params'
import { Heading } from 'components/Heading'
import { DashboardTabRating } from 'features/dashboard/components/DashboardTabRating'
import { DashboardTabStats } from 'features/dashboard/components/DashboardTabStats'
import { DASHBOARD_TAB_QUERY_KEY } from 'features/dashboard/constants'
import { DashboardPageTabKey } from 'features/dashboard/data'
import { queryParamsConfigMap } from 'data'

export const DashboardPage = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const { tab: dashboardTabKey } = decodeQueryParams(queryParamsConfigMap, searchStringToObject(location.search))
  const [tab, setTab] = useState(DashboardPageTabKey.Stats)

  if (dashboardTabKey && dashboardTabKey !== tab && Object.values(DashboardPageTabKey).some((v) => v === dashboardTabKey)) {
    setTab(dashboardTabKey as DashboardPageTabKey)
  }

  const handleSetTab = (value: DashboardPageTabKey) => {
    setTab(value)
    navigate({
      search: createSearchParams({
        ...searchStringToObject(location.search),
        [DASHBOARD_TAB_QUERY_KEY]: value,
      }).toString(),
    })
  }

  return (
    <>
      <Heading
        title={t('menu.panel')}
        sx={{ marginBottom: 2 }}
      />
      <TabContext
        value={tab}
      >
        <Box sx={{
          borderBottom: 1,
          borderColor: 'divider',
          marginBottom: '20px',
          marginTop: '-8px',
        }}
        >
          <TabList
            value={tab}
            onChange={(_, value) => handleSetTab(value)}
          >
            <Tab
              label="Общие показатели"
              value={DashboardPageTabKey.Stats}
            />
            <Tab
              label="Рейтинг сотрудников"
              value={DashboardPageTabKey.Rating}
            />
          </TabList>
        </Box>
        <TabPanel
          value={DashboardPageTabKey.Stats}
          sx={{ padding: 0 }}
        >
          <DashboardTabStats />
        </TabPanel>
        <TabPanel
          value={DashboardPageTabKey.Rating}
          sx={{ padding: 0 }}
        >
          <DashboardTabRating />
        </TabPanel>
      </TabContext>
    </>
  )
}
