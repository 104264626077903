import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { Add, AddTask, GroupAdd, LibraryAdd, PersonAddAlt1 } from '@mui/icons-material'
import { IconButton, ListItemIcon, MenuItem } from '@mui/material'
import ContextMenu from 'components/ContextMenu'
import { useAuth } from 'services/auth'
import { RootState } from 'store'

const HeaderCreationMenu = () => {
  const { t } = useTranslation()
  const { isManager } = useAuth()
  const company = useSelector((state: RootState) => state.organization)
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const open = !!anchorEl

  const menuItems = [
    {
      title: t('actions.createTask'),
      to: `/${company?.id}/tasks/create`,
      icon: <AddTask fontSize={'small'} />,
      show: true,
    },
    {
      title: t('actions.createEmployee'),
      to: `/${company?.id}/employees/create`,
      icon: <PersonAddAlt1 fontSize="small" />,
      show: isManager,
    },
    {
      title: t('actions.createDepartment'),
      to: `/${company?.id}/departments/create`,
      icon: <GroupAdd fontSize="small" />,
      show: isManager,
    },
    {
      title: t('pages.reports'),
      to: `/${company?.id}/reports`,
      icon: <LibraryAdd fontSize="small" />,
      show: true,
    },
  ]

  const handleButtonClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        size={'medium'}
        aria-haspopup="true"
        sx={{
          paddingX: 0,
          minWidth: 0,
          height: '28px',
          width: '28px',
          backgroundColor: 'white',
        }}
        disableRipple
        onClick={handleButtonClick}
      >
        <Add sx={{ fontSize: '20px' }}/>
      </IconButton>
      <ContextMenu
        anchorEl={anchorEl}
        id={'creation-menu'}
        open={open}
        disableScrollLock
        onClose={handleClose}
        onClick={handleClose}
      >
        {menuItems.filter(({ show }) => show).map(({ title, to, icon }, index) => (
          <MenuItem
            key={index + title}
            to={to}
            component={RouterLink}
          >
            <ListItemIcon>
              {icon}
            </ListItemIcon>
            {title}
          </MenuItem>
        ))}
      </ContextMenu>
    </>
  )
}

export default HeaderCreationMenu
