import { createStore, Reducer } from 'redux'
import { Organization } from 'api/humanagro.generated'

export interface RootState {
  organization: Organization | null
}

const defaultState: RootState = {
  organization: null,
}

const reducer: Reducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_ORGANIZATION':
      return { ...state, organization: action.payload }
    default:
      return state
  }
}

const store = createStore(reducer)

export default store
