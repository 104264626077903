import { FormEvent, ReactNode } from 'react'
import { Outlet } from 'react-router-dom'
import { Avatar, Box, Container, CssBaseline, Typography } from '@mui/material'

interface AuthFormLayoutProps {
  children: ReactNode
  title: string
  onSubmit: (e: FormEvent) => void
}

const AuthFormLayout = ({ children, title, onSubmit }: AuthFormLayoutProps) => {
  return (
    <Container
      component="main"
      maxWidth="xs"
    >
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar
          src={'/logo.svg'}
          alt={'Humanagro'}
          sx={{
            m: 1,
            width: '120px',
            height: '120px',
          }}
        />
        <Typography
          component="h1"
          variant="h5"
        >
          {title}
        </Typography>
        <Box
          component="form"
          sx={{ mt: 1 }}
          noValidate
          onSubmit={onSubmit}
        >
          <Outlet />
          {children}
        </Box>
      </Box>
    </Container>
  )
}

export default AuthFormLayout
