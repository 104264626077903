import * as React from 'react'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { lightGreen } from '@mui/material/colors'
import { format, subDays } from 'date-fns'
import { theme } from 'index'
import { ChartBar } from 'components/ChartBar'
import { ChartDoughnut } from 'components/ChartDoughnut'
import { FieldAutocomplete } from 'components/Field'
import { FieldAutocompleteCommonValue } from 'components/Field/components/FieldAutocomplete/types'
import { SectionFilters } from 'components/SectionFilters'
import { DashboardStatsCard } from 'features/dashboard/components/DashboardStatsCard'
import { DashboardPeriodOptions } from 'features/dashboard/data'
import { getCardsData, getEmployeesChartData, getTasksChartData } from 'features/dashboard/helpers'
import { useQueryDashboard } from 'features/dashboard/hooks/useQueryDashboard'
import { DashboardPeriodOption } from 'features/dashboard/types'
import { DepartmentFieldAutocomplete } from 'features/departments/components/DepartmentFieldAutocomplete'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { LIST_FILTER_KEY } from 'data'

export const DashboardTabStats = () => {
  const { t } = useTranslation()
  const organizationID = useOrganizationID()
  const navigate = useNavigate()
  const [department, setDepartment] = useState<FieldAutocompleteCommonValue | null>(null)
  const [period, setPeriod] = useState<DashboardPeriodOption>(DashboardPeriodOptions[0])
  const { data } = useQueryDashboard(department?.id ?? null, period.period.from, period.period.to)
  const cardsData = useMemo(() => getCardsData(data?.[0]), [data])
  const employeesChartData = useMemo(() => getEmployeesChartData(data?.[0]), [data])
  const employeesWeekChartData = useMemo(() => data?.map(getEmployeesChartData) ?? [], [data])
  const tasksChartData = useMemo(() => getTasksChartData(data?.[0]), [data])
  const tasksWeekChartData = useMemo(() => data?.map(getTasksChartData) ?? [], [data])
  const daysLabels = useMemo(() => ([
    format(subDays(new Date(period.period.from), 6), 'dd.MM'),
    format(subDays(new Date(period.period.from), 5), 'dd.MM'),
    format(subDays(new Date(period.period.from), 4), 'dd.MM'),
    format(subDays(new Date(period.period.from), 3), 'dd.MM'),
    format(subDays(new Date(period.period.from), 2), 'dd.MM'),
    format(subDays(new Date(period.period.from), 1), 'dd.MM'),
    format(new Date(period.period.from), 'dd.MM'),
  ]), [period.period.from])

  const handleGoToEmployees = useCallback((key: keyof ReturnType<typeof getEmployeesChartData>) => {
    navigate({
      pathname: `/${organizationID}/employees`,
      search: createSearchParams({ [LIST_FILTER_KEY]: key }).toString(),
    })
  }, [navigate, organizationID])

  const handleGoToTasks = useCallback((key: keyof ReturnType<typeof getTasksChartData>) => {
    navigate({
      pathname: `/${organizationID}/tasks`,
      search: createSearchParams({ [LIST_FILTER_KEY]: key }).toString(),
    })
  }, [navigate, organizationID])

  return (
    <>
      <SectionFilters>
        <DepartmentFieldAutocomplete
          department={department}
          disableClearable={false}
          onChange={setDepartment}
        />
        <FieldAutocomplete
          name={'period'}
          value={period}
          options={DashboardPeriodOptions}
          label={t('entity.dashboard.filters.period')}
          sx={{ width: '300px' }}
          labelInside
          disableClearable
          filterOptions
          onChange={(value) => setPeriod(value as DashboardPeriodOption)}
        />
      </SectionFilters>
      <Box
        sx={{
          display: 'grid',
          gap: '16px',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gap: '16px',
            gridTemplateColumns: 'repeat(auto-fill, 164px)',
          }}
        >
          {cardsData.map(({ title, value, Icon }) => (
            <DashboardStatsCard
              key={title}
              title={title}
              Icon={Icon}
              value={value.toString()}
            />
          ))}
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 450px)',
            gap: '16px',
          }}
        >
          <ChartDoughnut
            title={'Сотрудники'}
            data={{
              labels: [
                `Занятые (${employeesChartData.busy})`,
                `Свободные (${employeesChartData.available})`,
                `Отсутствуют (${employeesChartData.absent})`,
              ],
              datasets: [
                {
                  label: 'сотрудники',
                  data: Object.values(employeesChartData),
                  backgroundColor: [
                    theme.palette.warning.light,
                    lightGreen['500'],
                    theme.palette.error.light,
                  ],
                  borderColor: [
                    theme.palette.warning.dark,
                    lightGreen['600'],
                    theme.palette.error.dark,
                  ],
                  borderWidth: 1,
                },
              ],
            }}
            onArcClick={(i) => handleGoToEmployees(Object.keys(employeesChartData).at(i) as keyof ReturnType<typeof getEmployeesChartData>)}
          />
          <ChartDoughnut
            title={'Задачи'}
            data={{
              labels: [
                `В процессе (${tasksChartData.started})`,
                `Завершено (${tasksChartData.done})`,
                `Отклонено/пауза (${tasksChartData.cancelled})`,
              ],
              datasets: [
                {
                  label: 'задачи',
                  data: Object.values(tasksChartData),
                  backgroundColor: [
                    theme.palette.warning.light,
                    lightGreen['500'],
                    theme.palette.error.light,
                  ],
                  borderColor: [
                    theme.palette.warning.dark,
                    lightGreen['600'],
                    theme.palette.error.dark,
                  ],
                  borderWidth: 1,
                },
              ],
            }}
            onArcClick={(i) => handleGoToTasks(Object.keys(tasksChartData).at(i) as keyof ReturnType<typeof getTasksChartData>)}
          />
          <ChartBar
            title={'Последние семь дней'}
            data={{
              labels: daysLabels,
              datasets: [
                {
                  label: 'Занятые',
                  data: employeesWeekChartData.map(({ busy }) => busy).reverse(),
                  backgroundColor: theme.palette.warning.light,
                },
                {
                  label: 'Свободные',
                  data: employeesWeekChartData.map(({ available }) => available).reverse(),
                  backgroundColor: lightGreen['500'],
                },
                {
                  label: 'Отсутствуют',
                  data: employeesWeekChartData.map(({ absent }) => absent).reverse(),
                  backgroundColor: theme.palette.error.light,
                },
              ],
            }}
          />
          <ChartBar
            title={'Последние семь дней'}
            data={{
              labels: daysLabels,
              datasets: [
                {
                  label: 'В процессе',
                  data: tasksWeekChartData.map(({ started }) => started).reverse(),
                  backgroundColor: theme.palette.warning.light,
                },
                {
                  label: 'Завершено',
                  data: tasksWeekChartData.map(({ done }) => done).reverse(),
                  backgroundColor: lightGreen['500'],
                },
                {
                  label: 'Отклонено/пауза',
                  data: tasksWeekChartData.map(({ cancelled }) => cancelled).reverse(),
                  backgroundColor: theme.palette.error.light,
                },
              ],
            }}
          />
        </Box>
      </Box>
    </>
  )
}
