import { Box, Typography } from '@mui/material'
import { format } from 'date-fns'
import { LatLng } from 'leaflet'
import { TaskPoint, TaskStatus } from 'api/humanagro.generated'
import { MarkerData } from 'components/Map'
import { TaskStatusChip } from 'features/tasks/components/TaskStatusChip'
import { getTaskMarkerColorByStatus } from 'features/tasks/helpers'
import { FORMAT_DATE_TIME } from 'consts'

export const getMarkerDataFromTaskPoints = (list: TaskPoint[]): MarkerData[] => list.reduce<MarkerData[]>((acc, task) => {
  let coords: LatLng | null
  const foundComment = task.comments.find((comment) => comment.latitude && comment.longitude)

  if (foundComment) {
    coords = new LatLng(foundComment.latitude ?? 0, foundComment.longitude ?? 0)
  } else if (task.start_latitude || task.start_longitude) {
    coords = new LatLng(task.start_latitude ?? 0, task.start_longitude ?? 0)
  } else {
    coords = null
  }

  if (coords) {
    acc.push({
      taskID: task.id,
      coords,
      color: getTaskMarkerColorByStatus(task.status ?? TaskStatus.Value0),
      content: (
        <>
          <Typography
            component={'div'}
            variant={'body2'}
          >
            {task.name}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              marginTop: '8px',
            }}
          >
            <TaskStatusChip
              status={task.status ?? TaskStatus.Value0}
            />
            {foundComment?.updated_at && (
              <Typography
                component={'div'}
                variant={'body2'}
              >
                {format(new Date(foundComment.updated_at), FORMAT_DATE_TIME)}
              </Typography>
            )}
          </Box>
        </>
      ),
    })
  }

  return acc
}, [])
