import { Box, Chip } from '@mui/material'
import { UserStatus } from 'api/humanagro.generated'
import { EmployeeStatusName } from 'features/employees/data'
import { useEmployeeStatusColor } from 'features/employees/hooks/useEmployeeStatusColor'

interface EmployeeStatusChipProps {
  status: UserStatus
  statusName?: string
}

export const EmployeeStatusChip = ({ status, statusName }: EmployeeStatusChipProps) => {
  const EmployeeStatusColor = useEmployeeStatusColor()

  return (
    <Chip
      size={'small'}
      variant={'outlined'}
      icon={(
        <Box
          sx={{
            width: '7px',
            height: '7px',
            borderRadius: '50%',
            backgroundColor: EmployeeStatusColor[status],
            marginLeft: '8px !important',
          }}
        />
      )}
      label={statusName ?? EmployeeStatusName[status]}
    />
  )
}
