import { PropsWithChildren } from 'react'
import { Box } from '@mui/material'
import { theme } from 'index'

interface DrawerFooterProps {}

export const DrawerFooter = ({ children }: PropsWithChildren<DrawerFooterProps>) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '8px',
        padding: '16px 20px',
        borderTop: `1px solid ${theme.palette.grey['300']}`,
      }}
    >
      {children}
    </Box>
  )
}
