import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { theme } from 'index'
import { decodeQueryParams, searchStringToObject } from 'serialize-query-params'
import { useQueryEmployee } from 'api/query/useQueryEmployee'
import { Drawer, DrawerContent, DrawerHeader, DrawerField } from 'components/Drawer'
import { FullScreenLoading } from 'components/FullScreenLoading'
import { EmployeeContactsList } from 'features/employees/components/EmployeeContactsList'
import { EmployeeGroupTags } from 'features/employees/components/EmployeeGroupTags'
import { EmployeeRatingIcon } from 'features/employees/components/EmployeeRatingIcon'
import { EmployeeStatusChip } from 'features/employees/components/EmployeeStatusChip'
import { getUserFullName } from 'features/employees/helpers'
import { EmployeeGroup } from 'features/employees/types'
import { useQueryTasks } from 'features/tasks/api/useQueryTasks'
import { TasksTable } from 'features/tasks/components/TasksTable'
import { queryParamsConfigMap } from 'data'

export const EmployeeDrawer = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const { employeeID } = decodeQueryParams(queryParamsConfigMap, searchStringToObject(location.search))
  const open = useMemo(() => !!employeeID, [employeeID])
  const { data: employee, isLoading, isSuccess } = useQueryEmployee(employeeID ?? -1, open)
  const [page, setPage] = useState(0)
  const tasksQuery = useQueryTasks(undefined, employee, page + 1)

  const handleClose = () => {
    navigate({
      search: createSearchParams({}).toString(),
    })
  }

  return (
    <Drawer
      open={open}
      size={'large'}
      onClose={handleClose}
    >
      {isLoading || !isSuccess || !employee ? <FullScreenLoading /> : (
        <>
          <DrawerHeader>
            {getUserFullName(employee.user)}{' '}
            <Box
              component={'span'}
              color={theme.palette.grey['500']}
              fontWeight={400}
            >
              ID-{employee.id}
            </Box>
          </DrawerHeader>
          <DrawerContent>
            <Box
              sx={{
                display: 'grid',
                gap: '16px',
              }}
            >
              <DrawerField
                title={t('entity.employee.fields.department')}
                content={employee.department.name}
              />
              <DrawerField
                title={t('entity.employee.fields.status')}
                content={employee.status && (
                  <EmployeeStatusChip
                    status={employee.status}
                    statusName={employee.status_name}
                  />
                )}
              />
              <DrawerField
                title={t('entity.employee.fields.roles')}
                content={<EmployeeGroupTags groups={employee.user.groups} />}
              />
              {employee.user.groups.some(({ name }) => name === EmployeeGroup.Worker) && (
                <DrawerField
                  title={t('entity.employee.fields.rating')}
                  content={<EmployeeRatingIcon rating={employee.rating?.value ?? null} />}
                />
              )}
              <DrawerField
                title={t('entity.employee.fields.contacts')}
                content={<EmployeeContactsList employee={employee.user} />}
              />
              <DrawerField
                variant={'column'}
                title={t('entity.employee.fields.tasks')}
                content={(
                  <TasksTable
                    tasks={tasksQuery.data?.results}
                    isLoading={tasksQuery.isLoading}
                    isEmpty={!tasksQuery.isSuccess}
                    page={page}
                    count={tasksQuery.data?.count}
                    onSetPage={setPage}
                  />
                )}
              />
            </Box>
          </DrawerContent>
        </>
      )}
    </Drawer>
  )
}
