import React, { ReactNode } from 'react'
import { Box } from '@mui/material'

interface SectionFiltersProps {
  children?: ReactNode
}

export const SectionFilters = ({ children }: SectionFiltersProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        marginBottom: 2,
      }}
    >
      {children}
    </Box>
  )
}
