import { useMutation } from 'react-query'
import { PaginatedCheckList } from 'api/humanagro.generated'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { queryClient } from 'services/query'
import { api } from 'api'

export const useMutationSubtaskDelete = () => {
  const organizationID = useOrganizationID()

  return useMutation({
    mutationFn: async ({
      departmentID,
      subtaskID,
      taskID,
    }: {
      departmentID: number,
      subtaskID: number
      taskID: number,
    }) => {
      return await api.taskOrgDepTaskChecksDestroy(departmentID.toString(), subtaskID, organizationID.toString(), taskID.toString())
    },
    onSuccess: async (_, { subtaskID, departmentID, taskID }) => {
      queryClient.setQueryData<PaginatedCheckList>(['task-checks', organizationID, departmentID, taskID],
        (oldData) => {
          return oldData?.results ? {
            ...oldData,
            results: oldData.results.filter(({ id }) => id !== subtaskID),
          } : (oldData ?? {})
        }
      )
    },
  })
}
