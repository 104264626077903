import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { ButtonAdd } from 'components/ButtonAdd'
import { Heading } from 'components/Heading'
import { TableRowEmpty } from 'components/TableRowEmpty'
import { TableRowSkeleton } from 'components/TableRowSkeleton'
import { DepartmentRow } from 'features/departments/components/DepartmentRow'
import { useDepartmentsQuery } from 'features/departments/hooks/useDepartmentsQuery'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { useAuth } from 'services/auth'
import { TABLE_CELL_DENSE_PADDING, TABLE_CONTEXT_BUTTON_CELL_WIDTH, TABLE_ID_CELL_WIDTH } from 'consts'

const COLUMNS = 4

const DepartmentsList = () => {
  const { t } = useTranslation()
  const { isManager } = useAuth()
  const organizationID = useOrganizationID()
  const query = useDepartmentsQuery()

  return (
    <>
      <Heading
        title={t('menu.departments')}
        count={!query.isLoading && query.isSuccess && query.data.length}
        actions={isManager ? <ButtonAdd to={`/${organizationID}/departments/create`} /> : undefined}
        sx={{ marginBottom: 2 }}
      />
      <Paper variant={'outlined'}>
        <Table
          size={'small'}
          aria-label={t('menu.departments')}
        >
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ width: TABLE_ID_CELL_WIDTH, paddingLeft: TABLE_CELL_DENSE_PADDING }}
              >
                ID
              </TableCell>
              <TableCell>
                Название
              </TableCell>
              <TableCell>
                Дата создания
              </TableCell>
              <TableCell
                align="right"
                sx={{ width: TABLE_CONTEXT_BUTTON_CELL_WIDTH, paddingRight: TABLE_CELL_DENSE_PADDING }}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {query.isLoading ? <TableRowSkeleton columns={COLUMNS} /> : (
              query.isSuccess && query.data.length > 0 ? (
                query.data.map((department) => (
                  <DepartmentRow
                    key={department.id}
                    department={department}
                    onAfterDelete={query.refetch}
                  />
                ))
              ) : <TableRowEmpty columns={COLUMNS} />
            )}
          </TableBody>
        </Table>
      </Paper>
    </>
  )
}

export default DepartmentsList
