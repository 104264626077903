import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Delete, Edit } from '@mui/icons-material'
import { ListItemIcon, ListItemText, MenuItem, TableCell, TableRow } from '@mui/material'
import { format } from 'date-fns'
import { ButtonContextActions } from 'components/ButtonContextActions'
import ContextMenu from 'components/ContextMenu'
import { TABLE_CELL_DENSE_PADDING, FORMAT_DATE_DEFAULT } from 'consts'
import { tableRowDefaultSx } from 'data'

interface DictionariesRowProps {
  id: number
  name: string
  createdAt: string
  onDelete: (id: number) => void
  onEdit: (id: number) => void
}

export const DictionariesTableRow = ({ id, name, createdAt, onDelete, onEdit }: DictionariesRowProps) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const isContextMenuOpen = !!anchorEl

  const handleMenuClick = (e: React.MouseEvent) => {
    setAnchorEl(e.currentTarget)
  }

  return (
    <>
      <TableRow
        sx={tableRowDefaultSx}
        hover
      >
        <TableCell
          component="th"
          scope="row"
          sx={{ paddingLeft: TABLE_CELL_DENSE_PADDING }}
        >
          {id}
        </TableCell>
        <TableCell>
          {name}
        </TableCell>
        <TableCell>
          {format(new Date(createdAt), FORMAT_DATE_DEFAULT)}
        </TableCell>
        <TableCell
          align="right"
          sx={{ paddingRight: TABLE_CELL_DENSE_PADDING }}
        >
          <ButtonContextActions onClick={handleMenuClick} />
        </TableCell>
      </TableRow>
      <ContextMenu
        open={isContextMenuOpen}
        anchorEl={anchorEl}
        onClick={() => setAnchorEl(null)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => onEdit(id)}>
          <ListItemIcon sx={{ marginRight: '-8px' }}>
            <Edit fontSize={'small'} />
          </ListItemIcon>
          <ListItemText>
            {t('actions.change')}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => onDelete(id)}>
          <ListItemIcon sx={{ marginRight: '-8px' }}>
            <Delete fontSize={'small'} />
          </ListItemIcon>
          <ListItemText>
            {t('actions.delete')}
          </ListItemText>
        </MenuItem>
      </ContextMenu>
    </>
  )
}
