import { ru } from 'date-fns/locale'
import { TaskStatus } from 'api/humanagro.generated'

export const i18nLocRu = {
  __icon: '🇷🇺',
  __title: 'Русский',
  __dateFnsLocale: ru,
  translation: {
    __app: 'HumanAgro',
    common: {
      language: 'Язык',
      noResults: 'Результаты отсутствуют',
      unknownError: 'Произошла ошибка',
      loading: 'Загрузка...',
      close: 'Закрыть',
      notChosen: 'Не выбрано',
      chosen: 'выбрано',
    },
    menu: {
      home: 'Главная',
      panel: 'Панель управления',
      tasks: 'Задачи',
      reports: 'Отчеты',
      employees: 'Сотрудники',
      departments: 'Подразделения',
      dictionaries: 'Справочники',
      taskTypes: 'Типы задач',
      inventory: 'Инвентарь',
      equipment: 'Оборудование',
      vehicle: 'Транспортные средства',
    },
    actions: {
      add: 'Добавить',
      save: 'Сохранить',
      form: 'Сформировать',
      change: 'Изменить',
      delete: 'Удалить',
      saveAndContinue: 'Сохранить и добавить еще',
      back: 'Назад',
      createTask: 'Создать задачу',
      createEmployee: 'Создать сотрудника',
      createDepartment: 'Создать подразделение',
      choose: 'Выберите...',
      chooseShort: 'Выбрать',
    },
    pages: {
      addEmployee: 'Добавить сотрудника',
      tasksCreate: 'Добавить задачу',
      taskEdit: 'Изменить задачу',
      reports: 'Сформировать отчет',
      departmentCreate: 'Добавить подразделение',
      departmentEdit: 'Изменить подразделение',
      taskTypesCreate: 'Добавить тип задачи',
      taskTypesEdit: 'Изменить тип задачи',
      inventoryCreate: 'Добавить инвентарь',
      inventoryEdit: 'Изменить инвентарь',
      equipmentCreate: 'Добавить оборудование',
      equipmentEdit: 'Изменить оборудование',
      vehicleCreate: 'Добавить транспортное средство',
      vehicleEdit: 'Изменить транспортное средство',
      employeeEdit: 'Изменить данные сотрудника',
    },
    userMenu: {
      settings: 'Настройки',
      logout: 'Выйти',
    },
    validation: {
      common: 'Введите корректное значение',
      commonSelect: 'Выберите вариант из списка',
      commonDate: 'Выберите дату',
      telephone: 'Введите корректный номер телефона используя числа и символы +, -, ( )',
    },
    entity: {
      dashboard: {
        filters: {
          period: 'Период',
        },
      },
      employee: {
        title: 'Сотрудник',
        fields: {
          fio: 'ФИО',
          name: 'Имя',
          surname: 'Фамилия',
          patronymic: 'Отчество',
          telephone: 'Номер телефона',
          email: 'Эл. почта',
          status: 'Статус',
          department: 'Подразделение',
          role: 'Должность',
          roles: 'Роли',
          rating: 'Рейтинг',
          contacts: 'Контакты',
          tasks: 'Задачи',
        },
        filters: {
          periodStart: 'Начало периода',
          periodEnd: 'Конец периода',
          name: 'Поиск по ФИО',
        },
        actions: {
          fire: 'Отметить уволенным',
          absent: 'Отметить отсутствующим',
          available: 'Отметить доступным',
        },
        errors: {
          sidebar: 'Не удалось получить информацию о сотруднике',
          fire: 'Не удалось отметить сотрудника уволенным',
          absent: 'Не удалось отметить сотрудника отсутствующим',
          available: 'Не удалось отметить сотрудника доступным',
        },
      },
      task: {
        title: 'Задача',
        fields: {
          template: 'Шаблон задачи',
          title: 'Название',
          department: 'Подразделение',
          author: 'Автор',
          executors: 'Исполнители',
          type: 'Тип',
          subtype: 'Подтип',
          priority: 'Приоритет',
          location: 'Местоположение',
          time: 'Срок',
          startDate: 'Дата начала',
          startTime: 'Время',
          endDate: 'Дата окончания',
          endTime: 'Время',
          description: 'Описание',
          descriptionPlaceholder: 'Подробное описание задачи',
          status: 'Статус',
          startBoth: 'Дата начала план. / факт.',
          endBoth: 'Дата окончания план. / факт.',
          plannedStart: 'Дата планируемого начала',
          plannedEnd: 'Дата планируемого окончания',
          actualStart: 'Дата фактического начала',
          actualEnd: 'Дата фактического окончания',
        },
        filters: {
          title: 'Поиск по названию',
        },
        actions: {
          chooseLocation: 'Выбрать местоположение для задачи',
          chooseDepartment: 'Выберите подразделение...',
          cancel: 'Отменить',
        },
        messages: {
          cancelSuccess: 'Задача успешно отменена',
          editSuccess: 'Задача успешно изменена',
          createSuccess: 'Задача успешно создана',
        },
        errors: {
          noDepartments: 'В организацию не добавлено подразделений. Добавьте подразделение и сотрудников, чтобы создавать задачи.',
          executorsLimited: 'Выбрано максимально возможное количество исполнителей',
          invalidEndDate: 'Дата окончания не должна быть раньше даты начала',
        },
      },
      report: {
        title: 'Отчет',
        fields: {
          from: 'Период с',
          to: 'по',
        },
        messages: {
          from: 'Отчет от',
          success: 'Сделан запрос на создание отчета. Выполнен переход на страницу отчета.',
          chooseDepartment: 'Выберите подразделение, чтобы выбрать исполнителей',
          creating: 'Создание отчета...',
        },
        actions: {
          download: 'Скачать на устройство',
        },
      },
      taskStatus: {
        [TaskStatus.Value0]: 'Неизвестен',
        [TaskStatus.Value100]: 'Создана',
        [TaskStatus.Value101]: 'Отменена',
        [TaskStatus.Value110]: 'В пути',
        [TaskStatus.Value200]: 'В процессе',
        [TaskStatus.Value220]: 'На паузе',
        [TaskStatus.Value300]: 'Завершена',
        [TaskStatus.Value400]: 'Ошибка',
      },
      department: {
        title: 'Подразделение',
        fields: {
          title: 'Название',
          employeesAmount: 'Кол-во сотрудников',
        },
      },
      inventory: {
        title: 'Инвентарь',
        fields: {
          title: 'Название',
        },
        messages: {
          createSuccess: 'Инвентарь успешно добавлен',
          editSuccess: 'Инвентарь сохранен',
        },
      },
      equipment: {
        title: 'Оборудование',
        fields: {
          title: 'Название',
        },
        messages: {
          createSuccess: 'Оборудование успешно добавлено',
          editSuccess: 'Оборудование сохранено',
        },
      },
      vehicle: {
        title: 'Транспортное средство',
        fields: {
          title: 'Название',
        },
        messages: {
          createSuccess: 'Транспортное средство успешно добавлено',
          editSuccess: 'Транспортное средство сохранено',
        },
      },
      taskTypes: {
        fields: {
          title: 'Тип задачи',
          name: 'Название',
          createdDate: 'Дата создания',
        },
        messages: {
          createSuccess: 'Тип задачи успешно добавлен',
          editSuccess: 'Тип задачи сохранен',
        },
      },
      taskSubtype: {
        title: 'Подтипы',
        fields: {
          title: 'Название',
        },
        actions: {
          edit: 'Изменить подтип задачи',
          add: 'Добавить подтип задачи',
          forType: 'для типа',
        },
        messages: {
          createSuccessAll: 'Все подтипы задач были успешно созданы',
          createSuccess: 'Подтип задачи были успешно создан',
          editSuccess: 'Подтип задачи были успешно изменен',
        },
      },
      taskSubtask: {
        titleEdit: 'Изменить название подзадачи',
        placeholderName: 'Введите название подзадачи...',
        notFound: 'Подзадач по задаче не найдено',
      },
      map: {
        scheme: 'Схема',
        hybrid: 'Гибрид',
        satellite: 'Спутник',
      },
    },
  },
}
