import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { Event, FactCheck, Info, Photo } from '@mui/icons-material'
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Divider, Tab } from '@mui/material'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import { theme } from 'index'
import { decodeQueryParams, searchStringToObject } from 'serialize-query-params'
import { TaskStatus } from 'api/humanagro.generated'
import { useMutationSubtaskCreate } from 'api/query/useMutationSubtaskCreate'
import { useMutationSubtaskDelete } from 'api/query/useMutationSubtaskDelete'
import { useMutationSubtaskUpdate } from 'api/query/useMutationSubtaskUpdate'
import { Drawer, DrawerContent, DrawerField, DrawerHeader } from 'components/Drawer'
import { DrawerFooter } from 'components/Drawer/components/DrawerFooter'
import { FullScreenLoading } from 'components/FullScreenLoading'
import { EmployeeAvatar } from 'features/employees/components/EmployeeAvatar'
import { useQueryTask } from 'features/tasks/api/useQueryTask'
import { useQueryTaskChecks } from 'features/tasks/api/useQueryTaskChecks'
import { TaskDrawerTabEvents } from 'features/tasks/components/TaskDrawerTabEvents'
import { TaskDrawerTabInformation } from 'features/tasks/components/TaskDrawerTabInformation'
import { TaskDrawerTabPhotos } from 'features/tasks/components/TaskDrawerTabPhotos'
import { TaskDrawerTabSubtasks } from 'features/tasks/components/TasksSectionSubtasks'
import { TaskStatusChip } from 'features/tasks/components/TaskStatusChip'
import { useTaskActions } from 'features/tasks/hooks/useTaskActions'
import { FORMAT_DATE_TIME_EXTENSIVE } from 'consts'
import { queryParamsConfigMap } from 'data'

export enum TaskDrawerTab {
  Information = 'information',
  Subtasks = 'subtasks',
  Events = 'events',
  Photos = 'photos',
}

export const TabsDrawerTabDefault = TaskDrawerTab.Events

export const TasksDrawer = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const [tab, setTab] = useState(TabsDrawerTabDefault)
  const { taskID } = decodeQueryParams(queryParamsConfigMap, searchStringToObject(location.search))
  const subtaskMutationCreate = useMutationSubtaskCreate()
  const subtaskMutationDelete = useMutationSubtaskDelete()
  const subtaskMutationUpdate = useMutationSubtaskUpdate()
  const open = useMemo(() => !!taskID, [taskID])
  const { data: task, isLoading, isSuccess } = useQueryTask(taskID ?? -1, open)
  const subtasksQuery = useQueryTaskChecks({ departmentID: task?.department.id, taskID: task?.id })
  const { actions, actionable } = useTaskActions(taskID ?? -1, task?.status ?? null)

  const handleClose = () => {
    setTab(TabsDrawerTabDefault)
    navigate({
      search: createSearchParams({}).toString(),
    })
  }

  const handleAddSubtask = useCallback(async (name: string) => {
    if (!task) {
      return
    }

    await subtaskMutationCreate.mutateAsync({
      name,
      departmentID: task.department.id,
      taskID: task.id,
    })
  }, [task, subtaskMutationCreate])

  const handleDeleteSubtask = useCallback(async (id: number) => {
    if (!task) {
      return
    }

    await subtaskMutationDelete.mutateAsync({
      departmentID: task.department.id,
      taskID: task.id,
      subtaskID: id,
    })
  }, [task, subtaskMutationDelete])

  const handleEditSubtask = useCallback(async (id: number, name: string) => {
    if (!task) {
      return
    }

    await subtaskMutationUpdate.mutateAsync({
      name,
      departmentID: task.department.id,
      taskID: task.id,
      subtaskID: id,
    })
  }, [task, subtaskMutationUpdate])

  const handleSubtaskStatusChange = useCallback(async (id: number, status: TaskStatus) => {
    if (!task) {
      return
    }

    await subtaskMutationUpdate.mutateAsync({
      status,
      departmentID: task.department.id,
      taskID: task.id,
      subtaskID: id,
    })
  }, [task, subtaskMutationUpdate])

  return (
    <Drawer
      open={open}
      size={'medium'}
      onClose={handleClose}
    >
      {isLoading || !isSuccess || !task ? <FullScreenLoading/> : (
        <>
          <DrawerHeader>
            <Box
              component={'span'}
            >
              {task.name}{' '}
            </Box>
            <Box
              component={'span'}
              color={theme.palette.grey['500']}
              fontWeight={400}
            >
              ID-{task.id}
            </Box>
          </DrawerHeader>
          <DrawerContent>
            <Box
              sx={{
                display: 'grid',
                gap: '14px',
                gridTemplateRows: 'auto auto',
              }}
            >
              <DrawerField
                title={t('entity.task.fields.status')}
                content={task.status && <TaskStatusChip status={task.status}/>}
              />
              <DrawerField
                title={t('entity.task.fields.executors')}
                content={(
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '4px',
                    }}
                  >
                    {task.executors.map((user) => (
                      <EmployeeAvatar
                        key={user.id}
                        user={user}
                        departmentID={task.department.id}
                      />
                    ))}
                  </Box>
                )}
              />
              <DrawerField
                title={t('entity.task.fields.author')}
                content={task.creator && (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '8px',
                    }}
                  >
                    <EmployeeAvatar
                      user={task.creator}
                      departmentID={task.department.id}
                    />
                  </Box>
                )}
              />
              <DrawerField
                title={t('entity.task.fields.startBoth')}
                content={(
                  <>
                    {task.planned_start_at ? format(new Date(task.planned_start_at), FORMAT_DATE_TIME_EXTENSIVE, { locale: ru }) : '-'}
                    {' / '}
                    {task.started_at ? format(new Date(task.started_at), FORMAT_DATE_TIME_EXTENSIVE, { locale: ru }) : '-'}
                  </>
                )}
              />
              <DrawerField
                title={t('entity.task.fields.endBoth')}
                content={(
                  <>
                    {task.deadline ? format(new Date(task.deadline), FORMAT_DATE_TIME_EXTENSIVE, { locale: ru }) : '-'}
                    {' / '}
                    {task.completed_at ? format(new Date(task.completed_at), FORMAT_DATE_TIME_EXTENSIVE, { locale: ru }) : '-'}
                  </>
                )}
              />
              <Divider
                sx={{
                  margin: '24px 0 -6px',
                }}
              />
              <Box>
                <TabContext
                  value={tab}
                >
                  <Box sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    marginBottom: '20px',
                    marginTop: '-8px',
                  }}
                  >
                    <TabList
                      value={tab}
                      onChange={(_, value) => setTab(value)}
                    >
                      <Tab
                        label="Информация"
                        value={TaskDrawerTab.Information}
                        icon={<Info/>}
                        iconPosition={'start'}
                        sx={{ minHeight: 56 }}
                      />
                      <Tab
                        label="Подзадачи"
                        value={TaskDrawerTab.Subtasks}
                        icon={<FactCheck/>}
                        iconPosition={'start'}
                        sx={{ minHeight: 56 }}
                      />
                      <Tab
                        label="События"
                        value={TaskDrawerTab.Events}
                        icon={<Event/>}
                        iconPosition={'start'}
                        sx={{ minHeight: 56 }}
                      />
                      <Tab
                        label="Фотографии"
                        value={TaskDrawerTab.Photos}
                        icon={<Photo/>}
                        iconPosition={'start'}
                        sx={{ minHeight: 56 }}
                      />
                    </TabList>
                  </Box>
                  <TabPanel
                    value={TaskDrawerTab.Information}
                    sx={{
                      display: 'grid',
                      gap: '14px',
                      padding: 0,
                    }}
                  >
                    <TaskDrawerTabInformation
                      task={task}
                    />
                  </TabPanel>
                  <TabPanel
                    value={TaskDrawerTab.Subtasks}
                    sx={{ padding: 0 }}
                  >
                    <TaskDrawerTabSubtasks
                      subtasks={subtasksQuery.data?.results ?? []}
                      taskStatus={task.status}
                      onAdd={handleAddSubtask}
                      onDelete={handleDeleteSubtask}
                      onEdit={handleEditSubtask}
                      onStatusChange={handleSubtaskStatusChange}
                    />
                  </TabPanel>
                  <TabPanel
                    value={TaskDrawerTab.Events}
                    sx={{ padding: 0 }}
                  >
                    <TaskDrawerTabEvents
                      taskID={task.id}
                      departmentID={task.department.id}
                      status={task.status ?? TaskStatus.Value0}
                    />
                  </TabPanel>
                  <TabPanel
                    value={TaskDrawerTab.Photos}
                    sx={{ padding: 0 }}
                  >
                    <TaskDrawerTabPhotos
                      taskID={task.id}
                      departmentID={task.department.id}
                    />
                  </TabPanel>
                </TabContext>
              </Box>
            </Box>
          </DrawerContent>
          {actionable && (
            <DrawerFooter>
              {actions.cancel && !!task?.department?.id && (
                <LoadingButton
                  type={'button'}
                  variant={'outlined'}
                  color={'error'}
                  loading={actions.cancel.loading}
                  onClick={() => actions.cancel!.call(task.department.id)}
                >
                  Отменить
                </LoadingButton>
              )}
              {actions.edit && (
                <LoadingButton
                  type={'button'}
                  variant={'outlined'}
                  color={'primary'}
                  loading={actions.edit.loading}
                  onClick={actions.edit.call}
                >
                  Изменить
                </LoadingButton>
              )}
            </DrawerFooter>
          )}
        </>
      )}
    </Drawer>
  )
}
