import React, { PropsWithChildren, ReactNode } from 'react'
import { Box, Divider, Typography } from '@mui/material'

interface DrawerHeaderProps {
  renderAfter?: ReactNode
}

export const DrawerHeader = ({ renderAfter, children }: PropsWithChildren<DrawerHeaderProps>) => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          padding: '16px 56px 16px 20px',
        }}
      >
        <Typography variant={'h6'}>
          {children}
        </Typography>
        {renderAfter}
      </Box>
      <Divider />
    </Box>
  )
}
