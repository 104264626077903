import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from '@mui/material'
import { useDepartmentsQuery } from 'features/departments/hooks/useDepartmentsQuery'

export const DepartmentsAbsentAlert = () => {
  const { t } = useTranslation()
  const departmentsQuery = useDepartmentsQuery()

  return (departmentsQuery.isSuccess && departmentsQuery.data.length === 0) ? (
    <Alert
      severity={'warning'}
      sx={{ marginBottom: '16px' }}
    >
      {t('entity.task.errors.noDepartments')}
    </Alert>
  ) : null
}
