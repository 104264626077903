import { Box, Typography } from '@mui/material'
import { UserPosition } from 'api/humanagro.generated'
import { EmployeeAvatar } from 'features/employees/components/EmployeeAvatar'
import { EmployeeRatingIcon } from 'features/employees/components/EmployeeRatingIcon'
import { getUserFullName } from 'features/employees/helpers'

interface DashboardEmployeeRatingRowProps {
  userPosition: UserPosition
}

export const DashboardEmployeeRatingRow = ({ userPosition }: DashboardEmployeeRatingRowProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '450px',
        gap: '12px',
      }}
    >
      <EmployeeAvatar
        user={{
          ...userPosition.user,
          id: userPosition.id,
        }}
        departmentID={userPosition.department.id}
        labelled={false}
        fetchUserPositionID={false}
      />
      <Typography
        variant={'body1'}
        sx={{
          marginRight: 'auto',
        }}
      >
        {getUserFullName(userPosition.user)}
      </Typography>
      <EmployeeRatingIcon
        rating={userPosition.rating?.value ?? null}
        sx={{
          marginLeft: '24px',
          flexShrink: 0,
        }}
      />
    </Box>
  )
}
