import * as React from 'react'
import { Box, Link } from '@mui/material'
import { UserExtend } from 'api/humanagro.generated'

interface EmployeeContactsListProps {
  employee: UserExtend
}

export const EmployeeContactsList = ({ employee }: EmployeeContactsListProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
      }}
    >
      <Link
        href={`mailto:${employee.email}`}
      >
        {employee.email}
      </Link>
      {!!employee.phone_number && (
        <Link
          href={`tel:${employee.phone_number}`}
        >
          {employee.phone_number}
        </Link>
      )}
    </Box>
  )
}
