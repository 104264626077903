import React, { useEffect } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { Box, Dialog, DialogProps } from '@mui/material'
import { TaskType } from 'api/humanagro.generated'
import { useMutationSubtype } from 'api/query/useMutationSubtype'
import { DialogContent } from 'components/DialogContent'
import { DialogHeader } from 'components/DialogHeader'
import { FieldInputControlled } from 'components/Field'
import { Form } from 'components/Form'
import { useHandleError } from 'hooks/useHandleError'
import { useToast } from 'hooks/useToast'
import { titleValidationOptions } from 'data'

interface TaskSubtypeDialogCreateProps extends Pick<DialogProps, 'open'> {
  type: TaskType
  onClose: () => void
}

export const TaskSubtypeDialogCreate = ({ type, open, onClose }: TaskSubtypeDialogCreateProps) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useToast()
  const handleError = useHandleError()
  const subtypeMutation = useMutationSubtype(type.id)
  const { formState: { isSubmitting, isSubmitSuccessful }, control, reset, handleSubmit } = useForm({
    defaultValues: {
      name: '',
    },
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
  }, [isSubmitSuccessful, reset])

  const handleAdd = async (data: FieldValues) => {
    try {
      await subtypeMutation.mutateAsync({ name: data.name })
      enqueueSnackbar(t('entity.taskSubtype.messages.createSuccess'), { variant: 'success' })
      onClose()
    } catch (error) {
      handleError(error)
      throw error
    }
  }

  return (
    <Dialog
      open={open}
      maxWidth={'lg'}
      onClose={onClose}
    >
      <DialogHeader
        title={t('entity.taskSubtype.actions.add')}
        subtitle={`${t('entity.taskSubtype.actions.forType')} "${type.name}" (ID-${type.id})`}
        onClose={onClose}
      />
      <DialogContent>
        <Form
          content={(
            <>
              <FieldInputControlled
                name={'name'}
                control={control}
                rules={titleValidationOptions}
                label={t('entity.taskSubtype.fields.title')}
                placeholder={t('entity.taskSubtype.fields.title')}
              />
              <Box>
                <LoadingButton
                  type={'submit'}
                  variant={'contained'}
                  loading={subtypeMutation.isLoading}
                  disabled={isSubmitting && !isSubmitSuccessful}
                  disableRipple
                  disableElevation
                >
                  {t('actions.save')}
                </LoadingButton>
              </Box>
            </>
          )}
          onSubmit={handleSubmit(handleAdd)}
        />
      </DialogContent>
    </Dialog>
  )
}
