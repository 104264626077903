/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ActivationSerializerV2 {
  new_password: string;
  token: string;
  uid: string;
}

export interface Check {
  /** @format date-time */
  readonly created_at: string;
  readonly id: number;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
  /** @maxLength 127 */
  name: string;
  /** @format double */
  position_x?: number;
  /** @format double */
  position_y?: number;
  /**
   * @min 0
   * @max 32767
   */
  status?: TaskStatus;
  readonly status_name: string;
  readonly task: number;
  /** @format date-time */
  readonly updated_at: string;
}

export interface CheckHistory {
  /** @maxLength 100 */
  history_change_reason?: string | null;
  /** @format date-time */
  history_date: string;
  /** @format uuid */
  readonly history_id: string;
  history_type: HistoryTypeEnum;
  history_user?: number | null;
  /**
   * @format int64
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  id?: number;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
  /** @maxLength 127 */
  name: string;
  /**
   * @min 0
   * @max 32767
   */
  status?: TaskStatus;
}

export interface CheckPoint {
  readonly id: number;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
  /** @format double */
  position_x?: number;
  /** @format double */
  position_y?: number;
  /**
   * @min 0
   * @max 32767
   */
  status?: TaskStatus;
  /** @format date-time */
  readonly updated_at: string;
}

export interface Comment {
  /** @format date-time */
  readonly created_at: string;
  readonly creator: UserName;
  readonly id: number;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
  /** @format date-time */
  performed_at?: string | null;
  /** @format double */
  position_x?: number;
  /** @format double */
  position_y?: number;
  /**
   * @min 0
   * @max 32767
   */
  status?: TaskStatus;
  readonly status_name: string;
  readonly task: number;
  text?: string;
  /** @format date-time */
  readonly updated_at: string;
}

export interface CommentHistory {
  /** @maxLength 100 */
  history_change_reason?: string | null;
  /** @format date-time */
  history_date: string;
  /** @format uuid */
  readonly history_id: string;
  history_type: HistoryTypeEnum;
  history_user?: number | null;
  /**
   * @format int64
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  id?: number;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
  /** @format date-time */
  performed_at?: string | null;
  /**
   * @min 0
   * @max 32767
   */
  status?: TaskStatus;
  text?: string;
}

export interface CommentPoint {
  readonly id: number;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
  /** @format date-time */
  performed_at?: string | null;
  /** @format double */
  position_x?: number;
  /** @format double */
  position_y?: number;
  /**
   * @min 0
   * @max 32767
   */
  status?: TaskStatus;
  /** @format date-time */
  readonly updated_at: string;
}

export interface CreateDashboard {
  readonly department: number;
  readonly id: number;
  /** @format date */
  readonly period_end: string;
  /** @format date-time */
  readonly updated_at: string;
}

export interface CreateOrganizationDashboard {
  readonly id: number;
  readonly organization: number;
  /** @format date */
  readonly period_end: string;
  /** @format date-time */
  readonly updated_at: string;
}

export interface Dashboard {
  /** @format double */
  avg_rating?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_task_cancelled?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_task_done?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_task_error?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_task_expired?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_task_in_progress?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_task_stop?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_task_total?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_today_task_cancelled?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_today_task_done?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_today_task_error?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_today_task_expired?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_today_task_in_progress?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_today_task_stop?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_today_task_total?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_user_absent?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_user_busy?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_user_free?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_user_total?: number;
  department: number;
  readonly id: number;
  /** @format date */
  period_end?: string;
  /** @format date-time */
  updated_at?: string;
}

export interface Department {
  /** @format date-time */
  readonly created_at: string;
  readonly id: number;
  /** @maxLength 127 */
  name: string;
  readonly organization: number;
  readonly settings: DepartmentSettings;
  /** @format date-time */
  readonly updated_at: string;
}

export interface DepartmentSettings {
  /** @format date-time */
  readonly created_at: string;
  readonly department: number;
  /** @format date-time */
  readonly updated_at: string;
}

export interface DepartmentShort {
  readonly id: number;
  /** @maxLength 127 */
  name: string;
  organization: number;
}

export interface Device {
  /** @format date-time */
  readonly created_at: string;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  device_type?: DeviceType;
  readonly id: number;
  /** @maxLength 255 */
  token: string;
  /** @format date-time */
  readonly updated_at: string;
  readonly user: number;
  /** @maxLength 255 */
  user_agent?: string;
}

export enum DeviceType {
  Value0 = 0,
  Value1 = 1,
  Value100 = 100,
  Value200 = 200,
  Value210 = 210,
  Value300 = 300,
}

export interface Enum {
  readonly label: string;
  readonly value: string;
}

export interface Equipment {
  /** @format date-time */
  readonly created_at: string;
  readonly id: number;
  /** @maxLength 127 */
  name: string;
  readonly organization: number;
  /** @format date-time */
  readonly updated_at: string;
}

export interface EquipmentAsset {
  /** @format date-time */
  readonly created_at: string;
  equipment?: number | null;
  readonly id: number;
  /** @maxLength 62 */
  number: string;
  readonly organization: number;
  /** @format date-time */
  readonly updated_at: string;
}

export interface EquipmentAssetNumber {
  readonly id: number;
  /** @maxLength 62 */
  number: string;
}

export interface EquipmentName {
  readonly id: number;
  /** @maxLength 127 */
  name: string;
}

export enum GroupNameEnum {
  Worker = "Worker",
  Specialist = "Specialist",
}

export enum HistoryTypeEnum {
  "+" = "+",
  "~" = "~",
  "-" = "-",
}

export interface Inventory {
  /** @format date-time */
  readonly created_at: string;
  readonly id: number;
  /** @maxLength 127 */
  name: string;
  readonly organization: number;
  /** @format date-time */
  readonly updated_at: string;
}

export interface InventoryAsset {
  /** @format date-time */
  readonly created_at: string;
  readonly id: number;
  inventory?: number | null;
  /** @maxLength 62 */
  number?: string;
  readonly organization: number;
  /** @format date-time */
  readonly updated_at: string;
}

export interface InventoryAssetNumber {
  readonly id: number;
  /** @maxLength 62 */
  number?: string;
}

export interface InventoryName {
  readonly id: number;
  /** @maxLength 127 */
  name: string;
}

export interface MyDepartment {
  /** @format date-time */
  readonly created_at: string;
  readonly id: number;
  /** @maxLength 127 */
  name: string;
  organization: MyOrganization;
  /** @format date-time */
  readonly updated_at: string;
}

export interface MyOrganization {
  /** @format date-time */
  readonly created_at: string;
  /** @maxLength 255 */
  full_name: string;
  readonly id: number;
  readonly owner: number;
  /** @maxLength 127 */
  short_name: string;
  /** @format date-time */
  readonly updated_at: string;
}

export interface MyUserPosition {
  /** @format date */
  born_date?: string | null;
  /** @format date-time */
  readonly created_at: string;
  readonly department: MyDepartment;
  /** @format date */
  employment_date?: string | null;
  readonly id: number;
  position?: number | null;
  readonly rating: UserPositionRating;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  salary?: number;
  /** @maxLength 62 */
  specialization?: string;
  /**
   * @min 0
   * @max 32767
   */
  status?: UserStatus;
  readonly status_name: string;
  /** @format date-time */
  readonly updated_at: string;
  user: number;
  work_experience: number;
}

export enum NameEnum {
  DashboardUpdate = "dashboard_update",
}

export interface Notification {
  /** @format date-time */
  readonly created_at: string;
  readonly id: number;
  payload?: Record<string, any>;
  text: string;
  /** @maxLength 255 */
  title: string;
  readonly user: number;
}

export interface Organization {
  /** @format date-time */
  readonly created_at: string;
  /** @maxLength 255 */
  full_name: string;
  readonly id: number;
  readonly owner: number;
  readonly settings: OrganizationSettings;
  /** @maxLength 127 */
  short_name: string;
  /** @format date-time */
  readonly updated_at: string;
}

export interface OrganizationDashboard {
  /** @format double */
  avg_rating?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_task_cancelled?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_task_done?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_task_error?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_task_expired?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_task_in_progress?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_task_stop?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_task_total?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_today_task_cancelled?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_today_task_done?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_today_task_error?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_today_task_expired?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_today_task_in_progress?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_today_task_stop?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_today_task_total?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_user_absent?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_user_busy?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_user_free?: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  count_user_total?: number;
  readonly id: number;
  organization: number;
  /** @format date */
  period_end?: string;
  /** @format date-time */
  updated_at?: string;
}

export interface OrganizationSettings {
  /** @format date-time */
  readonly created_at: string;
  /** @format time */
  daily_notification_hour?: string | null;
  deadline_notification_delay?: string | null;
  readonly organization: number;
  start_notification_delay?: string | null;
  time_zone: string;
  /** @format date-time */
  readonly updated_at: string;
}

export interface PaginatedCheckHistoryList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: CheckHistory[];
}

export interface PaginatedCheckList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: Check[];
}

export interface PaginatedCommentHistoryList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: CommentHistory[];
}

export interface PaginatedCommentList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: Comment[];
}

export interface PaginatedDashboardList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: Dashboard[];
}

export interface PaginatedDepartmentList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: Department[];
}

export interface PaginatedDeviceList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: Device[];
}

export interface PaginatedEquipmentAssetList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: EquipmentAsset[];
}

export interface PaginatedEquipmentList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: Equipment[];
}

export interface PaginatedInventoryAssetList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: InventoryAsset[];
}

export interface PaginatedInventoryList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: Inventory[];
}

export interface PaginatedMyUserPositionList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: MyUserPosition[];
}

export interface PaginatedNotificationList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: Notification[];
}

export interface PaginatedOrganizationDashboardList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: OrganizationDashboard[];
}

export interface PaginatedOrganizationList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: Organization[];
}

export interface PaginatedPhotoList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: Photo[];
}

export interface PaginatedPositionList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: Position[];
}

export interface PaginatedReportList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: Report[];
}

export interface PaginatedResultList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: Result[];
}

export interface PaginatedSystemMessageList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: SystemMessage[];
}

export interface PaginatedTaskHistoryList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: TaskHistory[];
}

export interface PaginatedTaskList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: Task[];
}

export interface PaginatedTaskPointList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: TaskPoint[];
}

export interface PaginatedTaskSubTypeList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: TaskSubType[];
}

export interface PaginatedTaskTypeList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: TaskType[];
}

export interface PaginatedUserGeolocationList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: UserGeolocation[];
}

export interface PaginatedUserPositionList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: UserPosition[];
}

export interface PaginatedVehicleAssetList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: VehicleAsset[];
}

export interface PaginatedVehicleList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: Vehicle[];
}

export interface PasswordResetConfirmSerializerV2 {
  new_password: string;
  token: string;
  uid: string;
}

export interface PatchedCheck {
  /** @format date-time */
  readonly created_at?: string;
  readonly id?: number;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
  /** @maxLength 127 */
  name?: string;
  /** @format double */
  position_x?: number;
  /** @format double */
  position_y?: number;
  /**
   * @min 0
   * @max 32767
   */
  status?: TaskStatus;
  readonly status_name?: string;
  readonly task?: number;
  /** @format date-time */
  readonly updated_at?: string;
}

export interface PatchedComment {
  /** @format date-time */
  readonly created_at?: string;
  readonly creator?: UserName;
  readonly id?: number;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
  /** @format date-time */
  performed_at?: string | null;
  /** @format double */
  position_x?: number;
  /** @format double */
  position_y?: number;
  /**
   * @min 0
   * @max 32767
   */
  status?: TaskStatus;
  readonly status_name?: string;
  readonly task?: number;
  text?: string;
  /** @format date-time */
  readonly updated_at?: string;
}

export interface PatchedDepartment {
  /** @format date-time */
  readonly created_at?: string;
  readonly id?: number;
  /** @maxLength 127 */
  name?: string;
  readonly organization?: number;
  readonly settings?: DepartmentSettings;
  /** @format date-time */
  readonly updated_at?: string;
}

export interface PatchedEquipment {
  /** @format date-time */
  readonly created_at?: string;
  readonly id?: number;
  /** @maxLength 127 */
  name?: string;
  readonly organization?: number;
  /** @format date-time */
  readonly updated_at?: string;
}

export interface PatchedEquipmentAsset {
  /** @format date-time */
  readonly created_at?: string;
  equipment?: number | null;
  readonly id?: number;
  /** @maxLength 62 */
  number?: string;
  readonly organization?: number;
  /** @format date-time */
  readonly updated_at?: string;
}

export interface PatchedInventory {
  /** @format date-time */
  readonly created_at?: string;
  readonly id?: number;
  /** @maxLength 127 */
  name?: string;
  readonly organization?: number;
  /** @format date-time */
  readonly updated_at?: string;
}

export interface PatchedInventoryAsset {
  /** @format date-time */
  readonly created_at?: string;
  readonly id?: number;
  inventory?: number | null;
  /** @maxLength 62 */
  number?: string;
  readonly organization?: number;
  /** @format date-time */
  readonly updated_at?: string;
}

export interface PatchedOrganization {
  /** @format date-time */
  readonly created_at?: string;
  /** @maxLength 255 */
  full_name?: string;
  readonly id?: number;
  readonly owner?: number;
  readonly settings?: OrganizationSettings;
  /** @maxLength 127 */
  short_name?: string;
  /** @format date-time */
  readonly updated_at?: string;
}

export interface PatchedOrganizationSettings {
  /** @format date-time */
  readonly created_at?: string;
  /** @format time */
  daily_notification_hour?: string | null;
  deadline_notification_delay?: string | null;
  readonly organization?: number;
  start_notification_delay?: string | null;
  time_zone?: string;
  /** @format date-time */
  readonly updated_at?: string;
}

export interface PatchedPhoto {
  /** @format date-time */
  readonly created_at?: string;
  readonly id?: number;
  /** @maxLength 127 */
  name?: string;
  readonly owner?: UserName;
  /**
   * Фото
   * @format uri
   */
  photo?: string;
  readonly task?: number;
  /** @format date-time */
  readonly updated_at?: string;
}

export interface PatchedPosition {
  /** @format date-time */
  readonly created_at?: string;
  readonly id?: number;
  /** @maxLength 127 */
  name?: string;
  readonly organization?: number;
  /** @format date-time */
  readonly updated_at?: string;
}

export interface PatchedResultUpdate {
  /** @format double */
  area?: number;
  /** @default "no" */
  area_measure?: TaskResultAreaMeasure;
  /** @format double */
  count?: number;
  /** @default "no" */
  count_measure?: TaskResultCountMeasure;
  /** @format date-time */
  readonly created_at?: string;
  readonly id?: number;
  /** @format double */
  length?: number;
  /** @default "no" */
  length_measure?: TaskResultLengthMeasure;
  readonly owner?: UserName;
  /**
   * Фото
   * @format uri
   */
  photo?: string;
  readonly task?: number;
  /** @format date-time */
  readonly updated_at?: string;
  /** @format double */
  volume?: number;
  /** @default "no" */
  volume_measure?: TaskResultVolumeMeasure;
  /** @format double */
  weight?: number;
  /** @default "no" */
  weight_measure?: TaskResultWeightMeasure;
}

export interface PatchedTaskCreate {
  /** @format date-time */
  completed_at?: string | null;
  /** @format date-time */
  readonly created_at?: string;
  readonly creator?: number | null;
  /** @format date-time */
  deadline?: string | null;
  readonly department?: number;
  description?: string;
  /**
   * @format int64
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  elapsed_seconds?: number;
  equipment?: number | null;
  equipment_asset?: number | null;
  executors?: number[];
  readonly id?: number;
  inventory?: number | null;
  inventory_asset?: number | null;
  /** @maxLength 127 */
  name?: string;
  /** @format date-time */
  on_the_way_at?: string | null;
  /** @format date-time */
  planned_start_at?: string | null;
  /**
   * @min 0
   * @max 32767
   */
  priority?: TaskPriority;
  /**
   * @format int64
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  required_seconds?: number;
  /** @format double */
  start_latitude?: number;
  /** @format double */
  start_longitude?: number;
  /** @format double */
  start_position_x?: number;
  /** @format double */
  start_position_y?: number;
  /** @format date-time */
  started_at?: string | null;
  /**
   * @min 0
   * @max 32767
   */
  status?: TaskStatus;
  task_subtype?: number | null;
  task_type?: number | null;
  /** @format date-time */
  readonly updated_at?: string;
  vehicle?: number | null;
  vehicle_asset?: number | null;
}

export interface PatchedTaskSubType {
  /** @format date-time */
  readonly created_at?: string;
  readonly id?: number;
  /** @maxLength 127 */
  name?: string;
  readonly organization?: number;
  task_type?: number;
  /** @format date-time */
  readonly updated_at?: string;
}

export interface PatchedTaskType {
  /** @format date-time */
  readonly created_at?: string;
  readonly id?: number;
  /** @maxLength 127 */
  name?: string;
  readonly organization?: number;
  /** @format date-time */
  readonly updated_at?: string;
}

export interface PatchedUser {
  /** @format date-time */
  readonly date_joined?: string;
  /**
   * Email address
   * @format email
   */
  readonly email?: string;
  /** @format date */
  eula_accepted_from?: string | null;
  /** @maxLength 150 */
  first_name?: string;
  readonly groups?: UserGroups[];
  readonly id?: number;
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  readonly is_active?: boolean;
  /**
   * Staff status
   * Designates whether the user can log into this admin site.
   */
  readonly is_staff?: boolean;
  /**
   * Superuser status
   * Designates that this user has all permissions without explicitly assigning them.
   */
  readonly is_superuser?: boolean;
  /** @format date-time */
  readonly last_login?: string | null;
  /** @maxLength 150 */
  last_name?: string;
  /** @maxLength 150 */
  middle_name?: string;
  readonly phone_number?: string | null;
}

export interface PatchedUserPositionUpdate {
  /** @format date */
  born_date?: string | null;
  /** @format date-time */
  readonly created_at?: string;
  readonly department?: ShortDepartment;
  /**
   * @format email
   * @maxLength 254
   */
  email?: string;
  /** @format date */
  employment_date?: string | null;
  /** @maxLength 150 */
  first_name?: string;
  readonly id?: number;
  /** @maxLength 150 */
  last_name?: string;
  /** @maxLength 150 */
  middle_name?: string;
  /** @maxLength 128 */
  phone_number?: string;
  position?: number | null;
  readonly rating?: UserPositionRating;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  salary?: number;
  /** @maxLength 62 */
  specialization?: string;
  /**
   * @min 0
   * @max 32767
   */
  status?: UserStatus;
  readonly status_name?: string;
  /** @format date-time */
  readonly updated_at?: string;
  readonly user?: UserExtend;
  work_experience?: number;
}

export interface PatchedVehicle {
  /** @format date-time */
  readonly created_at?: string;
  readonly id?: number;
  /** @maxLength 127 */
  name?: string;
  readonly organization?: number;
  /** @format date-time */
  readonly updated_at?: string;
}

export interface PatchedVehicleAsset {
  /** @format date-time */
  readonly created_at?: string;
  /** @maxLength 15 */
  gos_number?: string;
  readonly id?: number;
  /** @maxLength 62 */
  number?: string;
  readonly organization?: number;
  /** @format date-time */
  readonly updated_at?: string;
  vehicle?: number | null;
  /** @maxLength 30 */
  vin_number?: string;
}

export interface Photo {
  /** @format date-time */
  readonly created_at: string;
  readonly id: number;
  /** @maxLength 127 */
  name: string;
  readonly owner: UserName;
  /**
   * Фото
   * @format uri
   */
  photo?: string;
  readonly task: number;
  /** @format date-time */
  readonly updated_at: string;
}

export interface Position {
  /** @format date-time */
  readonly created_at: string;
  readonly id: number;
  /** @maxLength 127 */
  name: string;
  readonly organization: number;
  /** @format date-time */
  readonly updated_at: string;
}

export interface Report {
  /** @format date-time */
  readonly created_at: string;
  /**
   * Файл
   * @format uri
   */
  readonly file: string;
  readonly id: number;
  /** @maxLength 127 */
  name: string;
  readonly organization: number;
  readonly owner?: number | null;
  /** @format uuid */
  readonly process?: string | null;
  /** @format date-time */
  readonly updated_at: string;
}

export interface ReportCreate {
  /** @format date-time */
  readonly created_at: string;
  /**
   * Файл
   * @format uri
   */
  readonly file: string;
  readonly id: number;
  /** @maxLength 127 */
  name: string;
  readonly organization: number;
  readonly owner?: number | null;
  /** @format uuid */
  readonly process?: string | null;
  report_filter: Record<string, any>;
  /** @format date-time */
  readonly updated_at: string;
}

export interface Result {
  /** @format double */
  area?: number;
  /** @default "no" */
  area_measure?: TaskResultAreaMeasure;
  /** @format double */
  count?: number;
  /** @default "no" */
  count_measure?: TaskResultCountMeasure;
  /** @format date-time */
  readonly created_at: string;
  readonly id: number;
  /** @format double */
  length?: number;
  /** @default "no" */
  length_measure?: TaskResultLengthMeasure;
  readonly owner: UserName;
  /**
   * Фото
   * @format uri
   */
  photo?: string;
  readonly task: number;
  /** @format date-time */
  readonly updated_at: string;
  /** @format double */
  volume?: number;
  /** @default "no" */
  volume_measure?: TaskResultVolumeMeasure;
  /** @format double */
  weight?: number;
  /** @default "no" */
  weight_measure?: TaskResultWeightMeasure;
}

export interface ResultUpdate {
  /** @format double */
  area?: number;
  /** @default "no" */
  area_measure?: TaskResultAreaMeasure;
  /** @format double */
  count?: number;
  /** @default "no" */
  count_measure?: TaskResultCountMeasure;
  /** @format date-time */
  readonly created_at: string;
  readonly id: number;
  /** @format double */
  length?: number;
  /** @default "no" */
  length_measure?: TaskResultLengthMeasure;
  readonly owner: UserName;
  /**
   * Фото
   * @format uri
   */
  photo?: string;
  readonly task: number;
  /** @format date-time */
  readonly updated_at: string;
  /** @format double */
  volume?: number;
  /** @default "no" */
  volume_measure?: TaskResultVolumeMeasure;
  /** @format double */
  weight?: number;
  /** @default "no" */
  weight_measure?: TaskResultWeightMeasure;
}

export interface SendEmailResetSerializerV2 {
  /** @format email */
  email: string;
  readonly uid: string;
}

export interface ShortDepartment {
  readonly id: number;
  readonly name: string;
  readonly organization: number;
}

export enum UserStatus {
  Value0 = 0,
  Value100 = 100,
  Value200 = 200,
  Value300 = 300,
  Value400 = 400,
  Value500 = 500,
}

export interface SystemMessage {
  /**
   * @min 0
   * @max 32767
   */
  code: UserStatus;
  /** @format date-time */
  readonly created_at: string;
  readonly department?: number | null;
  /**
   * Uuid
   * @format uuid
   */
  id?: string;
  name: NameEnum;
  readonly organization: number;
}

export interface Task {
  /** @format date-time */
  completed_at?: string | null;
  /** @format date-time */
  readonly created_at: string;
  creator: UserName;
  /** @format date-time */
  deadline?: string | null;
  readonly department: number;
  description?: string;
  /**
   * @format int64
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  elapsed_seconds?: number;
  equipment: EquipmentName;
  equipment_asset: EquipmentAssetNumber;
  executors: UserName[];
  readonly id: number;
  inventory: InventoryName;
  inventory_asset: InventoryAssetNumber;
  /** @maxLength 127 */
  name: string;
  /** @format date-time */
  on_the_way_at?: string | null;
  /** @format date-time */
  planned_start_at?: string | null;
  /**
   * @min 0
   * @max 32767
   */
  priority?: TaskPriority;
  /**
   * @format int64
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  required_seconds?: number;
  /** @format double */
  start_latitude?: number;
  /** @format double */
  start_longitude?: number;
  /** @format double */
  start_position_x?: number;
  /** @format double */
  start_position_y?: number;
  /** @format date-time */
  started_at?: string | null;
  /**
   * @min 0
   * @max 32767
   */
  status?: TaskStatus;
  readonly status_name: string;
  task_subtype: TaskSubTypeName;
  task_type: TaskTypeName;
  /** @format date-time */
  readonly updated_at: string;
  vehicle: VehicleName;
  vehicle_asset: VehicleAssetNumber;
}

export interface TaskCreate {
  /** @format date-time */
  completed_at?: string | null;
  /** @format date-time */
  readonly created_at: string;
  readonly creator?: number | null;
  /** @format date-time */
  deadline?: string | null;
  readonly department: number;
  description?: string;
  /**
   * @format int64
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  elapsed_seconds?: number;
  equipment?: number | null;
  equipment_asset?: number | null;
  executors?: number[];
  readonly id: number;
  inventory?: number | null;
  inventory_asset?: number | null;
  /** @maxLength 127 */
  name: string;
  /** @format date-time */
  on_the_way_at?: string | null;
  /** @format date-time */
  planned_start_at?: string | null;
  /**
   * @min 0
   * @max 32767
   */
  priority?: TaskPriority;
  /**
   * @format int64
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  required_seconds?: number;
  /** @format double */
  start_latitude?: number;
  /** @format double */
  start_longitude?: number;
  /** @format double */
  start_position_x?: number;
  /** @format double */
  start_position_y?: number;
  /** @format date-time */
  started_at?: string | null;
  /**
   * @min 0
   * @max 32767
   */
  status?: TaskStatus;
  task_subtype?: number | null;
  task_type?: number | null;
  /** @format date-time */
  readonly updated_at: string;
  vehicle?: number | null;
  vehicle_asset?: number | null;
}

export interface TaskDetailed {
  /** @format date-time */
  completed_at?: string | null;
  /** @format date-time */
  readonly created_at: string;
  creator: UserExtend;
  /** @format date-time */
  deadline?: string | null;
  department: DepartmentShort;
  description?: string;
  /**
   * @format int64
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  elapsed_seconds?: number;
  equipment: Equipment;
  equipment_asset: EquipmentAsset;
  executors: UserExtend[];
  readonly id: number;
  inventory: Inventory;
  inventory_asset: InventoryAsset;
  /** @maxLength 127 */
  name: string;
  /** @format date-time */
  on_the_way_at?: string | null;
  /** @format date-time */
  planned_start_at?: string | null;
  /**
   * @min 0
   * @max 32767
   */
  priority?: TaskPriority;
  /**
   * @format int64
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  required_seconds?: number;
  /** @format double */
  start_latitude?: number;
  /** @format double */
  start_longitude?: number;
  /** @format double */
  start_position_x?: number;
  /** @format double */
  start_position_y?: number;
  /** @format date-time */
  started_at?: string | null;
  /**
   * @min 0
   * @max 32767
   */
  status?: TaskStatus;
  readonly status_name: string;
  task_subtype: TaskSubType;
  task_type: TaskType;
  /** @format date-time */
  readonly updated_at: string;
  vehicle: Vehicle;
  vehicle_asset: VehicleAsset;
}

export interface TaskHistory {
  /** @format date-time */
  deadline?: string | null;
  description?: string;
  equipment?: number | null;
  equipment_asset?: number | null;
  /** @maxLength 100 */
  history_change_reason?: string | null;
  /** @format date-time */
  history_date: string;
  /** @format uuid */
  readonly history_id: string;
  history_type: HistoryTypeEnum;
  history_user?: number | null;
  /**
   * @format int64
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  id?: number;
  inventory?: number | null;
  inventory_asset?: number | null;
  /** @maxLength 127 */
  name: string;
  /** @format date-time */
  planned_start_at?: string | null;
  /**
   * @min 0
   * @max 32767
   */
  priority?: TaskPriority;
  /** @format double */
  start_latitude?: number;
  /** @format double */
  start_longitude?: number;
  task_subtype?: number | null;
  task_type?: number | null;
  vehicle?: number | null;
  vehicle_asset?: number | null;
}

export interface TaskPoint {
  checks: CheckPoint[];
  comments: CommentPoint[];
  /** @format date-time */
  deadline?: string | null;
  department: number;
  readonly id: number;
  /** @maxLength 127 */
  name: string;
  /**
   * @min 0
   * @max 32767
   */
  priority?: TaskPriority;
  /** @format double */
  start_latitude?: number;
  /** @format double */
  start_longitude?: number;
  /** @format double */
  start_position_x?: number;
  /** @format double */
  start_position_y?: number;
  /**
   * @min 0
   * @max 32767
   */
  status?: TaskStatus;
}

export enum TaskPriority {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
}

export enum TaskResultAreaMeasure {
  No = "no",
  SquareMeter = "square_meter",
  SquareKilometer = "square_kilometer",
  Hundred = "hundred",
  Hectare = "hectare",
}

export enum TaskResultCountMeasure {
  No = "no",
  Pieces = "pieces",
}

export enum TaskResultLengthMeasure {
  No = "no",
  Meter = "meter",
  Kilometer = "kilometer",
}

export enum TaskResultVolumeMeasure {
  No = "no",
  Liters = "liters",
  CubicMeter = "cubic_meter",
}

export enum TaskResultWeightMeasure {
  No = "no",
  Kilogram = "kilogram",
  Ton = "ton",
  Hundred = "hundred",
}

export enum TaskStatus {
  Value0 = 0,
  Value100 = 100,
  Value101 = 101,
  Value110 = 110,
  Value200 = 200,
  Value220 = 220,
  Value300 = 300,
  Value400 = 400,
}

export interface TaskSubType {
  /** @format date-time */
  readonly created_at: string;
  readonly id: number;
  /** @maxLength 127 */
  name: string;
  readonly organization: number;
  task_type: number;
  /** @format date-time */
  readonly updated_at: string;
}

export interface TaskSubTypeName {
  readonly id: number;
  /** @maxLength 127 */
  name: string;
}

export interface TaskTimeRecommendation {
  /** @format double */
  readonly cost: number;
  /** @format date-time */
  deadline?: string;
  /**
   * @format double
   * @default 0
   */
  latitude?: number;
  /**
   * @format double
   * @default 0
   */
  longitude?: number;
  /** @format date-time */
  planned_start_at?: string;
  task_type: number;
  user: number;
  /** @format double */
  readonly value: number;
}

export interface TaskTimeRecommendationResult {
  /** @format double */
  rating?: number | null;
  user: UserName;
  /** @format double */
  value: number;
}

export interface TaskType {
  /** @format date-time */
  readonly created_at: string;
  readonly id: number;
  /** @maxLength 127 */
  name: string;
  readonly organization: number;
  /** @format date-time */
  readonly updated_at: string;
}

export interface TaskTypeName {
  readonly id: number;
  /** @maxLength 127 */
  name: string;
}

export interface TokenCreateSerializerV2 {
  readonly auth_token: string;
  email?: string;
  password?: string;
}

export interface TokenObtainPair {
  readonly access: string;
  email: string;
  password: string;
  readonly refresh: string;
}

export interface TokenRefresh {
  readonly access: string;
  refresh: string;
}

export interface TokenVerify {
  token: string;
}

export interface User {
  /** @format date-time */
  readonly date_joined: string;
  /**
   * Email address
   * @format email
   */
  readonly email: string;
  /** @format date */
  eula_accepted_from?: string | null;
  /** @maxLength 150 */
  first_name?: string;
  readonly groups: UserGroups[];
  readonly id: number;
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  readonly is_active: boolean;
  /**
   * Staff status
   * Designates whether the user can log into this admin site.
   */
  readonly is_staff: boolean;
  /**
   * Superuser status
   * Designates that this user has all permissions without explicitly assigning them.
   */
  readonly is_superuser: boolean;
  /** @format date-time */
  readonly last_login?: string | null;
  /** @maxLength 150 */
  last_name?: string;
  /** @maxLength 150 */
  middle_name?: string;
  readonly phone_number?: string | null;
}

export interface UserCreateSerializerV2 {
  /** @format date */
  born_date?: string | null;
  department: number;
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email: string;
  /** @format date */
  employment_date?: string | null;
  /** @maxLength 150 */
  first_name?: string;
  group_name: GroupNameEnum;
  /** @maxLength 150 */
  last_name?: string;
  /** @maxLength 150 */
  middle_name?: string;
  organization: number;
  /** @maxLength 128 */
  phone_number?: string | null;
  position?: number;
  /** @default 0 */
  salary?: number;
}

export interface UserExtend {
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email: string;
  /** @maxLength 150 */
  first_name?: string;
  readonly groups: UserGroupsShort[];
  readonly id: number;
  /** @maxLength 150 */
  last_name?: string;
  /** @maxLength 150 */
  middle_name?: string;
  /** @maxLength 128 */
  phone_number?: string | null;
}

export interface UserGeolocation {
  /** @format date-time */
  readonly created_at: string;
  readonly id: number;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
  /** @format double */
  position_x?: number;
  /** @format double */
  position_y?: number;
  readonly user: number;
}

export interface UserGeolocationList {
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email: string;
  /** @maxLength 150 */
  first_name?: string;
  readonly geolocation: UserGeolocation[];
  readonly id: number;
  /** @maxLength 150 */
  last_name?: string;
  /** @maxLength 150 */
  middle_name?: string;
  /** @maxLength 128 */
  phone_number?: string | null;
}

export interface UserGroups {
  readonly id: number;
  /** @maxLength 150 */
  name: string;
  readonly permissions: UserPermissions[];
}

export interface UserGroupsShort {
  readonly id: number;
  /** @maxLength 150 */
  name: string;
}

export interface UserName {
  /** @maxLength 150 */
  first_name?: string;
  readonly id: number;
  /** @maxLength 150 */
  last_name?: string;
  /** @maxLength 150 */
  middle_name?: string;
}

export interface UserPermissions {
  /** @maxLength 100 */
  codename: string;
  /** @maxLength 255 */
  name: string;
}

export interface UserPosition {
  /** @format date */
  born_date?: string | null;
  /** @format date-time */
  readonly created_at: string;
  readonly department: ShortDepartment;
  /** @format date */
  employment_date?: string | null;
  readonly id: number;
  position?: number | null;
  readonly rating: UserPositionRating;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  salary?: number;
  /** @maxLength 62 */
  specialization?: string;
  /**
   * @min 0
   * @max 32767
   */
  status?: UserStatus;
  readonly status_name: string;
  /** @format date-time */
  readonly updated_at: string;
  readonly user: UserExtend;
  work_experience: number;
}

export interface UserPositionGeolocation {
  /** @format date */
  born_date?: string | null;
  /** @format date-time */
  readonly created_at: string;
  readonly department: ShortDepartment;
  /** @format date */
  employment_date?: string | null;
  readonly id: number;
  position?: number | null;
  readonly rating: UserPositionRating;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  salary?: number;
  /** @maxLength 62 */
  specialization?: string;
  /**
   * @min 0
   * @max 32767
   */
  status?: UserStatus;
  readonly status_name: string;
  /** @format date-time */
  readonly updated_at: string;
  readonly user: UserGeolocationList;
  work_experience: number;
}

export interface UserPositionRating {
  /** @format date-time */
  readonly updated_at: string;
  /** @format double */
  value?: number;
}

export interface UserPositionUpdate {
  /** @format date */
  born_date?: string | null;
  /** @format date-time */
  readonly created_at: string;
  readonly department: ShortDepartment;
  /**
   * @format email
   * @maxLength 254
   */
  email: string;
  /** @format date */
  employment_date?: string | null;
  /** @maxLength 150 */
  first_name: string;
  readonly id: number;
  /** @maxLength 150 */
  last_name: string;
  /** @maxLength 150 */
  middle_name: string;
  /** @maxLength 128 */
  phone_number: string;
  position?: number | null;
  readonly rating: UserPositionRating;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  salary?: number;
  /** @maxLength 62 */
  specialization?: string;
  /**
   * @min 0
   * @max 32767
   */
  status?: UserStatus;
  readonly status_name: string;
  /** @format date-time */
  readonly updated_at: string;
  readonly user: UserExtend;
  work_experience: number;
}

export interface UsernameResetConfirmSerializerV2 {
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  new_email: string;
}

export interface Vehicle {
  /** @format date-time */
  readonly created_at: string;
  readonly id: number;
  /** @maxLength 127 */
  name: string;
  readonly organization: number;
  /** @format date-time */
  readonly updated_at: string;
}

export interface VehicleAsset {
  /** @format date-time */
  readonly created_at: string;
  /** @maxLength 15 */
  gos_number: string;
  readonly id: number;
  /** @maxLength 62 */
  number: string;
  readonly organization: number;
  /** @format date-time */
  readonly updated_at: string;
  vehicle?: number | null;
  /** @maxLength 30 */
  vin_number: string;
}

export interface VehicleAssetNumber {
  /** @maxLength 15 */
  gos_number: string;
  readonly id: number;
  /** @maxLength 62 */
  number: string;
}

export interface VehicleName {
  readonly id: number;
  /** @maxLength 127 */
  name: string;
}

export interface WorkerRecommendation {
  /** @format date-time */
  planned_start_at?: string;
  readonly result: TaskTimeRecommendationResult[];
  task_type: number;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title HumanAgro
 * @version 0.0.5 (v1)
 *
 * Автодокументация
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags account
     * @name AccountDeviceList
     * @request GET:/api/v1/account/device/
     * @secure
     */
    accountDeviceList: (
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedDeviceList, any>({
        path: `/api/v1/account/device/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags account
     * @name AccountDeviceCreate
     * @request POST:/api/v1/account/device/
     * @secure
     */
    accountDeviceCreate: (body: Device, params: RequestParams = {}) =>
      this.request<Device, any>({
        path: `/api/v1/account/device/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags account
     * @name AccountDeviceDestroy
     * @request DELETE:/api/v1/account/device/{id}/
     * @secure
     */
    accountDeviceDestroy: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/account/device/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags account
     * @name AccountDeviceRetrieve
     * @request GET:/api/v1/account/device/{id}/
     * @secure
     */
    accountDeviceRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<Device, any>({
        path: `/api/v1/account/device/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags account
     * @name AccountGeolocationList
     * @request GET:/api/v1/account/geolocation/
     * @secure
     */
    accountGeolocationList: (
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        user?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedUserGeolocationList, any>({
        path: `/api/v1/account/geolocation/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags account
     * @name AccountGeolocationCreate
     * @request POST:/api/v1/account/geolocation/
     * @secure
     */
    accountGeolocationCreate: (body: UserGeolocation, params: RequestParams = {}) =>
      this.request<UserGeolocation, any>({
        path: `/api/v1/account/geolocation/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags account
     * @name AccountGeolocationRetrieve
     * @request GET:/api/v1/account/geolocation/{id}/
     * @secure
     */
    accountGeolocationRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<UserGeolocation, any>({
        path: `/api/v1/account/geolocation/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags account
     * @name AccountMeRetrieve
     * @request GET:/api/v1/account/me/
     * @secure
     */
    accountMeRetrieve: (params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api/v1/account/me/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags account
     * @name AccountMePartialUpdate
     * @request PATCH:/api/v1/account/me/
     * @secure
     */
    accountMePartialUpdate: (body: PatchedUser, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api/v1/account/me/`,
        method: "PATCH",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags account
     * @name AccountMeUpdate
     * @request PUT:/api/v1/account/me/
     * @secure
     */
    accountMeUpdate: (body: User, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api/v1/account/me/`,
        method: "PUT",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags account
     * @name AccountNotificationList
     * @request GET:/api/v1/account/notification/
     * @secure
     */
    accountNotificationList: (
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        title?: string;
        user?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedNotificationList, any>({
        path: `/api/v1/account/notification/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags account
     * @name AccountNotificationRetrieve
     * @request GET:/api/v1/account/notification/{id}/
     * @secure
     */
    accountNotificationRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<Notification, any>({
        path: `/api/v1/account/notification/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytics
     * @name AnalyticsOrgDashboardList
     * @request GET:/api/v1/analytics/org/{org_id}/dashboard/
     * @secure
     */
    analyticsOrgDashboardList: (
      orgId: string,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** Ordering */
        o?: (
          | "-id"
          | "-organization"
          | "-period_end"
          | "-updated_at"
          | "id"
          | "organization"
          | "period_end"
          | "updated_at"
        )[];
        /** The initial index from which to return the results. */
        offset?: number;
        organization?: number;
        /** @format date */
        period_end_after?: string;
        /** @format date */
        period_end_before?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedOrganizationDashboardList, any>({
        path: `/api/v1/analytics/org/${orgId}/dashboard/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytics
     * @name AnalyticsOrgDashboardCreate
     * @request POST:/api/v1/analytics/org/{org_id}/dashboard/
     * @secure
     */
    analyticsOrgDashboardCreate: (orgId: string, body: CreateOrganizationDashboard, params: RequestParams = {}) =>
      this.request<CreateOrganizationDashboard, any>({
        path: `/api/v1/analytics/org/${orgId}/dashboard/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytics
     * @name AnalyticsOrgDashboardRetrieve
     * @request GET:/api/v1/analytics/org/{org_id}/dashboard/{id}/
     * @secure
     */
    analyticsOrgDashboardRetrieve: (id: number, orgId: string, params: RequestParams = {}) =>
      this.request<OrganizationDashboard, any>({
        path: `/api/v1/analytics/org/${orgId}/dashboard/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytics
     * @name AnalyticsOrgDepBestTimeCreate
     * @request POST:/api/v1/analytics/org/{org_id}/dep/{dep_id}/best/time/
     * @secure
     */
    analyticsOrgDepBestTimeCreate: (
      depId: string,
      orgId: string,
      body: TaskTimeRecommendation,
      params: RequestParams = {},
    ) =>
      this.request<TaskTimeRecommendation, any>({
        path: `/api/v1/analytics/org/${orgId}/dep/${depId}/best/time/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytics
     * @name AnalyticsOrgDepBestWorkerCreate
     * @request POST:/api/v1/analytics/org/{org_id}/dep/{dep_id}/best/worker/
     * @secure
     */
    analyticsOrgDepBestWorkerCreate: (
      depId: string,
      orgId: string,
      body: WorkerRecommendation,
      params: RequestParams = {},
    ) =>
      this.request<WorkerRecommendation, any>({
        path: `/api/v1/analytics/org/${orgId}/dep/${depId}/best/worker/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytics
     * @name AnalyticsOrgDepDashboardList
     * @request GET:/api/v1/analytics/org/{org_id}/dep/{dep_id}/dashboard/
     * @secure
     */
    analyticsOrgDepDashboardList: (
      depId: string,
      orgId: string,
      query?: {
        department?: number;
        /** Number of results to return per page. */
        limit?: number;
        /** Ordering */
        o?: (
          | "-department"
          | "-id"
          | "-period_end"
          | "-updated_at"
          | "department"
          | "id"
          | "period_end"
          | "updated_at"
        )[];
        /** The initial index from which to return the results. */
        offset?: number;
        /** @format date */
        period_end_after?: string;
        /** @format date */
        period_end_before?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedDashboardList, any>({
        path: `/api/v1/analytics/org/${orgId}/dep/${depId}/dashboard/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytics
     * @name AnalyticsOrgDepDashboardCreate
     * @request POST:/api/v1/analytics/org/{org_id}/dep/{dep_id}/dashboard/
     * @secure
     */
    analyticsOrgDepDashboardCreate: (depId: string, orgId: string, body: CreateDashboard, params: RequestParams = {}) =>
      this.request<CreateDashboard, any>({
        path: `/api/v1/analytics/org/${orgId}/dep/${depId}/dashboard/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytics
     * @name AnalyticsOrgDepDashboardRetrieve
     * @request GET:/api/v1/analytics/org/{org_id}/dep/{dep_id}/dashboard/{id}/
     * @secure
     */
    analyticsOrgDepDashboardRetrieve: (depId: string, id: number, orgId: string, params: RequestParams = {}) =>
      this.request<Dashboard, any>({
        path: `/api/v1/analytics/org/${orgId}/dep/${depId}/dashboard/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
     *
     * @tags auth
     * @name AuthJwtCreateCreate
     * @request POST:/api/v1/auth/jwt/create/
     */
    authJwtCreateCreate: (body: TokenObtainPair, params: RequestParams = {}) =>
      this.request<TokenObtainPair, any>({
        path: `/api/v1/auth/jwt/create/`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     *
     * @tags auth
     * @name AuthJwtRefreshCreate
     * @request POST:/api/v1/auth/jwt/refresh/
     */
    authJwtRefreshCreate: (body: TokenRefresh, params: RequestParams = {}) =>
      this.request<TokenRefresh, any>({
        path: `/api/v1/auth/jwt/refresh/`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Takes a token and indicates if it is valid.  This view provides no information about a token's fitness for a particular use.
     *
     * @tags auth
     * @name AuthJwtVerifyCreate
     * @request POST:/api/v1/auth/jwt/verify/
     */
    authJwtVerifyCreate: (body: TokenVerify, params: RequestParams = {}) =>
      this.request<TokenVerify, any>({
        path: `/api/v1/auth/jwt/verify/`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Use this endpoint to obtain user authentication token.
     *
     * @tags auth
     * @name AuthTokenLoginCreate
     * @request POST:/api/v1/auth/token/login/
     * @secure
     */
    authTokenLoginCreate: (body: TokenCreateSerializerV2, params: RequestParams = {}) =>
      this.request<TokenCreateSerializerV2, any>({
        path: `/api/v1/auth/token/login/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Use this endpoint to logout user (remove user authentication token).
     *
     * @tags auth
     * @name AuthTokenLogoutCreate
     * @request POST:/api/v1/auth/token/logout/
     * @secure
     */
    authTokenLogoutCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/auth/token/logout/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthUsersCreate
     * @request POST:/api/v1/auth/users/
     * @secure
     */
    authUsersCreate: (body: UserCreateSerializerV2, params: RequestParams = {}) =>
      this.request<UserCreateSerializerV2, any>({
        path: `/api/v1/auth/users/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthUsersActivationCreate
     * @request POST:/api/v1/auth/users/activation/
     * @secure
     */
    authUsersActivationCreate: (body: ActivationSerializerV2, params: RequestParams = {}) =>
      this.request<ActivationSerializerV2, any>({
        path: `/api/v1/auth/users/activation/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthUsersResendActivationCreate
     * @request POST:/api/v1/auth/users/resend_activation/
     * @secure
     */
    authUsersResendActivationCreate: (body: SendEmailResetSerializerV2, params: RequestParams = {}) =>
      this.request<SendEmailResetSerializerV2, any>({
        path: `/api/v1/auth/users/resend_activation/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthUsersResetEmailCreate
     * @request POST:/api/v1/auth/users/reset_email/
     * @secure
     */
    authUsersResetEmailCreate: (body: SendEmailResetSerializerV2, params: RequestParams = {}) =>
      this.request<SendEmailResetSerializerV2, any>({
        path: `/api/v1/auth/users/reset_email/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthUsersResetEmailConfirmCreate
     * @request POST:/api/v1/auth/users/reset_email_confirm/
     * @secure
     */
    authUsersResetEmailConfirmCreate: (body: UsernameResetConfirmSerializerV2, params: RequestParams = {}) =>
      this.request<UsernameResetConfirmSerializerV2, any>({
        path: `/api/v1/auth/users/reset_email_confirm/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthUsersResetPasswordCreate
     * @request POST:/api/v1/auth/users/reset_password/
     * @secure
     */
    authUsersResetPasswordCreate: (body: SendEmailResetSerializerV2, params: RequestParams = {}) =>
      this.request<SendEmailResetSerializerV2, any>({
        path: `/api/v1/auth/users/reset_password/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthUsersResetPasswordConfirmCreate
     * @request POST:/api/v1/auth/users/reset_password_confirm/
     * @secure
     */
    authUsersResetPasswordConfirmCreate: (body: PasswordResetConfirmSerializerV2, params: RequestParams = {}) =>
      this.request<PasswordResetConfirmSerializerV2, any>({
        path: `/api/v1/auth/users/reset_password_confirm/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthUsersDestroy
     * @request DELETE:/api/v1/auth/users/{id}/
     * @secure
     */
    authUsersDestroy: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/auth/users/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthUsersRetrieve
     * @request GET:/api/v1/auth/users/{id}/
     * @secure
     */
    authUsersRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api/v1/auth/users/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthUsersPartialUpdate
     * @request PATCH:/api/v1/auth/users/{id}/
     * @secure
     */
    authUsersPartialUpdate: (id: number, body: PatchedUser, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api/v1/auth/users/${id}/`,
        method: "PATCH",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthUsersUpdate
     * @request PUT:/api/v1/auth/users/{id}/
     * @secure
     */
    authUsersUpdate: (id: number, body: User, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api/v1/auth/users/${id}/`,
        method: "PUT",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags export
     * @name ExportOrgReportsList
     * @request GET:/api/v1/export/org/{org_id}/reports/
     * @secure
     */
    exportOrgReportsList: (
      orgId: string,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        name?: string;
        /** The initial index from which to return the results. */
        offset?: number;
        organization?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedReportList, any>({
        path: `/api/v1/export/org/${orgId}/reports/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags export
     * @name ExportOrgReportsCreate
     * @request POST:/api/v1/export/org/{org_id}/reports/
     * @secure
     */
    exportOrgReportsCreate: (orgId: string, body: ReportCreate, params: RequestParams = {}) =>
      this.request<ReportCreate, any>({
        path: `/api/v1/export/org/${orgId}/reports/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags export
     * @name ExportOrgReportsDestroy
     * @request DELETE:/api/v1/export/org/{org_id}/reports/{id}/
     * @secure
     */
    exportOrgReportsDestroy: (id: number, orgId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/export/org/${orgId}/reports/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags export
     * @name ExportOrgReportsRetrieve
     * @request GET:/api/v1/export/org/{org_id}/reports/{id}/
     * @secure
     */
    exportOrgReportsRetrieve: (id: number, orgId: string, params: RequestParams = {}) =>
      this.request<Report, any>({
        path: `/api/v1/export/org/${orgId}/reports/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationEnumsUserStatusList
     * @request GET:/api/v1/organization/enums/user-status/
     * @secure
     */
    organizationEnumsUserStatusList: (params: RequestParams = {}) =>
      this.request<Enum[], any>({
        path: `/api/v1/organization/enums/user-status/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationEnumsUserStatusRetrieve
     * @request GET:/api/v1/organization/enums/user-status/{id}/
     * @secure
     */
    organizationEnumsUserStatusRetrieve: (id: string, params: RequestParams = {}) =>
      this.request<Enum, any>({
        path: `/api/v1/organization/enums/user-status/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationMyUserPositionsList
     * @request GET:/api/v1/organization/my-user-positions/
     * @secure
     */
    organizationMyUserPositionsList: (
      query?: {
        department?: number;
        email?: string;
        first_name?: string;
        group_name?: ("Manager" | "Specialist" | "Worker" | "manager" | "specialist" | "worker")[];
        last_name?: string;
        /** Number of results to return per page. */
        limit?: number;
        middle_name?: string;
        name?: string;
        /** Ordering */
        o?: (
          | "-created_at"
          | "-first_name"
          | "-last_name"
          | "-rating"
          | "-status"
          | "-updated_at"
          | "created_at"
          | "first_name"
          | "last_name"
          | "rating"
          | "status"
          | "updated_at"
        )[];
        /** The initial index from which to return the results. */
        offset?: number;
        organization?: number;
        phone_number?: string;
        position?: number;
        status?: (0 | 100 | 200 | 300 | 400 | 500)[];
        user?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedMyUserPositionList, any>({
        path: `/api/v1/organization/my-user-positions/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationMyUserPositionsRetrieve
     * @request GET:/api/v1/organization/my-user-positions/{id}/
     * @secure
     */
    organizationMyUserPositionsRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<MyUserPosition, any>({
        path: `/api/v1/organization/my-user-positions/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgList
     * @request GET:/api/v1/organization/org/
     * @secure
     */
    organizationOrgList: (
      query?: {
        full_name?: string;
        /** Number of results to return per page. */
        limit?: number;
        /** Ordering */
        o?: ("-created_at" | "-full_name" | "-updated_at" | "created_at" | "full_name" | "updated_at")[];
        /** The initial index from which to return the results. */
        offset?: number;
        owner?: number;
        short_name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedOrganizationList, any>({
        path: `/api/v1/organization/org/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgRetrieve
     * @request GET:/api/v1/organization/org/{id}/
     * @secure
     */
    organizationOrgRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<Organization, any>({
        path: `/api/v1/organization/org/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgPartialUpdate
     * @request PATCH:/api/v1/organization/org/{id}/
     * @secure
     */
    organizationOrgPartialUpdate: (id: number, body: PatchedOrganization, params: RequestParams = {}) =>
      this.request<Organization, any>({
        path: `/api/v1/organization/org/${id}/`,
        method: "PATCH",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgSettingsPartialUpdate
     * @request PATCH:/api/v1/organization/org/{id}/settings/
     * @secure
     */
    organizationOrgSettingsPartialUpdate: (id: number, body: PatchedOrganizationSettings, params: RequestParams = {}) =>
      this.request<OrganizationSettings, any>({
        path: `/api/v1/organization/org/${id}/settings/`,
        method: "PATCH",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgDepList
     * @request GET:/api/v1/organization/org/{org_id}/dep/
     * @secure
     */
    organizationOrgDepList: (
      orgId: string,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        name?: string;
        /** Ordering */
        o?: ("-created_at" | "-name" | "-updated_at" | "created_at" | "name" | "updated_at")[];
        /** The initial index from which to return the results. */
        offset?: number;
        organization?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedDepartmentList, any>({
        path: `/api/v1/organization/org/${orgId}/dep/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgDepCreate
     * @request POST:/api/v1/organization/org/{org_id}/dep/
     * @secure
     */
    organizationOrgDepCreate: (orgId: string, body: Department, params: RequestParams = {}) =>
      this.request<Department, any>({
        path: `/api/v1/organization/org/${orgId}/dep/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgDepUserPositionsList
     * @request GET:/api/v1/organization/org/{org_id}/dep/{dep_id}/user-positions/
     * @secure
     */
    organizationOrgDepUserPositionsList: (
      depId: string,
      orgId: string,
      query?: {
        department?: number;
        email?: string;
        first_name?: string;
        group_name?: ("Manager" | "Specialist" | "Worker" | "manager" | "specialist" | "worker")[];
        last_name?: string;
        /** Number of results to return per page. */
        limit?: number;
        middle_name?: string;
        name?: string;
        /** Ordering */
        o?: (
          | "-created_at"
          | "-first_name"
          | "-last_name"
          | "-rating"
          | "-status"
          | "-updated_at"
          | "created_at"
          | "first_name"
          | "last_name"
          | "rating"
          | "status"
          | "updated_at"
        )[];
        /** The initial index from which to return the results. */
        offset?: number;
        organization?: number;
        phone_number?: string;
        position?: number;
        status?: (0 | 100 | 200 | 300 | 400 | 500)[];
        user?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedUserPositionList, any>({
        path: `/api/v1/organization/org/${orgId}/dep/${depId}/user-positions/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgDepUserPositionsGeolocationRetrieve
     * @request GET:/api/v1/organization/org/{org_id}/dep/{dep_id}/user-positions/geolocation/
     * @secure
     */
    organizationOrgDepUserPositionsGeolocationRetrieve: (depId: string, orgId: string, params: RequestParams = {}) =>
      this.request<UserPositionGeolocation, any>({
        path: `/api/v1/organization/org/${orgId}/dep/${depId}/user-positions/geolocation/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgDepUserPositionsRetrieve
     * @request GET:/api/v1/organization/org/{org_id}/dep/{dep_id}/user-positions/{id}/
     * @secure
     */
    organizationOrgDepUserPositionsRetrieve: (depId: string, id: number, orgId: string, params: RequestParams = {}) =>
      this.request<UserPosition, any>({
        path: `/api/v1/organization/org/${orgId}/dep/${depId}/user-positions/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgDepUserPositionsPartialUpdate
     * @request PATCH:/api/v1/organization/org/{org_id}/dep/{dep_id}/user-positions/{id}/
     * @secure
     */
    organizationOrgDepUserPositionsPartialUpdate: (
      depId: string,
      id: number,
      orgId: string,
      body: PatchedUserPositionUpdate,
      params: RequestParams = {},
    ) =>
      this.request<UserPositionUpdate, any>({
        path: `/api/v1/organization/org/${orgId}/dep/${depId}/user-positions/${id}/`,
        method: "PATCH",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgDepDestroy
     * @request DELETE:/api/v1/organization/org/{org_id}/dep/{id}/
     * @secure
     */
    organizationOrgDepDestroy: (id: number, orgId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organization/org/${orgId}/dep/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgDepRetrieve
     * @request GET:/api/v1/organization/org/{org_id}/dep/{id}/
     * @secure
     */
    organizationOrgDepRetrieve: (id: number, orgId: string, params: RequestParams = {}) =>
      this.request<Department, any>({
        path: `/api/v1/organization/org/${orgId}/dep/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgDepPartialUpdate
     * @request PATCH:/api/v1/organization/org/{org_id}/dep/{id}/
     * @secure
     */
    organizationOrgDepPartialUpdate: (id: number, orgId: string, body: PatchedDepartment, params: RequestParams = {}) =>
      this.request<Department, any>({
        path: `/api/v1/organization/org/${orgId}/dep/${id}/`,
        method: "PATCH",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgEquipmentList
     * @request GET:/api/v1/organization/org/{org_id}/equipment/
     * @secure
     */
    organizationOrgEquipmentList: (
      orgId: string,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        name?: string;
        /** Ordering */
        o?: ("-created_at" | "-name" | "-updated_at" | "created_at" | "name" | "updated_at")[];
        /** The initial index from which to return the results. */
        offset?: number;
        organization?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedEquipmentList, any>({
        path: `/api/v1/organization/org/${orgId}/equipment/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgEquipmentCreate
     * @request POST:/api/v1/organization/org/{org_id}/equipment/
     * @secure
     */
    organizationOrgEquipmentCreate: (orgId: string, body: Equipment, params: RequestParams = {}) =>
      this.request<Equipment, any>({
        path: `/api/v1/organization/org/${orgId}/equipment/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgEquipmentAssetsList
     * @request GET:/api/v1/organization/org/{org_id}/equipment/assets/
     * @secure
     */
    organizationOrgEquipmentAssetsList: (
      orgId: string,
      query?: {
        equipment?: number;
        /** Number of results to return per page. */
        limit?: number;
        number?: string;
        /** Ordering */
        o?: ("-created_at" | "-number" | "-updated_at" | "created_at" | "number" | "updated_at")[];
        /** The initial index from which to return the results. */
        offset?: number;
        organization?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedEquipmentAssetList, any>({
        path: `/api/v1/organization/org/${orgId}/equipment/assets/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgEquipmentAssetsCreate
     * @request POST:/api/v1/organization/org/{org_id}/equipment/assets/
     * @secure
     */
    organizationOrgEquipmentAssetsCreate: (orgId: string, body: EquipmentAsset, params: RequestParams = {}) =>
      this.request<EquipmentAsset, any>({
        path: `/api/v1/organization/org/${orgId}/equipment/assets/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgEquipmentAssetsDestroy
     * @request DELETE:/api/v1/organization/org/{org_id}/equipment/assets/{id}/
     * @secure
     */
    organizationOrgEquipmentAssetsDestroy: (id: number, orgId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organization/org/${orgId}/equipment/assets/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgEquipmentAssetsRetrieve
     * @request GET:/api/v1/organization/org/{org_id}/equipment/assets/{id}/
     * @secure
     */
    organizationOrgEquipmentAssetsRetrieve: (id: number, orgId: string, params: RequestParams = {}) =>
      this.request<EquipmentAsset, any>({
        path: `/api/v1/organization/org/${orgId}/equipment/assets/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgEquipmentAssetsPartialUpdate
     * @request PATCH:/api/v1/organization/org/{org_id}/equipment/assets/{id}/
     * @secure
     */
    organizationOrgEquipmentAssetsPartialUpdate: (
      id: number,
      orgId: string,
      body: PatchedEquipmentAsset,
      params: RequestParams = {},
    ) =>
      this.request<EquipmentAsset, any>({
        path: `/api/v1/organization/org/${orgId}/equipment/assets/${id}/`,
        method: "PATCH",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgEquipmentDestroy
     * @request DELETE:/api/v1/organization/org/{org_id}/equipment/{id}/
     * @secure
     */
    organizationOrgEquipmentDestroy: (id: number, orgId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organization/org/${orgId}/equipment/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgEquipmentRetrieve
     * @request GET:/api/v1/organization/org/{org_id}/equipment/{id}/
     * @secure
     */
    organizationOrgEquipmentRetrieve: (id: number, orgId: string, params: RequestParams = {}) =>
      this.request<Equipment, any>({
        path: `/api/v1/organization/org/${orgId}/equipment/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgEquipmentPartialUpdate
     * @request PATCH:/api/v1/organization/org/{org_id}/equipment/{id}/
     * @secure
     */
    organizationOrgEquipmentPartialUpdate: (
      id: number,
      orgId: string,
      body: PatchedEquipment,
      params: RequestParams = {},
    ) =>
      this.request<Equipment, any>({
        path: `/api/v1/organization/org/${orgId}/equipment/${id}/`,
        method: "PATCH",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgInventoryList
     * @request GET:/api/v1/organization/org/{org_id}/inventory/
     * @secure
     */
    organizationOrgInventoryList: (
      orgId: string,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        name?: string;
        /** Ordering */
        o?: ("-created_at" | "-name" | "-updated_at" | "created_at" | "name" | "updated_at")[];
        /** The initial index from which to return the results. */
        offset?: number;
        organization?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedInventoryList, any>({
        path: `/api/v1/organization/org/${orgId}/inventory/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgInventoryCreate
     * @request POST:/api/v1/organization/org/{org_id}/inventory/
     * @secure
     */
    organizationOrgInventoryCreate: (orgId: string, body: Inventory, params: RequestParams = {}) =>
      this.request<Inventory, any>({
        path: `/api/v1/organization/org/${orgId}/inventory/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgInventoryAssetsList
     * @request GET:/api/v1/organization/org/{org_id}/inventory/assets/
     * @secure
     */
    organizationOrgInventoryAssetsList: (
      orgId: string,
      query?: {
        inventory?: number;
        /** Number of results to return per page. */
        limit?: number;
        number?: string;
        /** Ordering */
        o?: ("-created_at" | "-number" | "-updated_at" | "created_at" | "number" | "updated_at")[];
        /** The initial index from which to return the results. */
        offset?: number;
        organization?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedInventoryAssetList, any>({
        path: `/api/v1/organization/org/${orgId}/inventory/assets/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgInventoryAssetsCreate
     * @request POST:/api/v1/organization/org/{org_id}/inventory/assets/
     * @secure
     */
    organizationOrgInventoryAssetsCreate: (orgId: string, body: InventoryAsset, params: RequestParams = {}) =>
      this.request<InventoryAsset, any>({
        path: `/api/v1/organization/org/${orgId}/inventory/assets/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgInventoryAssetsDestroy
     * @request DELETE:/api/v1/organization/org/{org_id}/inventory/assets/{id}/
     * @secure
     */
    organizationOrgInventoryAssetsDestroy: (id: number, orgId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organization/org/${orgId}/inventory/assets/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgInventoryAssetsRetrieve
     * @request GET:/api/v1/organization/org/{org_id}/inventory/assets/{id}/
     * @secure
     */
    organizationOrgInventoryAssetsRetrieve: (id: number, orgId: string, params: RequestParams = {}) =>
      this.request<InventoryAsset, any>({
        path: `/api/v1/organization/org/${orgId}/inventory/assets/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgInventoryAssetsPartialUpdate
     * @request PATCH:/api/v1/organization/org/{org_id}/inventory/assets/{id}/
     * @secure
     */
    organizationOrgInventoryAssetsPartialUpdate: (
      id: number,
      orgId: string,
      body: PatchedInventoryAsset,
      params: RequestParams = {},
    ) =>
      this.request<InventoryAsset, any>({
        path: `/api/v1/organization/org/${orgId}/inventory/assets/${id}/`,
        method: "PATCH",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgInventoryDestroy
     * @request DELETE:/api/v1/organization/org/{org_id}/inventory/{id}/
     * @secure
     */
    organizationOrgInventoryDestroy: (id: number, orgId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organization/org/${orgId}/inventory/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgInventoryRetrieve
     * @request GET:/api/v1/organization/org/{org_id}/inventory/{id}/
     * @secure
     */
    organizationOrgInventoryRetrieve: (id: number, orgId: string, params: RequestParams = {}) =>
      this.request<Inventory, any>({
        path: `/api/v1/organization/org/${orgId}/inventory/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgInventoryPartialUpdate
     * @request PATCH:/api/v1/organization/org/{org_id}/inventory/{id}/
     * @secure
     */
    organizationOrgInventoryPartialUpdate: (
      id: number,
      orgId: string,
      body: PatchedInventory,
      params: RequestParams = {},
    ) =>
      this.request<Inventory, any>({
        path: `/api/v1/organization/org/${orgId}/inventory/${id}/`,
        method: "PATCH",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgMessagesList
     * @request GET:/api/v1/organization/org/{org_id}/messages/
     * @secure
     */
    organizationOrgMessagesList: (
      orgId: string,
      query?: {
        code?: string;
        department?: number;
        /** Number of results to return per page. */
        limit?: number;
        /** Ordering */
        o?: ("-code" | "-created_at" | "code" | "created_at")[];
        /** The initial index from which to return the results. */
        offset?: number;
        organization?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedSystemMessageList, any>({
        path: `/api/v1/organization/org/${orgId}/messages/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgMessagesRetrieve
     * @request GET:/api/v1/organization/org/{org_id}/messages/{id}/
     * @secure
     */
    organizationOrgMessagesRetrieve: (id: string, orgId: string, params: RequestParams = {}) =>
      this.request<SystemMessage, any>({
        path: `/api/v1/organization/org/${orgId}/messages/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgPositionsList
     * @request GET:/api/v1/organization/org/{org_id}/positions/
     * @secure
     */
    organizationOrgPositionsList: (
      orgId: string,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        name?: string;
        /** Ordering */
        o?: ("-created_at" | "-name" | "-updated_at" | "created_at" | "name" | "updated_at")[];
        /** The initial index from which to return the results. */
        offset?: number;
        organization?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedPositionList, any>({
        path: `/api/v1/organization/org/${orgId}/positions/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgPositionsCreate
     * @request POST:/api/v1/organization/org/{org_id}/positions/
     * @secure
     */
    organizationOrgPositionsCreate: (orgId: string, body: Position, params: RequestParams = {}) =>
      this.request<Position, any>({
        path: `/api/v1/organization/org/${orgId}/positions/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgPositionsDestroy
     * @request DELETE:/api/v1/organization/org/{org_id}/positions/{id}/
     * @secure
     */
    organizationOrgPositionsDestroy: (id: number, orgId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organization/org/${orgId}/positions/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgPositionsRetrieve
     * @request GET:/api/v1/organization/org/{org_id}/positions/{id}/
     * @secure
     */
    organizationOrgPositionsRetrieve: (id: number, orgId: string, params: RequestParams = {}) =>
      this.request<Position, any>({
        path: `/api/v1/organization/org/${orgId}/positions/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgPositionsPartialUpdate
     * @request PATCH:/api/v1/organization/org/{org_id}/positions/{id}/
     * @secure
     */
    organizationOrgPositionsPartialUpdate: (
      id: number,
      orgId: string,
      body: PatchedPosition,
      params: RequestParams = {},
    ) =>
      this.request<Position, any>({
        path: `/api/v1/organization/org/${orgId}/positions/${id}/`,
        method: "PATCH",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgUserPositionsList
     * @request GET:/api/v1/organization/org/{org_id}/user-positions/
     * @secure
     */
    organizationOrgUserPositionsList: (
      orgId: string,
      query?: {
        department?: number;
        email?: string;
        first_name?: string;
        group_name?: ("Manager" | "Specialist" | "Worker" | "manager" | "specialist" | "worker")[];
        last_name?: string;
        /** Number of results to return per page. */
        limit?: number;
        middle_name?: string;
        name?: string;
        /** Ordering */
        o?: (
          | "-created_at"
          | "-first_name"
          | "-last_name"
          | "-rating"
          | "-status"
          | "-updated_at"
          | "created_at"
          | "first_name"
          | "last_name"
          | "rating"
          | "status"
          | "updated_at"
        )[];
        /** The initial index from which to return the results. */
        offset?: number;
        organization?: number;
        phone_number?: string;
        position?: number;
        status?: (0 | 100 | 200 | 300 | 400 | 500)[];
        user?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedUserPositionList, any>({
        path: `/api/v1/organization/org/${orgId}/user-positions/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgUserPositionsGeolocationRetrieve
     * @request GET:/api/v1/organization/org/{org_id}/user-positions/geolocation/
     * @secure
     */
    organizationOrgUserPositionsGeolocationRetrieve: (orgId: string, params: RequestParams = {}) =>
      this.request<UserPositionGeolocation, any>({
        path: `/api/v1/organization/org/${orgId}/user-positions/geolocation/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgUserPositionsRetrieve
     * @request GET:/api/v1/organization/org/{org_id}/user-positions/{id}/
     * @secure
     */
    organizationOrgUserPositionsRetrieve: (id: number, orgId: string, params: RequestParams = {}) =>
      this.request<UserPosition, any>({
        path: `/api/v1/organization/org/${orgId}/user-positions/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgVehicleList
     * @request GET:/api/v1/organization/org/{org_id}/vehicle/
     * @secure
     */
    organizationOrgVehicleList: (
      orgId: string,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        name?: string;
        /** Ordering */
        o?: ("-created_at" | "-name" | "-updated_at" | "created_at" | "name" | "updated_at")[];
        /** The initial index from which to return the results. */
        offset?: number;
        organization?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedVehicleList, any>({
        path: `/api/v1/organization/org/${orgId}/vehicle/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgVehicleCreate
     * @request POST:/api/v1/organization/org/{org_id}/vehicle/
     * @secure
     */
    organizationOrgVehicleCreate: (orgId: string, body: Vehicle, params: RequestParams = {}) =>
      this.request<Vehicle, any>({
        path: `/api/v1/organization/org/${orgId}/vehicle/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgVehicleAssetsList
     * @request GET:/api/v1/organization/org/{org_id}/vehicle/assets/
     * @secure
     */
    organizationOrgVehicleAssetsList: (
      orgId: string,
      query?: {
        gos_number?: string;
        /** Number of results to return per page. */
        limit?: number;
        number?: string;
        /** Ordering */
        o?: (
          | "-created_at"
          | "-gos_number"
          | "-number"
          | "-updated_at"
          | "-vin_number"
          | "created_at"
          | "gos_number"
          | "number"
          | "updated_at"
          | "vin_number"
        )[];
        /** The initial index from which to return the results. */
        offset?: number;
        organization?: number;
        vehicle?: number;
        vin_number?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedVehicleAssetList, any>({
        path: `/api/v1/organization/org/${orgId}/vehicle/assets/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgVehicleAssetsCreate
     * @request POST:/api/v1/organization/org/{org_id}/vehicle/assets/
     * @secure
     */
    organizationOrgVehicleAssetsCreate: (orgId: string, body: VehicleAsset, params: RequestParams = {}) =>
      this.request<VehicleAsset, any>({
        path: `/api/v1/organization/org/${orgId}/vehicle/assets/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgVehicleAssetsDestroy
     * @request DELETE:/api/v1/organization/org/{org_id}/vehicle/assets/{id}/
     * @secure
     */
    organizationOrgVehicleAssetsDestroy: (id: number, orgId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organization/org/${orgId}/vehicle/assets/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgVehicleAssetsRetrieve
     * @request GET:/api/v1/organization/org/{org_id}/vehicle/assets/{id}/
     * @secure
     */
    organizationOrgVehicleAssetsRetrieve: (id: number, orgId: string, params: RequestParams = {}) =>
      this.request<VehicleAsset, any>({
        path: `/api/v1/organization/org/${orgId}/vehicle/assets/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgVehicleAssetsPartialUpdate
     * @request PATCH:/api/v1/organization/org/{org_id}/vehicle/assets/{id}/
     * @secure
     */
    organizationOrgVehicleAssetsPartialUpdate: (
      id: number,
      orgId: string,
      body: PatchedVehicleAsset,
      params: RequestParams = {},
    ) =>
      this.request<VehicleAsset, any>({
        path: `/api/v1/organization/org/${orgId}/vehicle/assets/${id}/`,
        method: "PATCH",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgVehicleDestroy
     * @request DELETE:/api/v1/organization/org/{org_id}/vehicle/{id}/
     * @secure
     */
    organizationOrgVehicleDestroy: (id: number, orgId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organization/org/${orgId}/vehicle/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgVehicleRetrieve
     * @request GET:/api/v1/organization/org/{org_id}/vehicle/{id}/
     * @secure
     */
    organizationOrgVehicleRetrieve: (id: number, orgId: string, params: RequestParams = {}) =>
      this.request<Vehicle, any>({
        path: `/api/v1/organization/org/${orgId}/vehicle/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationOrgVehiclePartialUpdate
     * @request PATCH:/api/v1/organization/org/{org_id}/vehicle/{id}/
     * @secure
     */
    organizationOrgVehiclePartialUpdate: (
      id: number,
      orgId: string,
      body: PatchedVehicle,
      params: RequestParams = {},
    ) =>
      this.request<Vehicle, any>({
        path: `/api/v1/organization/org/${orgId}/vehicle/${id}/`,
        method: "PATCH",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskEnumsTaskStatusList
     * @request GET:/api/v1/task/enums/task-status/
     * @secure
     */
    taskEnumsTaskStatusList: (params: RequestParams = {}) =>
      this.request<Enum[], any>({
        path: `/api/v1/task/enums/task-status/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskEnumsTaskStatusRetrieve
     * @request GET:/api/v1/task/enums/task-status/{id}/
     * @secure
     */
    taskEnumsTaskStatusRetrieve: (id: string, params: RequestParams = {}) =>
      this.request<Enum, any>({
        path: `/api/v1/task/enums/task-status/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskPointList
     * @request GET:/api/v1/task/org/{org_id}/dep/{dep_id}/task-point/
     * @secure
     */
    taskOrgDepTaskPointList: (
      depId: string,
      orgId: string,
      query?: {
        /** @format date */
        completed_at_after?: string;
        /** @format date */
        completed_at_before?: string;
        creator?: number;
        /** @format date */
        deadline_after?: string;
        /** @format date */
        deadline_before?: string;
        department?: number;
        equipment?: number;
        executors?: number[];
        inventory?: number;
        /** Number of results to return per page. */
        limit?: number;
        name?: string;
        /** Ordering */
        o?: (
          | "-completed_at"
          | "-created_at"
          | "-deadline"
          | "-name"
          | "-planned_start_at"
          | "-priority"
          | "-started_at"
          | "-status"
          | "-updated_at"
          | "completed_at"
          | "created_at"
          | "deadline"
          | "name"
          | "planned_start_at"
          | "priority"
          | "started_at"
          | "status"
          | "updated_at"
        )[];
        /** The initial index from which to return the results. */
        offset?: number;
        priority?: 0 | 1 | 2 | 3 | 4 | 5;
        /** @format date */
        started_at_after?: string;
        /** @format date */
        started_at_before?: string;
        status?: (0 | 100 | 101 | 110 | 200 | 220 | 300 | 400)[];
        task_subtype?: number;
        task_type?: number;
        vehicle?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedTaskPointList, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task-point/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskPointRetrieve
     * @request GET:/api/v1/task/org/{org_id}/dep/{dep_id}/task-point/{id}/
     * @secure
     */
    taskOrgDepTaskPointRetrieve: (depId: string, id: number, orgId: string, params: RequestParams = {}) =>
      this.request<TaskPoint, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task-point/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskList
     * @request GET:/api/v1/task/org/{org_id}/dep/{dep_id}/task/
     * @secure
     */
    taskOrgDepTaskList: (
      depId: string,
      orgId: string,
      query?: {
        /** @format date */
        completed_at_after?: string;
        /** @format date */
        completed_at_before?: string;
        creator?: number;
        /** @format date */
        deadline_after?: string;
        /** @format date */
        deadline_before?: string;
        department?: number;
        equipment?: number;
        executors?: number[];
        inventory?: number;
        /** Number of results to return per page. */
        limit?: number;
        name?: string;
        /** Ordering */
        o?: (
          | "-completed_at"
          | "-created_at"
          | "-deadline"
          | "-name"
          | "-planned_start_at"
          | "-priority"
          | "-started_at"
          | "-status"
          | "-updated_at"
          | "completed_at"
          | "created_at"
          | "deadline"
          | "name"
          | "planned_start_at"
          | "priority"
          | "started_at"
          | "status"
          | "updated_at"
        )[];
        /** The initial index from which to return the results. */
        offset?: number;
        priority?: 0 | 1 | 2 | 3 | 4 | 5;
        /** @format date */
        started_at_after?: string;
        /** @format date */
        started_at_before?: string;
        status?: (0 | 100 | 101 | 110 | 200 | 220 | 300 | 400)[];
        task_subtype?: number;
        task_type?: number;
        vehicle?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedTaskList, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskCreate
     * @request POST:/api/v1/task/org/{org_id}/dep/{dep_id}/task/
     * @secure
     */
    taskOrgDepTaskCreate: (depId: string, orgId: string, body: TaskCreate, params: RequestParams = {}) =>
      this.request<TaskCreate, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskDestroy
     * @request DELETE:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{id}/
     * @secure
     */
    taskOrgDepTaskDestroy: (depId: string, id: number, orgId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskRetrieve
     * @request GET:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{id}/
     * @secure
     */
    taskOrgDepTaskRetrieve: (depId: string, id: number, orgId: string, params: RequestParams = {}) =>
      this.request<TaskDetailed, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskPartialUpdate
     * @request PATCH:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{id}/
     * @secure
     */
    taskOrgDepTaskPartialUpdate: (
      depId: string,
      id: number,
      orgId: string,
      body: PatchedTaskCreate,
      params: RequestParams = {},
    ) =>
      this.request<TaskCreate, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${id}/`,
        method: "PATCH",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskChecksList
     * @request GET:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{task_id}/checks/
     * @secure
     */
    taskOrgDepTaskChecksList: (
      depId: string,
      orgId: string,
      taskId: string,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        name?: string;
        /** Ordering */
        o?: ("-created_at" | "-name" | "-status" | "-updated_at" | "created_at" | "name" | "status" | "updated_at")[];
        /** The initial index from which to return the results. */
        offset?: number;
        status?: (0 | 100 | 101 | 110 | 200 | 220 | 300 | 400)[];
        task?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedCheckList, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${taskId}/checks/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskChecksCreate
     * @request POST:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{task_id}/checks/
     * @secure
     */
    taskOrgDepTaskChecksCreate: (
      depId: string,
      orgId: string,
      taskId: string,
      body: Check,
      params: RequestParams = {},
    ) =>
      this.request<Check, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${taskId}/checks/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskChecksHistoryList
     * @request GET:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{task_id}/checks/{check_id}/history/
     * @secure
     */
    taskOrgDepTaskChecksHistoryList: (
      checkId: string,
      depId: string,
      orgId: string,
      taskId: string,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedCheckHistoryList, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${taskId}/checks/${checkId}/history/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskChecksHistoryRetrieve
     * @request GET:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{task_id}/checks/{check_id}/history/{id}/
     * @secure
     */
    taskOrgDepTaskChecksHistoryRetrieve: (
      checkId: string,
      depId: string,
      id: number,
      orgId: string,
      taskId: string,
      params: RequestParams = {},
    ) =>
      this.request<CheckHistory, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${taskId}/checks/${checkId}/history/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskChecksDestroy
     * @request DELETE:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{task_id}/checks/{id}/
     * @secure
     */
    taskOrgDepTaskChecksDestroy: (
      depId: string,
      id: number,
      orgId: string,
      taskId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${taskId}/checks/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskChecksRetrieve
     * @request GET:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{task_id}/checks/{id}/
     * @secure
     */
    taskOrgDepTaskChecksRetrieve: (
      depId: string,
      id: number,
      orgId: string,
      taskId: string,
      params: RequestParams = {},
    ) =>
      this.request<Check, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${taskId}/checks/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskChecksPartialUpdate
     * @request PATCH:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{task_id}/checks/{id}/
     * @secure
     */
    taskOrgDepTaskChecksPartialUpdate: (
      depId: string,
      id: number,
      orgId: string,
      taskId: string,
      body: PatchedCheck,
      params: RequestParams = {},
    ) =>
      this.request<Check, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${taskId}/checks/${id}/`,
        method: "PATCH",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskCommentsList
     * @request GET:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{task_id}/comments/
     * @secure
     */
    taskOrgDepTaskCommentsList: (
      depId: string,
      orgId: string,
      taskId: string,
      query?: {
        creator?: number;
        /** Number of results to return per page. */
        limit?: number;
        /** Ordering */
        o?: ("-created_at" | "-name" | "-status" | "-updated_at" | "created_at" | "name" | "status" | "updated_at")[];
        /** The initial index from which to return the results. */
        offset?: number;
        status?: (0 | 100 | 101 | 110 | 200 | 220 | 300 | 400)[];
        task?: number;
        text?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedCommentList, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${taskId}/comments/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskCommentsCreate
     * @request POST:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{task_id}/comments/
     * @secure
     */
    taskOrgDepTaskCommentsCreate: (
      depId: string,
      orgId: string,
      taskId: string,
      body: Comment,
      params: RequestParams = {},
    ) =>
      this.request<Comment, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${taskId}/comments/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskCommentsHistoryList
     * @request GET:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{task_id}/comments/{comment_id}/history/
     * @secure
     */
    taskOrgDepTaskCommentsHistoryList: (
      commentId: string,
      depId: string,
      orgId: string,
      taskId: string,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedCommentHistoryList, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${taskId}/comments/${commentId}/history/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskCommentsHistoryRetrieve
     * @request GET:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{task_id}/comments/{comment_id}/history/{id}/
     * @secure
     */
    taskOrgDepTaskCommentsHistoryRetrieve: (
      commentId: string,
      depId: string,
      id: number,
      orgId: string,
      taskId: string,
      params: RequestParams = {},
    ) =>
      this.request<CommentHistory, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${taskId}/comments/${commentId}/history/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskCommentsDestroy
     * @request DELETE:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{task_id}/comments/{id}/
     * @secure
     */
    taskOrgDepTaskCommentsDestroy: (
      depId: string,
      id: number,
      orgId: string,
      taskId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${taskId}/comments/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskCommentsRetrieve
     * @request GET:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{task_id}/comments/{id}/
     * @secure
     */
    taskOrgDepTaskCommentsRetrieve: (
      depId: string,
      id: number,
      orgId: string,
      taskId: string,
      params: RequestParams = {},
    ) =>
      this.request<Comment, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${taskId}/comments/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskCommentsPartialUpdate
     * @request PATCH:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{task_id}/comments/{id}/
     * @secure
     */
    taskOrgDepTaskCommentsPartialUpdate: (
      depId: string,
      id: number,
      orgId: string,
      taskId: string,
      body: PatchedComment,
      params: RequestParams = {},
    ) =>
      this.request<Comment, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${taskId}/comments/${id}/`,
        method: "PATCH",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskHistoryList
     * @request GET:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{task_id}/history/
     * @secure
     */
    taskOrgDepTaskHistoryList: (
      depId: string,
      orgId: string,
      taskId: string,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedTaskHistoryList, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${taskId}/history/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskHistoryRetrieve
     * @request GET:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{task_id}/history/{id}/
     * @secure
     */
    taskOrgDepTaskHistoryRetrieve: (
      depId: string,
      id: number,
      orgId: string,
      taskId: string,
      params: RequestParams = {},
    ) =>
      this.request<TaskHistory, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${taskId}/history/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskPhotosList
     * @request GET:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{task_id}/photos/
     * @secure
     */
    taskOrgDepTaskPhotosList: (
      depId: string,
      orgId: string,
      taskId: string,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        owner?: number;
        task?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedPhotoList, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${taskId}/photos/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskPhotosCreate
     * @request POST:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{task_id}/photos/
     * @secure
     */
    taskOrgDepTaskPhotosCreate: (
      depId: string,
      orgId: string,
      taskId: string,
      body: Photo,
      params: RequestParams = {},
    ) =>
      this.request<Photo, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${taskId}/photos/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskPhotosDestroy
     * @request DELETE:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{task_id}/photos/{id}/
     * @secure
     */
    taskOrgDepTaskPhotosDestroy: (
      depId: string,
      id: number,
      orgId: string,
      taskId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${taskId}/photos/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskPhotosRetrieve
     * @request GET:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{task_id}/photos/{id}/
     * @secure
     */
    taskOrgDepTaskPhotosRetrieve: (
      depId: string,
      id: number,
      orgId: string,
      taskId: string,
      params: RequestParams = {},
    ) =>
      this.request<Photo, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${taskId}/photos/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskPhotosPartialUpdate
     * @request PATCH:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{task_id}/photos/{id}/
     * @secure
     */
    taskOrgDepTaskPhotosPartialUpdate: (
      depId: string,
      id: number,
      orgId: string,
      taskId: string,
      body: PatchedPhoto,
      params: RequestParams = {},
    ) =>
      this.request<Photo, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${taskId}/photos/${id}/`,
        method: "PATCH",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskResultsList
     * @request GET:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{task_id}/results/
     * @secure
     */
    taskOrgDepTaskResultsList: (
      depId: string,
      orgId: string,
      taskId: string,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        owner?: number;
        task?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedResultList, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${taskId}/results/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskResultsCreate
     * @request POST:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{task_id}/results/
     * @secure
     */
    taskOrgDepTaskResultsCreate: (
      depId: string,
      orgId: string,
      taskId: string,
      body: ResultUpdate,
      params: RequestParams = {},
    ) =>
      this.request<ResultUpdate, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${taskId}/results/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskResultsDestroy
     * @request DELETE:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{task_id}/results/{id}/
     * @secure
     */
    taskOrgDepTaskResultsDestroy: (
      depId: string,
      id: number,
      orgId: string,
      taskId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${taskId}/results/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskResultsRetrieve
     * @request GET:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{task_id}/results/{id}/
     * @secure
     */
    taskOrgDepTaskResultsRetrieve: (
      depId: string,
      id: number,
      orgId: string,
      taskId: string,
      params: RequestParams = {},
    ) =>
      this.request<Result, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${taskId}/results/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgDepTaskResultsPartialUpdate
     * @request PATCH:/api/v1/task/org/{org_id}/dep/{dep_id}/task/{task_id}/results/{id}/
     * @secure
     */
    taskOrgDepTaskResultsPartialUpdate: (
      depId: string,
      id: number,
      orgId: string,
      taskId: string,
      body: PatchedResultUpdate,
      params: RequestParams = {},
    ) =>
      this.request<ResultUpdate, any>({
        path: `/api/v1/task/org/${orgId}/dep/${depId}/task/${taskId}/results/${id}/`,
        method: "PATCH",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgTaskSubtypesList
     * @request GET:/api/v1/task/org/{org_id}/task-subtypes/
     * @secure
     */
    taskOrgTaskSubtypesList: (
      orgId: string,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        name?: string;
        /** Ordering */
        o?: ("-created_at" | "-name" | "-updated_at" | "created_at" | "name" | "updated_at")[];
        /** The initial index from which to return the results. */
        offset?: number;
        organization?: number;
        task_type?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedTaskSubTypeList, any>({
        path: `/api/v1/task/org/${orgId}/task-subtypes/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgTaskSubtypesCreate
     * @request POST:/api/v1/task/org/{org_id}/task-subtypes/
     * @secure
     */
    taskOrgTaskSubtypesCreate: (orgId: string, body: TaskSubType, params: RequestParams = {}) =>
      this.request<TaskSubType, any>({
        path: `/api/v1/task/org/${orgId}/task-subtypes/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgTaskSubtypesDestroy
     * @request DELETE:/api/v1/task/org/{org_id}/task-subtypes/{id}/
     * @secure
     */
    taskOrgTaskSubtypesDestroy: (id: number, orgId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/task/org/${orgId}/task-subtypes/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgTaskSubtypesRetrieve
     * @request GET:/api/v1/task/org/{org_id}/task-subtypes/{id}/
     * @secure
     */
    taskOrgTaskSubtypesRetrieve: (id: number, orgId: string, params: RequestParams = {}) =>
      this.request<TaskSubType, any>({
        path: `/api/v1/task/org/${orgId}/task-subtypes/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgTaskSubtypesPartialUpdate
     * @request PATCH:/api/v1/task/org/{org_id}/task-subtypes/{id}/
     * @secure
     */
    taskOrgTaskSubtypesPartialUpdate: (
      id: number,
      orgId: string,
      body: PatchedTaskSubType,
      params: RequestParams = {},
    ) =>
      this.request<TaskSubType, any>({
        path: `/api/v1/task/org/${orgId}/task-subtypes/${id}/`,
        method: "PATCH",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgTaskTypesList
     * @request GET:/api/v1/task/org/{org_id}/task-types/
     * @secure
     */
    taskOrgTaskTypesList: (
      orgId: string,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        name?: string;
        /** Ordering */
        o?: ("-created_at" | "-name" | "-updated_at" | "created_at" | "name" | "updated_at")[];
        /** The initial index from which to return the results. */
        offset?: number;
        organization?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedTaskTypeList, any>({
        path: `/api/v1/task/org/${orgId}/task-types/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgTaskTypesCreate
     * @request POST:/api/v1/task/org/{org_id}/task-types/
     * @secure
     */
    taskOrgTaskTypesCreate: (orgId: string, body: TaskType, params: RequestParams = {}) =>
      this.request<TaskType, any>({
        path: `/api/v1/task/org/${orgId}/task-types/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgTaskTypesDestroy
     * @request DELETE:/api/v1/task/org/{org_id}/task-types/{id}/
     * @secure
     */
    taskOrgTaskTypesDestroy: (id: number, orgId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/task/org/${orgId}/task-types/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgTaskTypesRetrieve
     * @request GET:/api/v1/task/org/{org_id}/task-types/{id}/
     * @secure
     */
    taskOrgTaskTypesRetrieve: (id: number, orgId: string, params: RequestParams = {}) =>
      this.request<TaskType, any>({
        path: `/api/v1/task/org/${orgId}/task-types/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgTaskTypesPartialUpdate
     * @request PATCH:/api/v1/task/org/{org_id}/task-types/{id}/
     * @secure
     */
    taskOrgTaskTypesPartialUpdate: (id: number, orgId: string, body: PatchedTaskType, params: RequestParams = {}) =>
      this.request<TaskType, any>({
        path: `/api/v1/task/org/${orgId}/task-types/${id}/`,
        method: "PATCH",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskOrgTaskList
     * @request GET:/api/v1/task/org/{org_id}/task/
     * @secure
     */
    taskOrgTaskList: (
      orgId: string,
      query?: {
        /** @format date */
        completed_at_after?: string;
        /** @format date */
        completed_at_before?: string;
        creator?: number;
        /** @format date */
        deadline_after?: string;
        /** @format date */
        deadline_before?: string;
        department?: number;
        equipment?: number;
        executors?: number[];
        inventory?: number;
        /** Number of results to return per page. */
        limit?: number;
        name?: string;
        /** Ordering */
        o?: (
          | "-completed_at"
          | "-created_at"
          | "-deadline"
          | "-name"
          | "-planned_start_at"
          | "-priority"
          | "-started_at"
          | "-status"
          | "-updated_at"
          | "completed_at"
          | "created_at"
          | "deadline"
          | "name"
          | "planned_start_at"
          | "priority"
          | "started_at"
          | "status"
          | "updated_at"
        )[];
        /** The initial index from which to return the results. */
        offset?: number;
        priority?: 0 | 1 | 2 | 3 | 4 | 5;
        /** @format date */
        started_at_after?: string;
        /** @format date */
        started_at_before?: string;
        status?: (0 | 100 | 101 | 110 | 200 | 220 | 300 | 400)[];
        task_subtype?: number;
        task_type?: number;
        vehicle?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedTaskList, any>({
        path: `/api/v1/task/org/${orgId}/task/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskTaskRetrieve
     * @request GET:/api/v1/task/task/{id}/
     * @secure
     */
    taskTaskRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<TaskDetailed, any>({
        path: `/api/v1/task/task/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
