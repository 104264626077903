import React from 'react'
import { SxProps, TablePagination } from '@mui/material'
import { usePrevious } from 'hooks/usePrevious'
import { LIMIT_QUERY_DEFAULT, LIMIT_QUERY_SMALL } from 'consts'

export interface TasksTablePaginationProps {
  sx?: SxProps
  count?: number
  limit?: 'small' | 'medium'
  page: number
  loading: boolean
  onSetPage: (page: number) => void
}

export const TasksTablePagination = ({ sx, count, limit = 'medium', page, loading, onSetPage }: TasksTablePaginationProps) => {
  const countPrev = usePrevious(count)
  let countCurrent: number | null = null

  if (loading && countPrev) {
    countCurrent = countPrev
  } else if (count) {
    countCurrent = count
  }

  return (
    <>
      {countCurrent !== null && (
        <TablePagination
          component="div"
          count={countCurrent}
          rowsPerPage={limit === 'small' ? LIMIT_QUERY_SMALL : LIMIT_QUERY_DEFAULT}
          rowsPerPageOptions={[limit === 'small' ? LIMIT_QUERY_SMALL : LIMIT_QUERY_DEFAULT]}
          page={page}
          sx={{
            paddingRight: '4px !important',
            ...(sx ?? {}),
          }}
          onPageChange={(_, page) => onSetPage(page)}
        />
      )}
    </>
  )
}
