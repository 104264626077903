import { Chip } from '@mui/material'

interface DictionariesRecordChipProps {
  label: string
}

export const DictionariesRecordChip = ({ label }: DictionariesRecordChipProps) => {
  return (
    <Chip
      size={'small'}
      label={label}
    />
  )
}
