import { useQuery } from 'react-query'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { api } from 'api'

interface QueryTaskChecksParams {
  departmentID?: number
  taskID?: number
  query?: Parameters<typeof api.taskOrgDepTaskChecksList>[3]
}

export const useQueryTaskChecks = ({ departmentID, taskID, query }: QueryTaskChecksParams) => {
  const organizationID = useOrganizationID()

  return useQuery(
    ['task-checks', organizationID, departmentID, taskID],
    async () => {
      const { data } = await api.taskOrgDepTaskChecksList(departmentID!.toString(), organizationID.toString(), taskID!.toString(), query)
      return data
    },
    { enabled: !!departmentID && !!taskID }
  )
}
