import { useMutation } from 'react-query'
import { PaginatedCheckList, TaskStatus } from 'api/humanagro.generated'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { queryClient } from 'services/query'
import { api } from 'api'

export const useMutationSubtaskUpdate = () => {
  const organizationID = useOrganizationID()

  return useMutation({
    mutationFn: async ({
      name,
      departmentID,
      taskID,
      subtaskID,
      status,
    }: {
      name?: string,
      departmentID: number,
      taskID: number,
      subtaskID: number
      status?: TaskStatus
    }) => {
      const { data } = await api.taskOrgDepTaskChecksPartialUpdate(departmentID.toString(), subtaskID, organizationID.toString(), taskID.toString(), {
        name,
        status,
      })
      return data
    },
    onSuccess: async (data, { subtaskID, departmentID, taskID }) => {
      queryClient.setQueryData<PaginatedCheckList>(['task-checks', organizationID, departmentID, taskID],
        (oldData) => {
          return oldData?.results ? {
            ...oldData,
            results: oldData.results.map((el) => el.id === subtaskID ? data : el),
          } : (oldData ?? {})
        }
      )
    },
  })
}
