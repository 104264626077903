import React, { useEffect, useMemo } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { LoadingButton } from '@mui/lab'
import { Box, Dialog, DialogProps } from '@mui/material'
import { TaskType } from 'api/humanagro.generated'
import { useMutationSubtype } from 'api/query/useMutationSubtype'
import { DialogContent } from 'components/DialogContent'
import { DialogHeader } from 'components/DialogHeader'
import { FieldInputControlled } from 'components/Field'
import { Form } from 'components/Form'
import { useHandleError } from 'hooks/useHandleError'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { useToast } from 'hooks/useToast'
import { api } from 'api'
import { titleValidationOptions } from 'data'

interface TaskSubtypeDialogEditProps extends Pick<DialogProps, 'open'> {
  type: TaskType
  subtypeID: number | null
  onClose: () => void
}

export const TaskSubtypeDialogEdit = ({ type, subtypeID, open, onClose }: TaskSubtypeDialogEditProps) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useToast()
  const handleError = useHandleError()
  const organizationID = useOrganizationID()
  const subtypeMutation = useMutationSubtype(type.id, subtypeID ?? -1)
  const query = useQuery(
    [type, subtypeID],
    () => api.taskOrgTaskSubtypesRetrieve(subtypeID ?? -1, organizationID.toString()),
    { enabled: open && !!subtypeID }
  )
  const { formState: { isSubmitting, isSubmitSuccessful }, control, reset, handleSubmit } = useForm({
    defaultValues: useMemo(() => ({ name: query.data?.data.name }), [query.data]),
  })

  useEffect(() => {
    reset({ name: query.data?.data.name })
  }, [query.data, reset])

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
  }, [isSubmitSuccessful, reset])

  const handleAdd = async (data: FieldValues) => {
    try {
      await subtypeMutation.mutateAsync({ name: data.name })
      enqueueSnackbar(t('entity.taskSubtype.messages.editSuccess'), { variant: 'success' })
      onClose()
    } catch (error) {
      handleError(error)
      throw error
    }
  }

  return (
    <Dialog
      open={open}
      maxWidth={'lg'}
      onClose={onClose}
    >
      <DialogHeader
        title={t('entity.taskSubtype.actions.edit').concat(subtypeID ? ` (ID-${subtypeID})` : '')}
        subtitle={`${t('entity.taskSubtype.actions.forType')} "${type.name}" (ID-${type.id})`}
        onClose={onClose}
      />
      <DialogContent>
        <Form
          content={(
            <>
              <FieldInputControlled
                name={'name'}
                control={control}
                rules={titleValidationOptions}
                disabled={query.isFetching}
                label={t('entity.taskSubtype.fields.title')}
                placeholder={t('entity.taskSubtype.fields.title')}
              />
              <Box>
                <LoadingButton
                  type={'submit'}
                  variant={'contained'}
                  loading={subtypeMutation.isLoading}
                  disabled={isSubmitting && !isSubmitSuccessful && !query.isFetching}
                  disableRipple
                  disableElevation
                >
                  {t('actions.save')}
                </LoadingButton>
              </Box>
            </>
          )}
          onSubmit={handleSubmit(handleAdd)}
        />
      </DialogContent>
    </Dialog>
  )
}
