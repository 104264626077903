import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Chip, SxProps, Typography } from '@mui/material'
import { UserExtend, UserName } from 'api/humanagro.generated'
import { Tooltip } from 'components/Tooltip'
import { useOpenEmployeeDrawer } from 'features/employees/components/EmployeeDrawer'
import { getUserFullName, getUserShortName } from 'features/employees/helpers'
import { useOrganizationID } from 'hooks/useOrganizationID'
import { useToast } from 'hooks/useToast'
import { api } from 'api'

interface EmployeeAvatarProps {
  user: UserExtend | UserName
  departmentID: number
  labelled?: boolean
  fetchUserPositionID?: boolean
  sx?: SxProps
  renderAfterChip?: ReactNode
  onClick?: () => void
}

export const EmployeeAvatar = ({ user, departmentID, labelled = true, fetchUserPositionID = true, sx, renderAfterChip, onClick }: EmployeeAvatarProps) => {
  const openEmployeeDrawer = useOpenEmployeeDrawer()
  const organizationID = useOrganizationID()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useToast()
  const fullName = useMemo(() => getUserFullName(user), [user])
  const shortName = useMemo(() => getUserShortName(user), [user])
  const initials = useMemo(() => shortName?.[0] ?? user.id, [shortName, user.id])
  const [userPositionID, setUserPositionID] = useState<number | null>(fetchUserPositionID ? null : user.id)

  const handleClick = useCallback(async () => {
    if (userPositionID) {
      openEmployeeDrawer(userPositionID)
      return
    }

    const { data } = await api.organizationOrgDepUserPositionsList(departmentID.toString(), organizationID.toString(), {
      user: user.id,
    })
    const id = data.results?.[0]?.id ?? null

    if (!id) {
      enqueueSnackbar(t('entity.employee.errors.sidebar'), { variant: 'error' })
    } else {
      openEmployeeDrawer(id)
    }

    setUserPositionID(id)
  }, [openEmployeeDrawer, userPositionID, departmentID, organizationID, enqueueSnackbar, t, user.id])

  const avatar = (
    <Avatar
      key={user.id}
      alt={fullName ?? user.id.toString()}
      sx={{
        width: `${labelled ? 20 : 32}px !important`,
        height: `${labelled ? 20 : 32}px !important`,
        border: labelled ? 'none' : undefined,
        cursor: 'pointer',
      }}
      onClick={() => !labelled && handleClick()}
    >
      <Box
        component={'span'}
        sx={{
          fontSize: labelled ? '12px' : '16px',
          fontWeight: 600,
          textTransform: 'uppercase',
        }}
      >
        {initials}
      </Box>
    </Avatar>
  )

  return (
    <Tooltip
      placement={'left'}
      content={(
        <Box>
          <Typography variant={'body2'}>
            {fullName}{' '}
          </Typography>
        </Box>
      )}
    >
      <Box
        sx={{ position: 'relative', ...sx }}
      >
        {labelled ? (
          <Chip
            variant={'filled'}
            sx={{ height: '28px' }}
            avatar={avatar}
            label={shortName}
            onClick={async (e) => {
              e.stopPropagation()
              onClick ? onClick() : await handleClick()
            }}
          />
        ) : avatar}
        {renderAfterChip}
      </Box>
    </Tooltip>
  )
}
